import BaseComponent from "../components/BaseComponent";
import ApplicationService from "../services/ApplicationService";
import ClientService from "../services/ClientService";
import DiminutiveService from "../services/DiminutiveService";
import OrganizationService from "../services/OrganizationService";
import ProceedingService from "../services/ProceedingService";
import ProductService from '../services/ProductService';
import StatusService from "../services/StatusService";
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import UserService from "../services/UserService";
import { ApplicationEditPriority } from "../application/ApplicationEditPriority";
import AuthService from "../services/AuthService";
import { getByIdInArray } from "../utilHook";
import { ApplicationEditAssignedUser } from "../application/ApplicationEditAssignedUser";

class BaseReport extends BaseComponent {

    baseState = {
        loading: true,
        error: false,
        _id: '',
        name: '',
        client_id: '',
        client: {
            name: ''
        },
        clients: [
            {
                _id: '',
                name: ''
            }
        ],
        fantasy_name: '',
        fantasy_names: '',
        short_name: '',
        organization_data: '',
        origin: '',
        display_name: '',
        description: '',
        brand: '',
        applications: []
    }

    showShortName(col) {
        return col.short_name ? col.short_name : col.name;
    }
    showClient(col) {
        return col.client?.name;
    }

    showProceeding(col) {
        return col?.application?.organization?.organization_name;
    }
    showExpedient(col) {
        return col?.application?.last_iteration?.field_0;
    }

    showLastDate(col) {
        if (col?.application?.last_iteration?.date)
            return format(col?.application?.last_iteration?.date, 'dd/MM/yyyy');
        return "";
    }

    showExpirationDate(col) {
        if (col?.application?.last_iteration?.expiration_date)
            return format(col?.application?.last_iteration?.expiration_date, 'dd/MM/yyyy');
        return "";
    }

    showStartDate(col) {

        if (col?.application?.iteration?.date) {
            return format(new Date(col?.application?.iteration?.date), 'dd/MM/yyyy');
        } else if (col?.application?.second_iteration?.date) {
            return format(new Date(col?.application?.second_iteration?.date), 'dd/MM/yyyy');
        }
        return "";
    }

    showPriority(col, isPdf = false) {
        if (isPdf) {
            return getByIdInArray(ApplicationService.priorities(), col?.application?.priority)?.name;
        }
        return <ApplicationEditPriority
            priority={col?.application?.priority}
            applicationId={col?.application?._id}
            edit={AuthService.userIsAdmin()}
        />
    }

    showAsignedUser(col, isPdf = false) {
        if (isPdf || !AuthService.userIsAdmin()) {
            return col?.application?.last_iteration?.asign_to;
        }
        return <ApplicationEditAssignedUser
            assginedUser={col?.application?.last_iteration?.asign_to}
            applicationId={col?.application?._id}
            users={this.state.users}
            edit={AuthService.userIsAdmin()}
        />
    }
    getProduct() {
        this.setState({ _id: this.state._id });
        // uso params.id porque el set state es async
        ProductService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                this.getDiminutives();

                return;
            }
            this.showToast('Error al cargar producto');
        })
    }

    getClients() {
        this.setState({ loadingClient: true });
        ClientService.getInstance().getAll(0, null, { product_id: this.state._id }).then((response) => {
            this.setState({ loadingClient: false });
            if (response && response.success) {
                this.setState({
                    clients: response.data
                });
                return;
            }
            this.showToast('Error al listar productos');
        });
    }

    getOrganizationsList() {
        OrganizationService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.sort(response.data, 'organization_name');
                this.setState({ organizations: response.data });
                return;
            }
            this.showToast('Error al listar organismos');
        });
    }

    getClientById(id) {
        ClientService.getInstance().getById(id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ clients: [response.data] });
                this.setState({ client: response.data });
                return;
            }
            this.showToast('Error al listar clientes');
        });
    }

    getApplications() {
        this.setState({ loadingApplication: true });
        ApplicationService.getInstance().getAll(0, null, { product_id: this.state._id }).then((response) => {
            this.setState({ loadingApplication: false });
            if (response && response.success) {
                this.setState({
                    applications: response.data
                });
                return;
            }
            this.showToast('Error al listar presentaciones');
        });
    }

    getDiminutives = () => {
        DiminutiveService.getInstance().getAll(0, 10000).then(response => {
            if (response && response.success) {
                this.setState({ diminutives: response.data });
                if (this.reduceAll) {
                    this.reduceAll();
                }
            }
        });
    }

    getStatusesList() {
        StatusService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.sort(response.data, 'status');
                this.setState({ statuses: response.data });
                return;
            }
            this.showToast('Error al listar estados');
        });
    }

    getSubstatusesList() {
        StatusService.getInstance().getSubstatusesList().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {

                let substatus = [];
                response.data.forEach(status => {
                    substatus = substatus.concat(status.substatuses.map(substatus => {
                        return substatus ? substatus.substatus : null;
                    }));
                });
                substatus = substatus?.sort();
                this.setState({ substatuses: substatus });
                return;
            }
            this.showToast('Error al listar subestados');
        });
    }

    getProceedingList() {
        ProceedingService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.sort(response.data, 'name');
                this.setState({ proceedings: response.data });
                return;
            }
            this.showToast('Error al listar trámites');
        });
    }

    getUsersList() {
        UserService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.sort(response.data, 'name');
                this.setState({ users: response.data });
                return;
            }
            this.showToast('Error al listar usuarios');
        });
    }

    reduce = (name) => {
        let short_name = name;
        if (short_name) {
            this.state.diminutives.forEach(diminutive => {
                short_name = short_name.replaceAll(diminutive.word, diminutive.diminutive);
            });
        }
        return short_name;
    }

    // este metodo se sobreescribe en product list porque cambia cada rows
    reduceAll = (name = null) => {
        if (!name) {
            if (this.state.name)
                name = this.state.name;
            if (this.props.name)
                name = this.props.name;
        }
        if (name) {
            this.setState({ short_name: this.reduce(name) });
        }
    }

    showCell = (row, column, pdf = false) => {
        let value = '';
        if (typeof column.value == 'string') {
            value = row[column.value];
        } else {
            value = column.value(row, pdf);
        }

        if (column.link && pdf === false) {
            return (<NavLink to={column.link(row)}>
                {value}
            </NavLink>);
        }
        return value;
    }

    showValueCell = (row, column) => {
        if (typeof column.value == 'string') {
            return row[column.value];
        }
        return column.value(row, true);
    }
}

export default BaseReport;