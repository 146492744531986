import Paper from '@material-ui/core/Paper';
import ProductService from '../services/ProductService';
import ProductTable from './ProductTable';
import { withSnackbar } from 'notistack';
import ContainerSearch from '../components/ContainerSearch';
import AuthService from '../services/AuthService';
import BaseProduct from './BaseProduct';

class ProductsList extends BaseProduct {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            totalCount: 100,
            rowsPerPage: 10,
            page: 0,
            query: {},
            rows: [],
        };
    }
    componentDidMount() {
        this.search();
    }

    search = (page = 0, rowsPerPage = null, sort = null) => {
        this.setState({ page: page });
        this.setState({ loading: true });
        if (!rowsPerPage)
            rowsPerPage = this.state.rowsPerPage;

        if (sort) {
            // como los atributos de query se agregan y no se borran, puedo mantener el sort
            // si necesidad de setearlo en cada consulta
            this.state.query.sort = sort;
        }

        ProductService.getInstance().getAll(page, rowsPerPage, this.state.query).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ rows: response.data });
                this.forceUpdate();
                this.reduceAll();
                this.forceUpdate();
                return;
            }
            this.showToast('Error al listar productos');
        });
        ProductService.getInstance().count(this.state.query).then((response) => {
            if (response && response.success) {
                this.setState({ totalCount: response.data });
            }
        })
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        this.search(newPage);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
        this.search(0, event.target.value);
    };

    handleTextFieldChange = (event) => {
        const query = {
            ...this.state.query,
            [event.target.id]: event.target.value
        };
        this.setState({ query });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.search(0);
    }

    render() {
        return (
            <div>
                <ContainerSearch
                    handleSubmit={this.handleSubmit}
                    handleKeypress={(e) => this.handleEnterPress(e, this.search)}
                    handleTextFieldChange={this.handleTextFieldChange}
                    title="Productos"
                    modelName={ProductService.modelName}
                    allowNew={AuthService.userIsAdmin() || AuthService.userIsEditor()}
                    q={this.props.q}
                />
                <Paper elevation={3}>
                    <Paper elevation={1}>
                        <ProductTable
                            loading={this.state.loading}
                            rows={this.state.rows}
                            search={this.search}
                            totalCount={this.state.totalCount}
                            page={this.state.page}
                            rowsPerPage={this.state.rowsPerPage}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </Paper>
            </div >
        );
    }
}

export default withSnackbar(ProductsList);