import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseUser from "./BaseUser";
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";
import { format } from "date-fns";

class UserEdit extends BaseUser {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        this.getUser();
    }

    render() {
        return (
            <div>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}
                    <Typography variant="h5" component="h2">
                        Usuario
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Nombre:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.name || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Apellido:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.last_name || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Correo electrónico:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.email || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Nombre de usuario:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.username || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Último acceso:
                    </Typography>
                    <Typography variant="body1" className="ml-2">
                        {this.state.last_login ? format(this.state.last_login, 'dd/MM/yyyy') : "-"}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                        Rol:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {UserService.permission(this.state) || "-"}</Typography>

                    <div className={"pt-3"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/user/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(UserEdit));