import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import ClientList from "./ClientList";
import ClientEdit from "./ClientEdit";
import ClientNew from "./ClientNew";
import ClientView from "./ClientView";

function ClientRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <ClientEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <ClientNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <ClientView />
                    </Route>
                    <Route path={`${path}/`}>
                        <ClientList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default ClientRoutes