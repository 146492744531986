import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-bootstrap';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProductExport = ({ data = [], columns = [], name, buttonLabel = "Exportar", disabled = false }) => {

    const [dataToShow, setDataToShow] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setDataToShow(
                data?.map(row => {
                    const currentRow = {};
                    columns.forEach(column => {
                        currentRow[column.className] = showCell(row, column, true);
                    })
                    return currentRow;
                })
            );
        }, 100);
    }, [data, columns]);

    if (!columns) {
        return <></>;
    }

    if (disabled) {
        return <Button variant="contained" disabled>
            Exportar
        </Button>;
    }

    return <ExcelFile element={<Button variant="contained" color="primary">{buttonLabel}</Button>} filename={name}>
        <ExcelSheet data={dataToShow} name={name}>
            {columns.map((column, key) => (
                <ExcelColumn label={column.label} value={column.className} key={key} />
            ))}
        </ExcelSheet>
    </ExcelFile>;
};

const showCell = (row, column, pdf = false) => {
    let value = '';
    if (typeof column.value == 'string') {
        value = row[column.value];
    } else {
        value = column.value(row, true);
    }

    if (column.link && pdf === false) {
        return (<NavLink to={column.link(row)}>
            {value}
        </NavLink>);
    }
    return value;
}
export default ProductExport;