import BaseComponent from "../components/BaseComponent"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { format } from 'date-fns';
import FileRow from "../file/FileRow";

export default class IterationTable extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteApplicationId: null
        };
    }

    render() {
        return (
            <div>
                {this.props.loading &&
                    <CircularLoading />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Comentarios</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Subestado</TableCell>
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>RNPA</TableCell>
                                    <TableCell>Expediente</TableCell>
                                    <TableCell>Documentación</TableCell>
                                    <TableCell>Vencimiento</TableCell>
                                    <TableCell>Asignado a</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{
                                this.props.iterations?.length &&
                                this.props.iterations.slice().reverse().map((iteration, numIteration) =>
                                    <TableRow key={numIteration}>
                                        <TableCell>
                                            {iteration.date ? format(iteration.date, 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.comments || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.status || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.substatus || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.username || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.field_1 || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.field_0 || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                iteration?.bucketFiles?.length ?
                                                    iteration.bucketFiles.map(
                                                        (bucketFile, index) =>
                                                            <FileRow file={bucketFile} index={numIteration} key={bucketFile?.filePath || index} />
                                                    )
                                                    :
                                                    ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {iteration.expiration_date ? format(iteration.expiration_date, 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {iteration.asign_to || "-"}
                                        </TableCell>
                                    </TableRow>
                                )
                            }</TableBody>
                        </Table>
                    </TableContainer>
                }
            </div >
        );
    }
}