import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import ProductsList from "./ProductList";
import ProductEdit from "./ProductEdit";
import ProductNew from "./ProductNew";
import ProductView from "./ProductView";

function ProductRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <ProductEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <ProductNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <ProductView />
                    </Route>
                    <Route path={`${path}/`}>
                        <ProductsList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default ProductRoutes