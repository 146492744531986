import BaseComponent from "../components/BaseComponent"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { DeleteForever } from "@material-ui/icons";
import ModalDelete from "../components/ModalDelete";
import ApplicationService from "../services/ApplicationService";
import { format } from 'date-fns';
import AuthService from "../services/AuthService";
import FileRow from "../file/FileRow";
import OrganizationService from "../services/OrganizationService";
import ProceedingService from "../services/ProceedingService";
import { getByIdInArray } from "../utilHook";

export default class ApplicationTable extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteApplicationId: null
        };
    }

    componentDidMount() {
        this.setState({ priorities: ApplicationService.priorities() });
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteApplicationId: id });
    }

    render() {
        return (
            <div className="applicationTable">
                {this.props.loading &&
                    <CircularLoading />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell> Fecha de inicio </TableCell>
                                    <TableCell> Fecha de último mov. </TableCell>
                                    <TableCell> Fecha de Vencimiento </TableCell>
                                    <TableCell> Organismo </TableCell>
                                    <TableCell> Trámite </TableCell>
                                    <TableCell> Estado </TableCell>
                                    <TableCell> Subestado </TableCell>
                                    <TableCell> Expediente </TableCell>
                                    <TableCell> Certificado </TableCell>
                                    <TableCell> Documentación </TableCell>
                                    <TableCell> Usuario (asignado a) </TableCell>
                                    <TableCell> Prioridad </TableCell>
                                    <TableCell className="max-width-1 text-right pr-4"> Acciones </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >{
                                this.props.rows.map((row) => (
                                    <TableRow key={row?._id} >
                                        <TableCell>
                                            <NavLink to={"/" + ApplicationService.modelName + "/view/" + row._id}>
                                                {row.iterations?.[0].date ? format(row.iterations?.[0].date, 'dd/MM/yyyy') : '-'}
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            {row.iterations[row.iterations.length - 1].date ? format(row.iterations[row.iterations.length - 1].date, 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.iterations[row.iterations.length - 1].expiration_date ? format(row.iterations[row.iterations.length - 1].expiration_date, 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                        <TableCell className="font-weight-bold">
                                            <NavLink to={"/" + OrganizationService.modelName + "/view/" + row.organization_id?._id}>
                                                {row?.organization_id?.organization_name || "-"}
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            <NavLink to={"/" + ProceedingService.modelName + "/view/" + row?.proceeding_id?._id}>
                                                {row?.proceeding_id?.name || "-"}
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            {row?.last_iteration?.status ? row?.last_iteration?.status : (row.iterations[row.iterations.length - 1].status || "-")}
                                        </TableCell>
                                        <TableCell>
                                            {row.iterations[row.iterations.length - 1].substatus || "-"}
                                        </TableCell>
                                        <TableCell> {row.iterations[row.iterations.length - 1].field_0 || "-"} </TableCell>
                                        <TableCell> {row.iterations[row.iterations.length - 1].field_1 || "-"} </TableCell>
                                        <TableCell>
                                            {
                                                row.iterations[row.iterations.length - 1]?.bucketFiles?.length ?
                                                    row.iterations[row.iterations.length - 1].bucketFiles.map(
                                                        (bucketFile, index) =>
                                                            <FileRow file={bucketFile} index={index} key={bucketFile?.filePath || index} />
                                                    )
                                                    :
                                                    ''
                                            }
                                        </TableCell>
                                        <TableCell> {row.iterations[row.iterations.length - 1]?.asign_to} </TableCell>
                                        <TableCell> {getByIdInArray(this.state.priorities, row.priority)?.name} </TableCell>
                                        <TableCell className="max-width-1 text-right">
                                            <div className="min-width-1">
                                                <NavLink to={"/" + ApplicationService.modelName + "/view/" + row?._id}>
                                                    <ListAltOutlinedIcon />
                                                </NavLink>
                                                {
                                                    (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                                    <NavLink to={"/" + ApplicationService.modelName + "/edit/" + row?._id}>
                                                        <EditOutlinedIcon />
                                                    </NavLink>
                                                }
                                                {
                                                    AuthService.userIsAdmin() &&
                                                    <NavLink onClick={this.showModalDelete(row?._id)} to="#">
                                                        <DeleteForever />
                                                    </NavLink>
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table >
                    </TableContainer >
                }
                <ModalDelete
                    show={this.state.showModalDelete}
                    link={"/" + ApplicationService.modelName + "/delete"}
                    id={this.state.deleteApplicationId}
                    message="¿Estas seguro que desea eliminar este trámite?"
                    setState={state => this.setState(state)}
                    onSubmit={this.props.search}
                />
            </div >
        );
    }
}