import './App.css';
import { Route, Switch } from 'react-router';
import { SnackbarProvider } from 'notistack';
import ApplicationRoutes from './application/routes';
import AuthService from './services/AuthService';
import BaseComponent from './components/BaseComponent';
import ClientOrganizationRoutes from './clientOrganization/routes';
import ClientRoutes from './client/routes';
import DiminutiveRoutes from './diminutives/routes';
import FileRoutes from './file/route';
import Home from './Home'
import Login from './auth/Login';
import OrganizationsRoutes from './organizations/routes';
import ProceedingRoutes from './proceedings/routes';
import ProductRoutes from './product/routes';
import ReportRoutes from './report/routes';
import UserRoutes from './user/route';

class App extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      token: AuthService.getToken()
    }
  }
  setToken = (token) => {
    this.setState({ token });
  }

  logout = () => {
    AuthService.setToken();
    this.setState({ token: null });
  }

  render() {
    return (
      <SnackbarProvider maxSnack={10} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <div>
          {
            !this.state.token &&
            <Login setToken={this.setToken} />
          }
          {
            this.state.token &&
            <Switch>
              <Route path="/application">
                <ApplicationRoutes logout={this.logout} />
              </Route>
              <Route path="/report">
                <ReportRoutes logout={this.logout} />
              </Route>
              <Route path="/client">
                <ClientRoutes logout={this.logout} />
              </Route>
              <Route path="/clientOrganization">
                <ClientOrganizationRoutes logout={this.logout} />
              </Route>
              <Route path="/diminutive">
                <DiminutiveRoutes logout={this.logout} />
              </Route>
              <Route path="/file">
                <FileRoutes logout={this.logout} />
              </Route>
              <Route path="/organization">
                <OrganizationsRoutes logout={this.logout} />
              </Route>
              <Route path="/proceeding">
                <ProceedingRoutes logout={this.logout} />
              </Route>
              <Route path="/product">
                <ProductRoutes logout={this.logout} />
              </Route>
              <Route path="/user">
                <UserRoutes logout={this.logout} />
              </Route>
              <Route path="/">
                <Home logout={this.logout} />
              </Route>
            </Switch>
          }
        </div>
      </SnackbarProvider >
    );
  }
}

export default App;
