
import CRUDService from './CRUDService';

class OrganizationService extends CRUDService {
    private static instance: OrganizationService;
    public static modelName: String = 'organization';
    private constructor() {
        super();
        this.modelName = OrganizationService.modelName;
    }

    public static getInstance(): OrganizationService {
        if (!this.instance)
            this.instance = new OrganizationService();
        return this.instance;
    }

    public static buildOrganization(data: any): any {
        return {
            _id: data._id,
            organization_name: data.organization_name,
            fields: data.fields,
            phone: data.phone,
            email: data.email,
            address: data.address,
            dependence: data.dependence,
            opening: data.opening,
            renewable: data.renewable,
            expires: data.expires,
            documentations: data.documentations,
            bucketDocumentations: data.bucketDocumentations,
        }
    }
}

export default OrganizationService