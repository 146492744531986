import React from "react";
import { format } from "date-fns";
import ClientService from "../services/ClientService";
import ProceedingService from "../services/ProceedingService";
import OrganizationService from "../services/OrganizationService";
import UserService from "../services/UserService";
import StatusService from "../services/StatusService";

class BaseComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this._back = this._back.bind(this);
  }

  _back(event) {
    if (event) event.preventDefault();

    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/");
    }
  }

  _linkTo = (link) => (event) => {
    if (event) event.preventDefault();

    this.props.history.push(link);
  };

  showToast = (message) => {
    this.props.enqueueSnackbar(message);
  };
  showToastError = (message) => {
    this.props.enqueueSnackbar(message, {
      variant: "error",
    });
  };
  showToastSuccess = (message) => {
    this.props.enqueueSnackbar(message, {
      variant: "success",
    });
  };

  isNumber = (string) => {
    var pattern = /^\d+$/;
    return pattern.test(string);
  };

  isObjectEmpty = (object) => {
    return Object.values(object).every(
      (x) => x === undefined || x === null || x === ""
    );
  };

  structuring = (key, value, prevValues) => {
    var ret = {};
    if (key && key.indexOf(".") !== -1) {
      if (!prevValues || !prevValues[key.split(".")[0]]) {
        ret[key.split(".")[0]] = {};
      } else {
        ret[key.split(".")[0]] = prevValues[key.split(".")[0]];
      }

      ret[key.split(".")[0]][key.split(".")[1]] = value;
    } else {
      ret[key] = value;
    }
    return ret;
  };

  clickById = (id) => {
    document.getElementById(id).click();
  };

  _handleChangeAutocomplete = (event, newValue, field_id, prevValues) => {
    if (this.props.setState) {
      this.props.setState(this.structuring(field_id, newValue, prevValues));
      return;
    }
    this.setState(this.structuring(field_id, newValue, prevValues));
    this.forceUpdate();
  };

  toDate = (numberDate) => {
    if (numberDate) {
      return format(numberDate, "dd/MM/yyyy");
    }
  };

  handleEnterPress = (event, myEvent) => {
    if (event.charCode === 13 || event.which === 13) {
      myEvent();
    }
  };

  sort = (data, field = "name") => {
    const lower = this.lower;
    data = data?.sort(function (a, b) {
      if (lower(a[field]) > lower(b[field])) return 1;
      if (lower(a[field]) < lower(b[field])) return -1;
      return 0;
    });
  };

  lower = (text) => {
    return typeof text == "string" ? text.toLowerCase() : text;
  };

  getClientsList() {
    ClientService.getInstance()
      .list()
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          let clients = response.data;
          if (clients.length) {
            clients = clients.map((client) => {
              return {
                _id: client._id,
                name: client.fantasy_name,
                coordinator: client.coordinator,
              };
            });
          }
          this.sort(response.data, "name");
          this.setState({
            clients: [{ name: "Todos los clientes" }, ...clients],
          });
          return;
        }
        this.showToast("Error al listar clientes");
      });
  }

  setStateProceedings(proceedings) {
    if (this.props.setState) {
      this.props.setState({ proceedings });
    } else {
      this.setState({ proceedings });
    }
  }

  getProceedingsList(organization) {
    if (!organization || !organization._id) {
      this.setStateProceedings([]);
      return;
    }

    ProceedingService.getInstance()
      .list({
        organization_id: organization._id,
      })
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          this.sort(response.data);
          this.setStateProceedings(response.data);
          return;
        }
        this.showToast("Error al listar trámites");
      });
  }

  getOrganizationsList(notEmpty = false) {
    OrganizationService.getInstance()
      .list()
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          this.sort(response.data, "organization_name");
          if (notEmpty && response.data?.length) {
            this.setState({
              organizations: response.data.filter(
                (organization) => !!organization.organization_name
              ),
            });
            return;
          }
          this.setState({ organizations: response.data });
          return;
        }
        this.showToast("Error al listar organismos");
      });
  }

  getUsersList(notEmpty = false) {
    UserService.getInstance()
      .list()
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          const users = response.data.map((user) => user.name)?.sort();

          if (notEmpty && users?.length) {
            this.setState({ users: users.filter((user) => !!user) });
            return;
          }
          this.setState({ users: users });
          return;
        }
        this.showToast("Error al listar usuarios");
      });
  }

  getStatusesList() {
    StatusService.getInstance()
      .list()
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          const statusesList = response.data
            .map((status) => {
              return status.status;
            })
            ?.sort();
          this.setState({ statusesList: statusesList });

          let statuses = {};
          response.data.forEach(function (status) {
            if (status.substatuses) {
              status.substatuses = status.substatuses
                .map((element) => {
                  if (element && element.substatus) return element.substatus;
                  return null;
                })
                ?.sort();
            }
            statuses[status.status] = status;
          });
          this.setState({ statuses: statuses });
          return;
        }
        this.showToast("Error al listar estados");
      });
  }
}

export default BaseComponent;
