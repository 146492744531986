import { Grid, Typography } from "@material-ui/core";
import BaseComponent from "../components/BaseComponent";
import ClientService from "../services/ClientService";
import OrganizationService from "../services/OrganizationService";
import ProceedingService from "../services/ProceedingService";
import ProductService from "../services/ProductService";
import { format } from 'date-fns';
import FileRow from "../file/FileRow";

export default class ApplicationDetail extends BaseComponent {

    render() {
        var last_iteration = {};
        // TODO: no se porque antes mostraba el ultimo si tenemos last_iteration
        if (this.props.application?.last_iteration) {
            last_iteration = this.props.application?.last_iteration;
        } else {
            if (this.props.application?.iterations?.length > 0) {
                last_iteration = this.props.application.iterations[this.props.application.iterations.length - 1];
            }
        }

        return (
            <>
                <Typography variant="h5" component="h2">
                    Presentaciones:
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Producto:
                        </Typography>
                        <Typography variant="body1" className="ml-2" component="a" href={"/" + ProductService.modelName + "/view/" + this.props.application?.product_id?._id} >
                            {this.props.application?.product_id?.name || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Cliente:
                        </Typography>
                        <Typography variant="body1" className="ml-2" component="a" href={"/" + ClientService.modelName + "/view/" + this.props.application?.client_id?._id}>
                            {this.props.application?.client_id?.name || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Organismo:
                        </Typography>
                        <Typography variant="body1" className="ml-2" component="a" href={"/" + OrganizationService.modelName + "/view/" + this.props.application?.organization_id?._id}>
                            {this.props.application?.organization_id?.organization_name || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Trámite:
                        </Typography>
                        <Typography variant="body1" className="ml-2" component="a" href={"/" + ProceedingService.modelName + "/view/" + this.props.application?.proceeding_id?._id}>
                            {this.props.application?.proceeding_id?.name || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Estado:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {this.props.application?.last_iteration?.status ? this.props.application?.last_iteration?.status : (last_iteration?.status || "-")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Subestado:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.substatus || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Expediente:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.field_0 || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Certificado:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.field_1 || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Comentarios:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.comments || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Vencimiento:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.expiration_date ? format(last_iteration?.expiration_date, 'dd/MM/yyyy') : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Asignado a:
                        </Typography>
                        <Typography variant="body1" className="ml-2">
                            {last_iteration?.asign_to || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2" color="textSecondary">
                            Documentación:
                        </Typography>
                        <Typography variant="body1" className="ml-2" component="div">
                            {
                                last_iteration.bucketFiles ?
                                    last_iteration?.bucketFiles?.map((file, index) => (
                                        <FileRow file={file} onDelete={this.props.onDeleteFile} index={index} key={file?.filePath || index} />
                                    )) : null
                            }
                            {/* <FileRow file={last_iteration.bucketFiles[0]} index={1} /> */}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    }
};