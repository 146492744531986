import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseClient from "./BaseClient";
import ProductTable from "../product/ProductTable";
import ClientDetail from "./ClientDetail";
import ClientOrganizationService from "../services/ClientOrganizationService";
import ClientOrganizationTable from "../clientOrganization/ClientOrganizationTable";
import AuthService from "../services/AuthService";
import ProductService from "../services/ProductService";
import ContainerSearch from '../components/ContainerSearch';

class ClientEdit extends BaseClient {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        this.getClient();
        this.getProducts();
        this.getClientOrganizations();
    }

    render() {
        return (
            <div className="clientView">
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}

                    <ClientDetail client={this.state} />

                    <div className={"pt-1"}>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-1 mr-3 mb-1" onClick={this._linkTo("/client/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-1 mb-1" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </Paper>
                <Paper elevation={3} className="productList mt-2">
                    <ContainerSearch
                        handleSubmit={this.handleProductSubmit}
                        handleKeypress={(e) => this.handleEnterPress(e, this.getProducts)}
                        handleTextFieldChange={this.handleProductTextFieldChange}
                        title="Productos"
                        modelName={ProductService.modelName}
                        q={this.props.q}
                        insideTable={true}
                        allowNew={true}
                        paramsToNew={"client_id=" + this.state._id}
                    />
                    <ProductTable
                        loading={this.state.loadingProduct}
                        classes={this.state.classes}
                        rows={this.state.products}
                        totalCount={this.state.totalCount}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        onPageChange={this.onProductChangePage}
                        search={this.getProducts}
                        onRowsPerPageChange={this.onProductChangeRowsPerPage}
                        hideClient={true} />
                </Paper>

                <Paper elevation={3} className="pt-1 mt-2">
                    <Typography variant="h5" component="h2" className="mt-2 ml-3 pr-3">
                        Organismos
                        {
                            AuthService.userIsAdmin() &&
                            <Button variant="contained" color="primary" className="mb-3 float-right" href={"/" + ClientOrganizationService.modelName + "/new/?client_id=" + this.state._id}>
                                Nuevo
                            </Button>
                        }
                    </Typography>
                    <ClientOrganizationTable
                        loading={this.state.loadingOrganization}
                        classes={this.state.classes}
                        rows={this.state.clientOrganizations}
                        totalCount={this.state.totalCountOrganization}
                        page={this.state.pageOrganization}
                        rowsPerPage={this.state.rowsPerPageOrganization}
                        onPageChange={this.onClientOrganizationPageChange}
                        search={this.getClientOrganizations}
                        onRowsPerPageChange={this.onClientOrganizationRowsPerPageChange}
                        hideClient={true} />
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(ClientEdit));