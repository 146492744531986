import { CircularProgress } from "@material-ui/core";

export default function CircularLoading(props) {
    return (
        <div>
            <div></div>
            <div
                style={{
                    marginLeft: '43%',
                    paddingTop: '70px',
                    paddingBottom: '50px',
                    left: '50%',
                    top: '50%'
                }}
            >
                <CircularProgress size={props.size || 150} thickness={1} />
            </div>
        </div>
    );
}