import { TablePagination } from "@material-ui/core";
import BaseComponent from "./BaseComponent";

export default class TablePaginationEs extends BaseComponent {

    displayRows = ({ from, to, count }) =>
        "" + from + "-" + to + " de " +
        (count !== -1 ? count : " mas que " + to);


    render() {
        return (
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                backIconButtonText="Anterior"
                nextIconButtonText="Siguiente"
                labelRowsPerPage="Mostrar"
                labelDisplayedRows={this.displayRows}
                count={this.props.count}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                onPageChange={this.props.onPageChange}
                onRowsPerPageChange={this.props.onRowsPerPageChange}
            />
        );
    }
};