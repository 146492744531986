import { Button, TextField, FormControl, withStyles } from '@material-ui/core';
import BaseComponent from '../components/BaseComponent';
import Modal from 'react-bootstrap/Modal';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 500,
            "max-width": "100%",
        }
    }
});

class ModalAddress extends BaseComponent {

    componentDidUpdate() {
        this.props.setState({ [this.props.name]: ModalAddress.showAddress(this.props.address) });
    }

    handleChange = (event) => {
        const address = {
            ...this.props.address,
            ...{ [event.target.id]: event.target.value }
        };
        this.props.setStateParent({ [this.props.name]: address });
        this.props.setState({ [this.props.name]: ModalAddress.showAddress(address) });
    }

    static showAddress(address) {
        if (!address)
            return '';

        return (address.address + ' ' + address.city).trim();
    }

    onShow = () => {
        this.props.setState({ ["show_" + this.props.name]: true });
    }

    onHide = () => {
        this.props.setState({ ["show_" + this.props.name]: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}: {this.props.address?.address}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl className={classes.root + " m-3"} onSubmit={this.onHide}>
                        <div>
                            <TextField id="address" label="Dirección" placeholder="Dirección" value={this.props.address?.address} onChange={this.handleChange} />
                            <TextField id="city" label="Ciudad" placeholder="Ciudad" value={this.props.address?.city} onChange={this.handleChange} />
                            <TextField id="country" label="País" placeholder="País" value={this.props.address?.country} onChange={this.handleChange} />
                            <TextField id="province" label="Provincia" placeholder="Provincia" value={this.props.address?.province} onChange={this.handleChange} />
                            <TextField id="postal_code" label="Postal" placeholder="Postal" value={this.props.address?.postal_code} onChange={this.handleChange} />
                        </div>
                        <div>
                            <Button variant="contained" color="primary" className="mb-3" onClick={this.onHide} id="address_save_button">
                                Guardar
                            </Button>
                        </div>
                    </FormControl>
                </Modal.Body>
            </Modal>
        );
    }
}

export default withStyles(useStyles)(ModalAddress);