import { ResponseError, ResponseSuccess } from '../interfaces/Response';
import ApiService from './ApiService';
import CRUDService from './CRUDService';
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import BaseFirebase from "../file/base";
import { v4 as uuidv4 } from 'uuid';

class FileService extends CRUDService {

    private static instance: FileService;
    public static modelName: String = 'file';
    private constructor() {
        super();
        this.modelName = FileService.modelName;
    }

    public static getInstance(): FileService {
        if (!this.instance)
            this.instance = new FileService();
        return this.instance;
    }

    public static buildFile(data: any): any {
        return {
            _id: data._id,
            name: data.name,
            fullname: data.fullname,
            type: data.type,
            data: data.data
        }
    }

    public async uploadFile1(file: File): Promise<any> {
        try {
            const toBase64 = (file: any) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
            let fileBase64 = await toBase64(file);

            return await ApiService.requestPost(
                ApiService.getApiUrl() + '/' + this.modelName + '/create/',
                {
                    name: file.name,
                    fullName: file.name,
                    type: file.type,
                    data: fileBase64
                });
        } catch (error) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }

    public async uploadFile(file: File): Promise<any> {
        try {
            const storage = getStorage(BaseFirebase.app, BaseFirebase.bucket);
            const uuid = this.generateUUID();
            const filePath = uuid + "_" + file.name;
            const reference = ref(storage, filePath);
            await uploadBytes(reference, file);
            return Promise.resolve(new ResponseSuccess({
                filePath: filePath,
                name: file.name,
            }));
        } catch (error) {
            console.error({ error });
            return new ResponseError(error as string);
        }
    }

    public async deleteFile(filepath: string): Promise<any> {
        try {
            const storage = getStorage(BaseFirebase.app, BaseFirebase.bucket);
            await deleteObject(ref(storage, filepath));
            return Promise.resolve(new ResponseSuccess({}));
        } catch (error) {
            console.error({ error });
            return new ResponseError(error as string, error);
        }
    }

    public generateUUID() {
        return uuidv4().replace(/-/g, '');
    }
}

export default FileService