import BaseComponent from "../components/BaseComponent";
import ApplicationService from "../services/ApplicationService";
import ProductService from "../services/ProductService";
import { getByIdInArray } from "../utilHook";
import { ApplicationFactory } from "./ApplicationFactory";

class BaseApplication extends BaseComponent {

    baseState = {
        loading: true,
        loadingProduct: true,
        _id: '',
        __v: '',
        error: false,
        client_id: '',
        organization_id: {},
        organizations: [],
        product_id: {},
        proceeding_id: {},
        proceedings: [],
        users: [],
        statuses: {},
        statusesList: [],
        success: '',
        iterations: [{
            comments: '',
            date: '',
            expiration_date: '',
            field_0: '',
            field_1: '',
            files: [],
            bucketFiles: [],
            status: '',
            user_ip: '',
            username: '',
            substatus: '',
            asign_to: ''
        }],
        last_iteration: {
            comments: '',
            date: '',
            expiration_date: '',
            field_0: '',
            field_1: '',
            files: [],
            bucketFiles: [],
            status: '',
            user_ip: '',
            username: '',
            substatus: '',
            asign_to: ''
        },
        files: [],
        bucketFiles: [],
        // de product
        totalCount: 100,
        rowsPerPage: 10,
        page: 0,
        totalCountOrganization: 100,
        rowsPerPageOrganization: 10,
        pageOrganization: 0,
        fileError: false,
    }

    getApplication(bucketFilesEmpty = false) {
        this.setState({ loading: true });
        ApplicationService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success && response.data) {
                this.setState(response.data);
                this.setState({ proceedings: [response.data.proceeding_id] });
                this.setState({ organizations: [response.data.organization_id] });
                this.getProduct();
                this.getOrganizationsList(true);
                this.getUsersList(true);
                this.getStatusesList();
                this.getProceedingsList(response.data.organization_id);
                this.setState({
                    'priority': getByIdInArray(ApplicationService.priorities(), response.data?.priority)
                });

                if (bucketFilesEmpty) {
                    // ocurre que en el edit no queres mantener los archivos del bucket files
                    this.clearBucketFiles();
                }
                return;
            }
            this.showToast('Error al carga la presentación');
        })
    }

    getProduct() {
        let product_id = this.state.product_id;
        if (this.state.product_id && this.state.product_id._id) {
            product_id = this.state.product_id._id;
        }
        ProductService.getInstance().getById(product_id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ product: response.data });
                return;
            }
            this.showToast('Error al cargar producto');
        })
    }

    onFileChange = (event) => {
        ApplicationFactory.onFileChange(this, event);
    }

    onDeleteFile = (filePath) => {
        ApplicationFactory.onDeleteFile(this, filePath);
    }

    updateData = () => {
        if (!this.state._id) {
            return;
        }
        this.update();
    }

    update = (redirect = false, postSubmit) => {
        this.setState({ loading: true });
        const application = ApplicationService.buildApplication(this.state);

        ApplicationService.getInstance().update(application).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                if (postSubmit) {
                    return postSubmit(application);
                }
                if (redirect)
                    this.props.history.push('/' + ApplicationService.modelName + '/view/' + this.state._id);
                this.clearBucketFiles();
                return;
            }
            this.removeLastIteration();
            this.showToast('No se pudo guardar el registro');
        });
    }

    clearBucketFiles = () => {
        this.forceUpdate();
        this.setState({
            last_iteration: {
                ...this.state?.last_iteration,
                bucketFiles: []
            }
        });
    }

    hasError = () => {
        return ApplicationFactory.hasError(this);
    }
    validateAsignTo = () => {
        return ApplicationFactory.validateAsignTo(this);
    }

    validateExpirationDate = (proceeding) => {
        return ApplicationFactory.validateExpirationDate(this, proceeding);
    }

    validateExpirationDateEmpty = () => {
        return ApplicationFactory.validateExpirationDateEmpty(this);
    }

    validateComments = () => {
        return ApplicationFactory.validateComments(this);
    }
    validateField0 = () => {
        return ApplicationFactory.validateField0(this);
    }

    validateField1 = () => {
        return ApplicationFactory.validateField1(this);
    }

    validateFiles = () => {
        return ApplicationFactory.validateFiles(this);
    }
}

export default BaseApplication;