import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CircularLoading from "../components/CircularLoading";
import ApplicationService from "../services/ApplicationService";

export const ApplicationEditAssignedUser = ({
    assginedUser: assignedUserProp,
    applicationId,
    users = [],
    edit = true
}) => {

    const [userList, setUserList] = useState(users?.map(user => user.name).filter((value, index, self) => self.indexOf(value) === index));
    const [assignedUser, setAssignedUser] = useState(assignedUserProp);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event, newInputValue) => {
        setAssignedUser(newInputValue);
        setLoading(true);
        ApplicationService.getInstance().updateAssignedUser(applicationId, newInputValue).then(response => {
            setLoading(false);
            if (response && response.success) {
                enqueueSnackbar("Usuario actualizado");
                setAssignedUser(newInputValue);
                return;
            }
            setAssignedUser(assignedUser);
            enqueueSnackbar("Error al cambiar usuario");
        })
    }
    const onChangeAssignedUser = () => {
        setLoading(true);
        const currentUser = userList?.find(user => user?.toLowerCase() === assignedUserProp?.toLowerCase());
        if (!currentUser) {
            setUserList(userList.concat([assignedUserProp]).filter((value, index, self) => self.indexOf(value) === index));
            setAssignedUser(assignedUserProp);
        } else {
            setAssignedUser(currentUser);
        }
        setLoading(false);
    }

    useEffect(() => {
        onChangeAssignedUser();
        // eslint-disable-next-line
    }, [assignedUserProp]);

    return <>
        {
            loading ?
                <CircularLoading size={25} />
                :
                edit ?
                    <Autocomplete
                        id="assignedUser"
                        options={getUserListAll(userList, assignedUserProp)}
                        getOptionSelected={(option, value) => option === value || option?.toLowerCase() === value?.toLowerCase()}
                        getOptionLabel={(option) => option || ''}
                        value={assignedUser}
                        required={true}
                        renderInput={(params) => <TextField {...params}
                            InputLabelProps={{ shrink: true }}
                            label="" />}
                        onChange={(event, newInputValue) => handleChange(event, newInputValue)}
                        disableClearable={true}
                    />
                    :
                    assignedUser
        }
    </>;
}

const getUserListAll = (userList: any[], assignedUserProp: any) => {
    const currentUser = userList?.find(user => user?.toLowerCase() === assignedUserProp?.toLowerCase());
    if (!currentUser) {
        return userList.concat([assignedUserProp]);
    }
    return userList;
}