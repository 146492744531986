import FileService from "../services/FileService";
import ProceedingService from "../services/ProceedingService";

export class ApplicationFactory {

    static handleDateChange = (applicationClass, date) => {
        let expiration_date = null;
        if (date) {
            expiration_date = date;
            if (date?.getTime) {
                expiration_date = date.getTime();
            }
        }
        applicationClass.setState({ expiration_date: expiration_date });
        if (applicationClass?.props?.setState) {
            applicationClass.props.setState({
                last_iteration: {
                    ...applicationClass.props.last_iteration,
                    expiration_date: expiration_date
                }
            });
        } else {
            applicationClass.setState({
                last_iteration: {
                    ...applicationClass.state.last_iteration,
                    expiration_date: expiration_date
                }
            });
        }
    }

    static validateAsignTo = (applicationClass) => {
        return !(applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.asign_to?.required
            && !applicationClass.state.last_iteration.asign_to);
    }

    static validateExpirationDateEmpty = (applicationClass) => {
        return !(applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.expiration_date?.required
            && !applicationClass.state.last_iteration.expiration_date);
    }

    static validateExpirationDate = (applicationClass, proceeding) => {
        if (ProceedingService.isReinscriptionOrRenovation(proceeding?.name)) {
            return true;
        }

        if (applicationClass.state.last_iteration.expiration_date) {
            return applicationClass.state.last_iteration.expiration_date > Date.now();
        }
        return true;
    }
    static validateComments = (applicationClass) => {
        return !(applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.comments?.required
            && !applicationClass.state.last_iteration.comments);
    }
    static validateField0 = (applicationClass) => {
        return !(applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.field_0?.required
            && !applicationClass.state.last_iteration.field_0);
    }

    static validateField1 = (applicationClass) => {
        return !(applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.field_1?.required
            && !applicationClass.state.last_iteration.field_1);
    }

    static validateFiles = (applicationClass) => {
        const status = applicationClass.state?.last_iteration?.status;
        applicationClass.setState({ fileError: false });

        if (applicationClass.state.proceeding_id?.expiration_date_field === false) {
            applicationClass.setState({ fileError: false });
            return true;
        }

        if (status === 'Aprobado' || status === 'En Trámite') {
            const ret = applicationClass.state?.last_iteration?.bucketFiles && applicationClass.state?.last_iteration?.bucketFiles.length > 0;
            applicationClass.setState({ fileError: !ret });
            return ret;
        }
        return true;
    }

    static hasError = (applicationClass) => {
        let error = false;

        if (!applicationClass.validateFiles()) {
            error = true;
            applicationClass.showToastError('Debe agregar un documento');
        }
        if (!applicationClass.validateAsignTo()) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Asignado a"');
        }
        if (!applicationClass.validateComments()) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Comentario"');
        }
        if (!applicationClass.validateField0()) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Expediente"');
        }

        if (!applicationClass.validateField1()) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Certificado"');
        }
        if (!applicationClass.state.last_iteration?.status) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Estado"');
        }
        if ((!applicationClass.state.proceeding_id || !applicationClass.state.proceeding_id._id)) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Trámite"');
        }
        if ((!applicationClass.state.organization_id || !applicationClass.state.organization_id._id)) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Organismo"');
        }

        if (!applicationClass.validateExpirationDateEmpty()) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Vencimiento"');
        }

        if (!applicationClass.validateExpirationDate(applicationClass.state.proceeding_id)) {
            error = true;
            applicationClass.showToastError('El "Vencimiento" tiene que ser mayor a la fecha actual');
        }

        if (applicationClass.state.last_iteration.status &&
            applicationClass.state.statuses[applicationClass.state.last_iteration.status]?.fields?.substatus?.required &&
            !applicationClass.state.last_iteration.substatus) {
            error = true;
            applicationClass.showToastError('Verifique el campo "Subestado"');
        }

        return error;
    }

    static onFileChange = (applicationClass, event) => {
        applicationClass.setState({ loading: true });
        FileService.getInstance().uploadFile(event.target.files[0]).then(response => {
            event.target.value = null;
            applicationClass.setState({ loading: false });
            if (response && response.success) {
                let files;
                if (applicationClass.state?.last_iteration) {
                    files = applicationClass.state?.last_iteration.bucketFiles || [];
                    files.push(response.data);
                    applicationClass.setState(applicationClass.structuring("last_iteration.bucketFiles", files, applicationClass.state));
                    applicationClass.forceUpdate();
                } else {
                    files = applicationClass.state.bucketFiles;
                    applicationClass.setState(applicationClass.structuring("bucketFiles", files, applicationClass.state));
                    applicationClass.forceUpdate();
                }
                // no actualizo porque sino me crea otra iteracion y no refresca la pagina
                // applicationClass.updateData();
                return;
            }
            applicationClass.showToast('No se pudo cargar el archivo');
        });
    };

    static onDeleteFile = (applicationClass, filePath) => {
        applicationClass.setState({ loading: true });
        // borrar solo se borra desde la ultima iteracion
        // y no se debería borrar el archivo sino el elemento de la lista y despues guardarlo
        FileService.getInstance().deleteFile(filePath).then((response) => {
            applicationClass.setState({ loading: false });
            if (!(response && response.success)) {
                applicationClass.showToast('No se pudo borrar el archivo');
            }
            let files = [];
            if (applicationClass.state?.last_iteration) {
                files = applicationClass.state.last_iteration.bucketFiles;
                files = files.filter(file => file.filePath !== filePath);
                applicationClass.setState(applicationClass.structuring("last_iteration.bucketFiles", files, applicationClass.state));
                applicationClass.forceUpdate();
            } else {
                files = applicationClass.state.bucketFiles;
                files = files.filter(file => file.filePath !== filePath);
                applicationClass.setState(applicationClass.structuring("bucketFiles", files, applicationClass.state));
                applicationClass.forceUpdate();
            }
            applicationClass.updateData();

        }).catch(() => {
            applicationClass.setState({ loading: false });
            applicationClass.showToast('No se pudo borrar el archivo');
        });
    }
}