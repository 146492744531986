import BaseComponent from "../components/BaseComponent";
import ApplicationService from "../services/ApplicationService";
import ClientService from "../services/ClientService";
import DiminutiveService from "../services/DiminutiveService";
import ProductService from '../services/ProductService';

class BaseProduct extends BaseComponent {

    baseState = {
        loading: true,
        error: false,
        _id: '',
        name: '',
        client_id: '',
        client: {
            name: ''
        },
        clients: [
            {
                _id: '',
                name: ''
            }
        ],
        fantasy_name: '',
        fantasy_names: '',
        short_name: '',
        organization_data: '',
        origin: '',
        display_name: '',
        description: '',
        brand: '',
        applications: []
    }

    getProduct() {
        this.setState({ _id: this.state._id });
        // uso params.id porque el set state es async
        ProductService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                this.getDiminutives();

                return;
            }
            this.showToast('Error al cargar producto');
        })
    }

    getClients() {
        this.setState({ loadingClient: true });
        ClientService.getInstance().getAll(0, null, { product_id: this.state._id }).then((response) => {
            this.setState({ loadingClient: false });
            if (response && response.success) {
                this.setState({
                    clients: response.data
                });
                return;
            }
            this.showToast('Error al listar productos');
        });
    }

    getClientsList() {
        ClientService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.sort(response.data, 'fantasy_name');
                this.setState({ clients: response.data });
                return;
            }
            this.showToast('Error al listar clientes');
        });
    }

    getClientById(id) {
        ClientService.getInstance().getById(id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ clients: [response.data] });
                this.setState({ client: response.data });
                return;
            }
            this.showToast('Error al listar clientes');
        });
    }

    getApplications() {
        this.setState({ loadingApplication: true });
        ApplicationService.getInstance().getAll(0, 10000, { product_id: this.state._id }).then((response) => {
            this.setState({ loadingApplication: false });
            if (response && response.success) {
                this.setState({
                    applications: response.data
                });
                return;
            }
            this.showToast('Error al listar presentaciones');
        });
    }

    getDiminutives = () => {
        DiminutiveService.getInstance().getAll(0, 10000).then(response => {
            if (response && response.success) {
                this.setState({ diminutives: response.data });
                if (this.reduceAll) {
                    this.reduceAll();
                }
            }
        });
    }

    reduce = (name) => {
        let short_name = name;
        if (short_name && this.state.diminutives) {
            this.state.diminutives.forEach(diminutive => {
                const regex = new RegExp(diminutive.word, "gi");
                short_name = short_name.replaceAll(regex, diminutive.diminutive);
            });
        }
        return short_name;
    }

    // este metodo se sobreescribe en product list porque cambia cada rows
    reduceAll = (name = null) => {
        if (!name) {
            if (this.state.name)
                name = this.state.name;
            if (this.props.name)
                name = this.props.name;
        }
        if (name) {
            this.setState({ short_name: this.reduce(name) });
            return this.reduce(name);
        }
    }


    validate = () => {
        let ret = true;
        if (!this.state.name) {
            this.showToastError('Ingrese "Denominación Legal"');
            ret = false;
        }

        if (!this.state.client?._id) {
            this.showToastError('Seleccione "Cliente"');
            ret = false;
        }

        if (!this.state.origin) {
            this.showToastError('Ingrese "Origen"');
            ret = false;
        }

        // if (!this.state.fantasy_name) {
        //     this.showToastError('Ingrese "Nombre de fantasía"');
        //     ret = false;
        // }
        return ret;
    }
}

export default BaseProduct;