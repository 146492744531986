import { withStyles, Paper, TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from "react-router";
import BaseProduct from './BaseProduct';
import { ProductButtonSubmit } from './ProductButtonSubmit';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 500,
            "max-width": "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class ProductForm extends BaseProduct {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            short_name: '',
            diminitives: [],
        };
    }

    _changeName = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
        this.setState(this.structuring(event.target.id, event.target.value));
        this.forceUpdate();
        const short_name = this.reduceAll(event.target.value);
        this.props.setState({ 'short_name': short_name })
    }

    _handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
        this.setState(this.structuring(event.target.id, event.target.value));
    }

    _handleChangeAutocomplete = (event, newValue, field_id) => {
        this.props.setState(this.structuring(field_id, newValue));
    }

    componentDidMount() {
        this.getDiminutives();
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={this.props.elevation} >
                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <div className={"pt-3"}>
                        Producto: {this.props.name}
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="name"
                                label="Denominación Legal"
                                placeholder="Denominación Legal"
                                value={this.props.name}
                                onChange={this._changeName}
                                required={true}
                                error={!this.props.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="client_id"
                                options={this.props.clients}
                                getOptionSelected={(option, value) => { return option._id === value._id || option.fantasy_name === value.fantasy_name; }}
                                getOptionLabel={(option) => { return option.fantasy_name || '' }}
                                // style={{ width: 300 }}
                                value={this.props.client}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Cliente"
                                    required={true}
                                    error={!this.props.client?._id} />}
                                onChange={(event, newInputValue) => this._handleChangeAutocomplete(event, newInputValue, "client")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="brand"
                                label="Marca"
                                placeholder="Marca"
                                value={this.props.brand}
                                onChange={this._handleTextFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="origin"
                                label="Origen"
                                placeholder="Origen"
                                value={this.props.origin}
                                onChange={this._handleTextFieldChange}
                                required={true}
                                error={!this.props.origin}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="fantasy_name"
                                label="Nombre de fantasía"
                                placeholder="Nombre de fantasía"
                                value={this.props.fantasy_name || ''}
                                onChange={this._handleTextFieldChange}
                            // required={true}
                            // error={!this.props.fantasy_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="description"
                                label="Descripción"
                                placeholder="Descripción"
                                value={this.props.description || ''}
                                onChange={this._handleTextFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="short_name"
                                label="Nombre corto"
                                placeholder="Nombre corto"
                                value={this.state.short_name || this.props.short_name}
                                disabled
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="display_name"
                                label="Display name"
                                placeholder="Display name"
                                value={this.props.display_name || ''}
                                disabled
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>
                    {!!this.props?.hideSubmit ?
                        null : <ProductButtonSubmit handleSubmit={this.props.handleSubmit} back={this._back} />
                    }
                </form >
            </Paper>
        );
    }
}

export default withRouter(withStyles(useStyles)(ProductForm))