import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseApplication from "./BaseApplication";
import ApplicationDetail from "./ApplicationDetail";
import ApplicationService from "../services/ApplicationService";
import AuthService from "../services/AuthService";
import IterationTable from './IterationTable';

class ApplicationEdit extends BaseApplication {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        this.getApplication();
    }

    render() {
        return (
            <div>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}

                    <ApplicationDetail application={this.state} />

                    <div className={"pt-1"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/" + ApplicationService.modelName + "/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Iteraciones:
                    </Typography>
                    <IterationTable iterations={this.state.iterations} />
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(ApplicationEdit));