import { withRouter } from "react-router";
import { Button, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseProduct from "./BaseProduct";
import ApplicationTable from "../application/ApplicationTable";
import ApplicationService from "../services/ApplicationService";
import AuthService from "../services/AuthService";
import "./style.css";

class ProductView extends BaseProduct {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        }
    }

    componentDidMount() {
        this.getProduct();
        this.getApplications();
    }

    render() {
        return (
            <div>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}
                    <Typography variant="h5" component="h2">
                        Producto
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Denominación Legal:</Typography>
                            <Typography variant="body1"> {this.state.name || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Cliente:</Typography>
                            <Typography variant="body1" component="a" href={"/client/view/" + this.state.client._id}> {this.state.client.name || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Nombre de fantasía:</Typography>
                            <Typography variant="body1"> {this.state.fantasy_name || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Origen:</Typography>
                            <Typography variant="body1"> {this.state.origin || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Nombre corto:</Typography>
                            <Typography variant="body1"> {this.state.short_name || "-"}</Typography>

                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Marca:</Typography>
                            <Typography variant="body1"> {this.state.brand || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Nombre de fantasía:</Typography>
                            <Typography variant="body1"> {this.state.fantasy_name || "-"}</Typography>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">Descripción:</Typography>
                            <Typography variant="body1"> {this.state.description || "-"}</Typography>
                        </Grid>
                    </Grid>


                    <div className={"pt-3"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/product/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </Paper>
                <Paper elevation={3} className="pt-1 mt-2">
                    <Typography variant="h5" component="h2" className="mt-2 ml-3 pr-3">
                        Presentaciones
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mb-3 float-right" onClick={this._linkTo("/" + ApplicationService.modelName + "/new/?product_id=" + this.state._id)}>
                                Nuevo
                            </Button>
                        }
                    </Typography>
                    <ApplicationTable loading={this.state.loadingApplications}
                        classes={this.state.classes}
                        rows={this.state.applications}
                    />
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(ProductView));