import BaseComponent from "../components/BaseComponent";
import DiminutiveService from '../services/DiminutiveService';

class BaseDiminutive extends BaseComponent {

    baseState = {
        _id: '',
        word: '',
        diminutive: '',
        loading: true,
    }

    getDiminutive() {
        // uso params.id porque el set state es async
        DiminutiveService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                return;
            }
            this.showToast('Error al cargar Diminutivo');
        })
    }

    validateWord = () => {
        let ret = this.state.word;
        if (!ret)
            this.showToastError('Ingrese una palabra');
        return ret;
    }
    validateDiminutive = () => {
        let ret = this.state.diminutive;
        if (!ret)
            this.showToastError('Ingrese un diminutivo');
        return ret;
    }

    validateDistinct = () => {
        let ret = this.state.diminutive === this.state.word;
        if (ret)
            this.showToastError('La palabra y el diminutivo tienen que ser distintos');
        return !ret;
    }
    validate = () => {
        return this.validateWord() && this.validateDiminutive() && this.validateDistinct();
    }
}

export default BaseDiminutive;