import { LinearProgress } from "@material-ui/core";
import ClientService from "../services/ClientService";
import ClientForm from "./ClientForm";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import BaseClient from "./BaseClient";

class ClientNew extends BaseClient {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...this.baseState };
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: false });
    this.getUsersList(true);
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });

    const client = ClientService.buildClient(this.state);
    ClientService.getInstance()
      .create(client)
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.data) {
          this.props.history.push("/client/view/" + response.data._id);
          return;
        }
        this.showToast("Error al crear cliente");
      });
  }

  render() {
    return (
      <div>
        {this.state.loading && <LinearProgress />}
        <ClientForm
          active={this.state?.active}
          handleSubmit={this._handleSubmit}
          brand={this.state?.brand}
          client={this.state?.client}
          client_id={this.state?.client_id}
          coordinator={this.state?.coordinator}
          comments={this.state?.comments}
          commercial_address={this.state?.commercial_address}
          contact={this.state?.contact}
          contacts={this.state?.contacts}
          display_name={this.state?.display_name}
          email={this.state?.email}
          error={this.state.error}
          fantasy_name={this.state?.fantasy_name}
          fiscal_address={this.state?.fiscal_address}
          industry={this.state?.industry}
          name={this.state?.name}
          origin={this.state?.origin}
          phone={this.state?.phone}
          sector={this.state?.sector}
          setState={(state) => this.setState(state)}
          showSector={this.state.industry === "ALIMENTOS"}
          state={this.state}
          stateClient={this.state?.stateClient}
          taxes_id={this.state?.taxes_id}
          users={this.state.users}
          assign_to={this.state.assign_to}
        />
      </div>
    );
  }
}
export default withSnackbar(withRouter(ClientNew));
