import ProductService from './../services/ProductService';
import ProductForm from './ProductForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseProduct from './BaseProduct';
import AuthService from '../services/AuthService';

class ProductEdit extends BaseProduct {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        }
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getClientsList();
        this.getProduct();
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        if (!this.validate()) {
            this.setState({ loading: false });
            return false;
        }

        const product = ProductService.buildProduct(this.state);

        this.setState({ loading: false });
        ProductService.getInstance().update(product).then(response => {
            if (response && response.success) {
                this.props.history.push('/' + ProductService.modelName + '/view/' + product._id);
                return;
            }
            this.showToast('Error al actualizar');
        });

    }

    render() {
        return (
            <ProductForm
                handleSubmit={this._handleSubmit}
                name={this.state?.name}
                client_id={this.state?.client_id}
                client={this.state?.client}
                fantasy_name={this.state?.fantasy_name}
                fantasy_names={this.state?.fantasy_names}
                description={this.state?.description}
                short_name={this.state?.short_name}
                display_name={this.state?.display_name}
                brand={this.state?.brand}
                origin={this.state?.origin}
                clients={this.state?.clients}
                setState={state => this.setState(state)}
                elevation={3}
            />
        );
    }
}
export default withSnackbar(withRouter(ProductEdit));