import { ResponseError } from '../interfaces/Response';
import ApiService from './ApiService';
import AuthService from './AuthService';
import CRUDService from './CRUDService';

class ApplicationService extends CRUDService {

    private static instance: ApplicationService;
    public static modelName: String = 'application';

    private constructor() {
        super();
        this.modelName = ApplicationService.modelName;
    }

    public static getInstance(): ApplicationService {
        if (!this.instance)
            this.instance = new ApplicationService();
        return this.instance;
    }

    public static isChangedState(data: any): boolean {
        // no tiene iterationes, entonces no cambio el estado
        // porque por defecto ya tiene uno
        if (!data.iterations ||
            (data.iterations.length === 1 && !data.iterations[data.iterations.length - 1].status)
        ) {
            return false;
        }
        // los estados son iguales entonces no cambio
        // if (data.iterations[data.iterations.length - 1].status === data.last_iteration.status) {
        //     return false;
        // }
        return true;
    }

    public static buildApplication(data: any): any {
        const iteration = {
            field_0: data.last_iteration.field_0,
            field_1: data.last_iteration.field_1,
            status: data.last_iteration.status,
            substatus: data.last_iteration?.substatus,
            comments: data.last_iteration.comments,
            expiration_date: (data.last_iteration.expiration_date ? data.last_iteration.expiration_date : null),
            date: new Date().getTime(),
            username: AuthService.getUserLogged()?.username,
            asign_to: data.last_iteration.asign_to,
            files: data.last_iteration.files,
            bucketFiles: data.last_iteration.bucketFiles,
        };

        // if (ApplicationService.isChangedState(data) || data.last_iteration.bucketFiles?.length) {
        data.iterations.push(iteration);
        // } else {
        //     data.iterations[data.iterations.length ? data.iterations.length - 1 : 0] = iteration;
        // }
        // la fecha se actualiza por mas que ya tiene, porque es un update
        data.last_iteration.date = iteration.date; //TODO: porque usamos time si es una fecha
        data.last_iteration.username = AuthService.getUserLogged()?.username;


        // en el new no tiene client_id, entonces lo saco de product
        // pero product se migró el client_id a client
        // tambien en el editar puede que no tanga client_id por eso dejo este if
        let client_id = ApplicationService.getClientByData(data);

        return {
            _id: data._id ? data._id : undefined,
            client_id: client_id,
            product_id: data.product_id,
            organization_id: data.organization_id,
            proceeding_id: data.proceeding_id,
            last_iteration: data.last_iteration,
            iterations: data.iterations,
            priority: data?.priority?.id || 2
        }
    }

    public static getClientByData(data: any): any {
        if (data.client_id) {
            return data.client_id;
        }
        if (data?.product?.client_id) {
            return data.product.client_id;
        }
        if (data?.product?.client?._id) {
            return data.product.client._id;
        }
        if (data?.client?._id) {
            return data.client._id;
        }
        if (data?.client) {
            return data.client;
        }
    }

    public static priorities() {
        return [
            {
                id: 1,
                name: "Baja"
            },
            {
                id: 2,
                name: "Media"
            },
            {
                id: 3,
                name: "Alta"
            },
        ];
    }

    public async updatePriority(applicationId: string, priority: any) {
        try {
            return await ApiService.requestPost(
                ApiService.getApiUrl() + '/' + this.modelName + '/updatePriority/' + applicationId,
                {
                    priority: priority?.id
                }
            );
        } catch (error: any) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }

    public async updateAssignedUser(applicationId: string, assignedUser: any) {
        try {
            return await ApiService.requestPost(
                ApiService.getApiUrl() + '/' + this.modelName + '/updateAssignedUser/' + applicationId,
                {
                    asign_to: assignedUser
                }
            );
        } catch (error: any) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }
}

export default ApplicationService