import "../Form.css";
import "./ClientForm.css";
import {
  Button,
  withStyles,
  Paper,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  TextareaAutosize,
} from "@material-ui/core";
import { withRouter } from "react-router";
import BaseComponent from "../components/BaseComponent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ModalAddress from "../components/ModalAddress";
import AuthService from "../services/AuthService";
import { Add, Check, Delete, Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const useStyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiAutocomplete-root": {
      width: 500,
      "max-width": "100%",
      order: 0,
      margin: 0,
      display: "inline-flex",
      padding: 0,
      position: "relative",
      "min-width": 0,
      "flex-direction": "column",
      "vertical-align": "top",
    },
  },
});

class ClientForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      show_commercial_address: false,
      show_fiscal_address: false,
      fiscal_address: "",
      comments: "",
      commercial_address: "",
      coordinator: "",
      contacts: props.contacts,
      newContact: {
        name: "",
        last_name: "",
        position: "",
        phone: "",
        email: "",
      },
      hasContacts: props.contacts > 0,
      industry: "",
      errorContactName: "",
      errorContactLastname: "",
      errorContactPosition: "",
      errorContactPhone: "",
      errorContactEmail: "",
      contactEditState: {},
      sector: "",
      showSector: false,
      stateClient: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contacts !== this.props.contacts) {
      this.setState({
        active: this.props.active,
        contacts: this.props.contacts,
        hasContacts: this.props.contacts.length > 0,
        showSector: this.props.showSector,
      });
    }
  }

  handleTextFieldChange = (event) => {
    this.props.setState(this.structuring(event.target.id, event.target.value));
  };

  handleTextFieldChangeContact = (event) => {
    const { id, value } = event.target;
    this.setState({ newContact: { ...this.state.newContact, [id]: value } });
    this.setState((prevState) => ({
      newContact: {
        ...prevState.newContact,
        [id]: value,
      },
    }));
  };

  validateContactName = (name) => {
    if (!name) {
      this.setState({ errorContactName: "Ingrese un nombre" });
      return false;
    }
    this.setState({ errorContactName: "" });
    return true;
  };

  handleAddContact = () => {
    console.log("hascontact", this.state.hasContacts);
    const { newContact } = this.state;
    const errors = {};

    if (!newContact.name) {
      errors.errorContactName = "Ingrese un nombre";
    }
    if (!newContact.last_name) {
      errors.errorContactLastname = "Ingrese un apellido";
    }
    if (!newContact.position) {
      errors.errorContactPosition = "Ingrese un cargo";
    }
    if (!newContact.phone) {
      errors.errorContactPhone = "Ingrese un teléfono";
    }
    if (!newContact.email) {
      errors.errorContactEmail = "Ingrese un correo electrónico";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ ...errors });
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      contacts: [...prevState.contacts, newContact],
      newContact: {
        name: "",
        last_name: "",
        position: "",
        phone: "",
        email: "",
      },
      hasContacts: true,
      errorContactName: "",
      errorContactLastname: "",
      errorContactPosition: "",
      errorContactPhone: "",
      errorContactEmail: "",
    }));
    this.props.setState((prevState) => ({
      ...prevState,
      contacts: [...prevState.contacts, newContact],
    }));
  };

  handleRemoveContact = (index) => {
    this.setState((prevState) => ({
      contacts: prevState.contacts.filter((_, i) => i !== index),
      hasContacts: prevState.contacts.length > 1,
    }));
    this.props.setState((prevState) => ({
      contacts: prevState.contacts.filter((_, i) => i !== index),
      hasContacts: prevState.contacts.length > 1,
    }));
  };

  handleChangeTaxes = (event) => {
    this.props.setState(this.structuring(event.target.id, event.target.value));
    this.validateForm(event.target.value, this.props.phone);
  };

  handleChangePhone = (event) => {
    this.props.setState(this.structuring(event.target.id, event.target.value));
    this.validateForm(this.props.taxes_id, event.target.value);
  };

  handleEditContact = (index) => {
    this.setState((prevState) => ({
      contactEditState: {
        ...prevState.contactEditState,
        [index]: !prevState.contactEditState[index],
      },
    }));
  };

  validateForm = (taxes_id, phone) => {
    console.log(phone);
    // no lo pongo en un if para que valide todos y despues evalue
    let validate = this.validateTaxes(taxes_id);
    // removido porque ya no hace falta validate &= this.validatePhone(phone);
    if (!this.props.name) {
      validate = false;
    }

    if (!this.props.fantasy_name) {
      validate = false;
    }
    if (!this.props.coordinator) {
      validate = false;
    }

    this.props.setState({ error: !validate });
    return validate;
  };

  validateTaxes = (taxes) => {
    if (this.isNumber(taxes) && taxes.length > 9 && taxes.length <= 11) {
      this.setState({ error_taxes_id: null });
      return true;
    }
    this.setState({ error_taxes_id: "Ingrese un máximo 11 dígitos" });
    return false;
  };

  validatePhone = (phone) => {
    if (phone) {
      // TODO: hago esto porque puede venir switchboard o directamente el phone

      if (
        (typeof phone.switchboard == "undefined" && !this.isNumber(phone)) ||
        (phone.switchboard && !this.isNumber(phone.switchboard))
      ) {
        this.setState({ error_phone: "Ingrese sólo números" });
        return false;
      }
    }
    this.setState({ error_phone: null });
    return true;
  };

  validateContactName = (name) => {
    if (!name) {
      this.setState({ errorContactName: "Ingrese un nombre" });
      return false;
    }
    this.setState({ errorContactName: "" });
    return true;
  };

  showModalCommercialAddress = () => {
    this.setState({ show_commercial_address: true });
  };

  showFiscalAddress = () => {
    this.setState({ show_fiscal_address: true });
  };
  onSubmit = (e) => {
    if (this.validateForm(this.props.taxes_id, this.props.phone)) {
      this.props.handleSubmit(e);
    }
  };

  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({
      active: isChecked,
      showAutocomplete: isChecked,
    });
    this.props.setState(
      this.structuring(event.target.id, event.target.checked)
    );
    this.forceUpdate();
  };

  handleChangeUser = (_, newInputValue, prevValues) => {
    this.props.setState(
      this.structuring("assign_to", newInputValue, prevValues)
    );
    this.forceUpdate();
  };

  renderContactData = (contact, index) => {
    const { contactEditState } = this.state;
    if (contactEditState[index]) {
      return (
        <div key={index} className="contact-block">
          <div className="contact-block-head">
            <Typography variant="h6">Editando Contacto #{index + 1}</Typography>
            <div className="contact-block-head-icons">
              <IconButton
                variant="contained"
                color="secondary"
                className=""
                onClick={() => this.handleEditContact(index)}
              >
                <Check />
              </IconButton>
            </div>
          </div>
          <div className="contact-block-form">
            <TextField
              id={`edit_contactname_${index}`}
              label="Nombre"
              placeholder="Nombre"
              value={this.state.contacts[index].name}
              onChange={(e) => this.handleContactEditChange(e, index, "name")}
            />
            <TextField
              id={`edit_contactlastname_${index}`}
              label="Apellido"
              placeholder="Apellido"
              value={this.state.contacts[index].last_name}
              onChange={(e) =>
                this.handleContactEditChange(e, index, "last_name")
              }
            />
            <TextField
              id={`edit_position_${index}`}
              label="Position"
              placeholder="Position"
              value={this.state.contacts[index].position}
              onChange={(e) =>
                this.handleContactEditChange(e, index, "position")
              }
            />
            <TextField
              id={`edit_phone_${index}`}
              label="Teléfono"
              placeholder="Teléfono"
              value={this.state.contacts[index].phone}
              onChange={(e) => this.handleContactEditChange(e, index, "phone")}
              InputProps={{
                inputProps: {
                  pattern: "[0-9]*",
                },
                onKeyPress: (event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                },
              }}
            />
            <TextField
              id={`edit_email_${index}`}
              label="Correo electrónico"
              placeholder="Correo electrónico"
              value={this.state.contacts[index].email}
              onChange={(e) => this.handleContactEditChange(e, index, "email")}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div key={index} className="contact-block">
          <div className="contact-block-head">
            <Typography>Contacto #{index + 1}</Typography>
            <div className="contact-block-head-icons">
              <IconButton
                variant="contained"
                color="secondary"
                className=""
                onClick={() => this.handleEditContact(index)}
              >
                <Edit />
              </IconButton>
              <IconButton
                variant="contained"
                color="secondary"
                className=""
                onClick={() => this.handleRemoveContact(index)}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
          <div className="contact-block-form">
            <Typography>{`Nombre: ${contact.name}`}</Typography>
            <Typography>{`Apellido: ${contact.last_name}`}</Typography>
            <Typography>{`Cargo: ${contact.position}`}</Typography>
            <Typography>{`Teléfono: ${contact.phone}`}</Typography>
            <Typography>{`Correo electrónico: ${contact.email}`}</Typography>
          </div>
        </div>
      );
    }
  };
  handleContactEditChange = (event, index, field) => {
    const { contacts } = this.state;
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = event.target.value;
    this.setState({ contacts: updatedContacts });
  };
  handleEditContact = (index) => {
    const { contactEditState } = this.state;
    this.setState({
      contactEditState: {
        ...contactEditState,
        [index]: !contactEditState[index],
      },
    });
  };
  handleChangeIndustry = (event, value, prevValues) => {
    if (value === "ALIMENTOS") {
      this.setState({ showSector: true });
    } else {
      this.setState({ showSector: false });
    }
    this.props.setState(this.structuring("industry", value, prevValues));
    this.forceUpdate();
  };
  handleChangeStateClient = (event, value, prevValues) => {
    this.props.setState(this.structuring("stateClient", value, prevValues));
    this.forceUpdate();
  };

  handleChangeSector = (event, value, prevValues) => {
    this.props.setState(this.structuring("sector", value, prevValues));
    this.forceUpdate();
  };
  handleChangeCoordinator = (_, newInputValue, prevValues) => {
    this.props.setState(
      this.structuring("coordinator", newInputValue, prevValues)
    );
    this.forceUpdate();
  };

  render() {
    const { classes } = this.props;
    const { contacts, newContact, hasContacts } = this.state;
    const buttonAdd = hasContacts
      ? "Agregar otro contacto"
      : "Agregar contacto";
    const contactBlocks = contacts?.map((contact, index) =>
      this.renderContactData(contact, index)
    );
    const listIndustry = [
      "ALIMENTOS",
      "DOMISANITARIO",
      "ENVASES",
      "INDUSTRIALES",
      "COMEX",
    ];
    const listSector = [
      "Cárnicos",
      "Lácteos",
      "Farináceos",
      "Azucarados",
      "Vegetales",
      "Bebidas y Helados",
      "Bebidas Fermentadas y Alcohólicas",
      "Estimulantes y Fruitivos",
      "Correctivos y Coadyuvantes, Grasos, Aceites",
      "Dietéticos",
      "Comidas Preparadas",
      "Aditivos, Concentrados, Aislados",
      "Otros",
    ];
    return (
      <Paper elevation={3}>
        <ModalAddress
          show={this.state.show_commercial_address}
          address={this.props.commercial_address}
          setStateParent={(state) => this.props.setState(state)}
          setState={(state) => this.setState(state)}
          name="commercial_address"
          title="Dirección comercial"
        />
        <ModalAddress
          show={this.state.show_fiscal_address}
          address={this.props.fiscal_address}
          setStateParent={(state) => this.props.setState(state)}
          setState={(state) => this.setState(state)}
          name="fiscal_address"
          title="Dirección fiscal"
        />
        <form className={classes.root + " m-3"} onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                Cliente: {this.props.name}
              </Typography>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col">
                  <Typography variant="body1" color="textSecondary">
                    Activo
                  </Typography>
                  <Checkbox
                    id="active"
                    checked={this.props.active}
                    onChange={this.handleCheckboxChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="name"
                label="Razón social"
                placeholder="Razón social"
                value={this.props.name}
                onChange={this.handleTextFieldChange}
                required={true}
                error={!this.props.name}
              />
            </div>
            <div className="col-sm-6">
              <Autocomplete
                id="coordinator"
                options={this.props.users}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                getOptionLabel={(option) => {
                  return option || "";
                }}
                value={this.props.coordinator}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Coordinador *"
                    error={!this.props.coordinator}
                  />
                )}
                onChange={(event, newInputValue) =>
                  this.handleChangeCoordinator(event, newInputValue, this.props)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="fantasy_name"
                label="Nombre de fantasía"
                placeholder="Nombre de fantasía"
                value={this.props.fantasy_name}
                onChange={this.handleTextFieldChange}
                required={true}
                error={!this.props.fantasy_name}
              />
            </div>
            <div className="col-sm-6">
              <Autocomplete
                id="assign_to"
                options={this.props.users}
                getOptionSelected={(option, value) => {
                  return option === value;
                }}
                getOptionLabel={(option) => {
                  return option || "";
                }}
                value={this.props.assign_to}
                renderInput={(params) => (
                  <TextField {...params} label="Usuario a cargo" />
                )}
                onChange={(event, newInputValue) =>
                  this.handleChangeUser(event, newInputValue, this.props)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="taxes_id"
                label="CUIT *"
                placeholder="CUIT"
                value={this.props.taxes_id}
                onChange={this.handleChangeTaxes}
                {...(this.state.error_taxes_id && {
                  error: true,
                  helperText: this.state.error_taxes_id,
                })}
              />
            </div>
            <div className="col-sm-6">
              <Autocomplete
                id="industry"
                options={[...listIndustry]}
                value={this.props.industry}
                getOptionSelected={(listIndustry, value) => {
                  return listIndustry === value;
                }}
                getOptionLabel={(listIndustry) => {
                  return listIndustry || "";
                }}
                onChange={(event, newInputValue) =>
                  this.handleChangeIndustry(event, newInputValue, this.props)
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Industria" />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="commercial_address"
                label="Dirección comercial"
                placeholder="Dirección Comercial"
                disabled={true}
                value={this.state.commercial_address}
                className="input-address"
              />
              <IconButton
                onClick={this.showModalCommercialAddress}
                className="pencil-address"
                id="commercial_address_button"
              >
                <EditOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="fiscal_address"
                label="Dirección fiscal"
                placeholder="Dirección fiscal"
                disabled={true}
                value={this.state.fiscal_address}
                className="input-address"
              />
              <IconButton
                onClick={this.showFiscalAddress}
                className="pencil-address"
                id="fiscal_address_button"
              >
                <EditOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextareaAutosize
                id="comments"
                label="Comentarios"
                placeholder="Comentarios"
                value={this.props.comments}
                onChange={this.handleTextFieldChange}
                className="textarea-comments"
              />
            </div>
            {this.state.showSector && (
              <div className="col-sm-6">
                <Autocomplete
                  id="sector"
                  onChange={(event, newInputValue) =>
                    this.handleChangeSector(event, newInputValue, this.props)
                  }
                  options={[...listSector]}
                  value={this.props.sector}
                  getOptionSelected={(listSector, value) => {
                    return listSector === value;
                  }}
                  getOptionLabel={(listSector) => {
                    return listSector || "";
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Rubro" />
                  )}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.active && (
              <div className="col">
                <Autocomplete
                  id="stateClient"
                  options={["Alfa", "Beta", "Gamma", "Delta"]}
                  value={this.props.stateClient}
                  style={{ width: 300 }}
                  getOptionSelected={(option, value) => {
                    return option === value;
                  }}
                  getOptionLabel={(option) => {
                    return option || "";
                  }}
                  onChange={(event, newInputValue) =>
                    this.handleChangeStateClient(
                      event,
                      newInputValue,
                      this.props
                    )
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo de Cliente" />
                  )}
                />
              </div>
            )}
          </div>
          <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
            Contacto
          </Typography>
          <div className="contact-blocks">{contactBlocks}</div>
          <div className="contact-form">
            <TextField
              id="name"
              label="Nombre"
              placeholder="Nombre"
              value={newContact.name}
              onChange={this.handleTextFieldChangeContact}
              required={!hasContacts}
              error={!newContact.name && !hasContacts}
              helperText={this.state.errorContactName}
            />
            <TextField
              id="last_name"
              label="Apellido"
              placeholder="Apellido"
              value={newContact.last_name}
              onChange={this.handleTextFieldChangeContact}
              required={!hasContacts}
              error={!newContact.last_name && !hasContacts}
              helperText={this.state.errorContactLastname}
            />
            <TextField
              id="position"
              label="Cargo"
              placeholder="Cargo"
              value={newContact.position}
              onChange={this.handleTextFieldChangeContact}
              required={!hasContacts}
              error={!this.state.newContact.position && !hasContacts}
              helperText={this.state.errorContactPosition}
            />
            <TextField
              id="phone"
              label="Teléfono"
              placeholder="Teléfono"
              value={newContact.phone}
              onChange={(event) => {
                const input = event.target.value;
                const regex = /^[0-9]*$/;
                if (regex.test(input) || input === "") {
                  this.setState({
                    newContact: {
                      ...this.state.newContact,
                      phone: input,
                    },
                    errorContactPhone: "",
                  });
                }
              }}
              required={!hasContacts}
              error={!this.state.newContact.phone && !hasContacts}
              helperText={this.state.errorContactPhone}
            />
            <TextField
              id="email"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              value={newContact.email}
              onChange={this.handleTextFieldChangeContact}
              required={!hasContacts}
              error={!this.state.newContact.email && !hasContacts}
              helperText={this.state.errorContactEmail}
            />
            <Button
              variant="contained"
              color="primary"
              className="mt-3"
              onClick={this.handleAddContact}
              startIcon={<Add />}
            >
              {buttonAdd}
            </Button>
          </div>
          <div>
            {(AuthService.userIsEditor() || AuthService.userIsAdmin()) && (
              <Button
                variant="contained"
                color="primary"
                className="mt-4 mr-3 mb-3"
                onClick={this.onSubmit}
                disabled={this.state.contacts.length < 1}
              >
                Guardar
              </Button>
            )}
            <Button
              variant="contained"
              color="default"
              className="mt-4 mb-3"
              onClick={this._back}
            >
              Volver
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withRouter(withStyles(useStyles)(ClientForm));
