import '../Search.css';
import { withRouter } from "react-router";
import { Button, FormControl, Paper, TextField, Typography } from "@material-ui/core";
import BaseComponent from "./BaseComponent";
class ContainerSearch extends BaseComponent {

    state = {};

    constructor(props) {
        super(props);
        this.state.elevation = 0;
        this.state.className = "mt-3 pt-3 pr-4 pl-4 container-search";
        if (!this.props.insideTable) {
            this.state.elevation = 3;
            this.state.className += " pb-3 mb-4";
        }
    }

    render() {
        return (
            <Paper elevation={this.state.elevation} className={this.state.className + " containerSearch"}>
                <Typography variant="h5" className="col-sm-1 title">
                    {this.props.title}
                </Typography>
                <FormControl onSubmit={this.props.handleSubmit} className="border-container ml-3 col-sm-3">
                    <div>
                        <TextField id="q" className="inputSearch mr-2" label="" placeholder="Buscar"
                            value={this.props.q} onChange={this.props.handleTextFieldChange} onKeyPress={this.props.handleKeypress}
                        />
                        {/* <TextField id="name" className="mr-2" label="Razón social" placeholder="Razón social"
                                value={this.props.props.name} onChange={this.props.handleTextFieldChange} onKeyPress={this.props.handleKeypress} />
                                <TextField id="commercial_address" className="ml-1" label="Dirección Comercial" placeholder="Dirección Comercial"
                            value={this.props.props.commercial_address} onChange={this.props.handleTextFieldChange} onKeyPress={this.props.handleKeypress} /> */}
                    </div>
                </FormControl>
                <div className="col-sm-1 float-right">
                    {
                        this.props.allowNew &&
                        <Button variant="contained" color="primary" className="float-right"
                            href={"/" + this.props.modelName + "/new/?" + this.props.paramsToNew}
                            onClick={this._linkTo("/" + this.props.modelName + "/new/?" + this.props.paramsToNew)}>
                            Nuevo
                        </Button>
                    }
                </div>
                <div className="clear"></div>
            </Paper>);
    }
}
export default withRouter(ContainerSearch);