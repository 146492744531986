import UserForm from './UserForm';
import { withRouter } from "react-router";
import UserService from '../services/UserService';
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseUser from './BaseUser';
import AuthService from '../services/AuthService';

class UserEdit extends BaseUser {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        if (!AuthService.userIsAdmin()) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getUser();
    }

    _handleSubmit = (event) => {

        event.preventDefault();
        if (this.state.error)
            return;
        this.setState({ loading: true });

        const user = UserService.buildUser(this.state);

        UserService.getInstance().update(user).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.props.history.push('/user/view/' + user._id);
                return;
            }

            this.showToast('No se pudo guardar el registro');
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
                <UserForm handleSubmit={this._handleSubmit}
                    _id={this.state?._id}
                    name={this.state?.name}
                    buttonPassword={AuthService.userIsAdmin()}
                    last_name={this.state?.last_name}
                    username={this.state?.username}
                    password={this.state?.password}
                    email={this.state?.email}
                    admin={this.state?.admin}
                    editor={this.state?.editor}
                    readonly={this.state?.readonly}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                    state={this.state}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(UserEdit))