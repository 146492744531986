import { LinearProgress } from '@material-ui/core';
import BaseUser from './BaseUser';
import UserService from '../services/UserService';
import UserForm from './UserForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';

class UserNew extends BaseUser {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        if (this.state.error)
            return;
        const user = UserService.buildUser(this.state);
        UserService.getInstance().create(user).then(response => {
            this.setState({ loading: false });
            if (response && response.data) {
                this.props.history.push('/user/view/' + response.data._id);
                return;
            }
            this.showToast('Error al crear Usuario');
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
                <UserForm handleSubmit={this._handleSubmit}
                    buttonPassword={false}
                    showPassword={true}
                    name={this.state?.name}
                    last_name={this.state.last_name}
                    username={this.state.username}
                    password={this.state.password}
                    email={this.state.email}
                    admin={this.state.admin}
                    editor={this.state.editor}
                    readonly={this.state.readonly}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                    state={this.state}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(UserNew));