import queryString from "query-string";
import { Response, ResponseError } from "../interfaces/Response";
import ApiService from "./ApiService";

class CRUDService {
  modelName: String = "";

  async getAll(page: any, rowsPerPage: any, query: any): Promise<Response> {
    try {
      const param = {
        page: page,
        // rowsPerPage: rowsPerPage,
        ...query,
      };
      return await ApiService.requestGet(
        ApiService.getApiUrl() +
          "/" +
          this.modelName +
          "?" +
          queryString.stringify(param)
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async count(query: any) {
    try {
      return await ApiService.requestGet(
        ApiService.getApiUrl() +
          "/" +
          this.modelName +
          "/count?" +
          queryString.stringify(query)
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async list(param: any): Promise<Response> {
    try {
      return await ApiService.requestGet(
        ApiService.getApiUrl() +
          "/" +
          this.modelName +
          "/list?" +
          queryString.stringify(param)
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async getById(_id: number) {
    try {
      return await ApiService.requestGet(
        ApiService.getApiUrl() + "/" + this.modelName + "/" + _id
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async create(data: any) {
    try {
      return await ApiService.requestPost(
        ApiService.getApiUrl() + "/" + this.modelName + "/create/",
        data
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async update(data: any) {
    try {
      return await ApiService.requestPost(
        ApiService.getApiUrl() + "/" + this.modelName + "/update/" + data._id,
        data
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }

  async delete(id: any) {
    try {
      return await ApiService.requestPost(
        ApiService.getApiUrl() + "/" + this.modelName + "/delete/" + id
      );
    } catch (error) {
      console.error(error);
      return new ResponseError(error as string);
    }
  }
}

export default CRUDService;
