import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CircularLoading from "../components/CircularLoading";
import ApplicationService from "../services/ApplicationService";
import { getByIdInArray } from "../utilHook";

export const ApplicationEditPriority = ({
    priority: priorityProp,
    applicationId,
    edit = true
}) => {

    const priorities = ApplicationService.priorities();
    const [priority, setPriority] = useState(getByIdInArray(priorities, priorityProp));
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event, newInputValue) => {
        setPriority(newInputValue);
        setLoading(true);
        ApplicationService.getInstance().updatePriority(applicationId, newInputValue).then(response => {
            setLoading(false);
            if (response && response.success) {
                enqueueSnackbar("Prioridad actualizada");
                setPriority(newInputValue);
                return;
            }
            setPriority(priority);
            enqueueSnackbar("Error al cambiar prioridad");
        })
    }

    useEffect(() => {
        setPriority(getByIdInArray(priorities, priorityProp));
        // eslint-disable-next-line
    }, [priorityProp]);

    return <>
        {loading ?
            <CircularLoading size={25} />
            :
            edit ?
                <Autocomplete
                    id="priority"
                    options={priorities}
                    getOptionSelected={(option, value) => { return option._id === value._id || option.name === value.name; }}
                    getOptionLabel={(option) => { return option.name || ''; }}
                    value={priority}
                    required={true}
                    renderInput={(params) => <TextField {...params}
                        InputLabelProps={{ shrink: true }}
                        label="" />}
                    onChange={(event, newInputValue) => handleChange(event, newInputValue)}
                    disableClearable={true}
                />
                :
                priority?.name
        }
    </>;
}