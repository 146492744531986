import BaseComponent from "../components/BaseComponent";
import UserService from "../services/UserService";

class BaseUser extends BaseComponent {

    baseState = {
        loading: true,
        _id: '',
        error: false,
        name: '',
        last_name: '',
        username: '',
        password: '',
        last_login: '',
        last_ip: '',
        email: '',
        admin: false,
        editor: false,
        readonly: false
    }

    getUser(user_id) {
        if (!user_id)
            user_id = this.state._id;

        this.setState({ loading: true });
        UserService.getInstance().getById(user_id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                delete response.data.password;
                this.setState(response.data);
                return;
            }
            this.showToast('Error al carga usuario');
        })
    }

    validateConfirmPassword(password, confirmPassword) {
        this.setState({ "error_confirmPassword": null });

        if (!this.state.passwordChanged) {
            return true;
        }

        if (password !== confirmPassword) {
            this.setState({ "error_confirmPassword": "Las contraseñas no son iguales" });
            return false;
        }
        return true;
    }

    validateUsername(username) {
        if (username && username.length > 4) {
            this.setState({ "error_username": null });
            return true;
        }
        this.setState({ "error_username": "Ingrese un nombre de usuario mayor a 4 caracteres" });
        return false;
    }
}

export default BaseUser;