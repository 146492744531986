import { Button } from '@material-ui/core';
import AuthService from '../services/AuthService';
export const ProductButtonSubmit = ({ handleSubmit, back }) => {
    return (
        <>
            {
                (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3" onClick={handleSubmit}>
                    Guardar
                </Button>
            }
            <Button variant="contained" color="default" className="mt-4 mb-3" onClick={back}>
                Volver
            </Button>
        </>
    );
}