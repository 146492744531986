import Modal from 'react-bootstrap/Modal';
import ApplicationEdit from '../application/ApplicationEdit';

export const ModalStatus = ({ show = false, onHide, applicationId, onSave, title, ...rest }) => {

    return (
        <>
            {show ? "true" : "false"}
            <Modal
                show={show}
                onHide={onHide}
                dialogClassName="modal-90w"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {show ?
                        <ApplicationEdit id={applicationId} handleSubmit={() => onSave()} handleCancel={onHide} /> :
                        null}
                </Modal.Body>
            </Modal>
        </>
    );
}
