import ClientOrganizationForm from './ClientOrganizationForm';
import { withRouter } from "react-router";
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseClientOrganization from './BaseClientOrganization';
import AuthService from '../services/AuthService';

class ClientOrganizationEdit extends BaseClientOrganization {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getClientsList();
        this.getOrganizationsList();
        this.getClientOrganization();
    }

    _handleSubmit = (event) => {

        event.preventDefault();
        if (this.state.error)
            return;

        this.update(true);
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <ClientOrganizationForm
                    _id={this.state?._id}
                    handleSubmit={this._handleSubmit}
                    organization_id={this.state?.organization_id}
                    organizations={this.state?.organizations}
                    client_id={this.state?.client_id}
                    clients={this.state?.clients}
                    files={this.state?.files}
                    bucketFiles={this.state?.bucketFiles}
                    loading={this.state?.loading}
                    onDeleteFile={this.onDeleteFile}
                    setState={state => this.setState(state)}
                    onFileChange={this.onFileChange}
                    error={this.state.error}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(ClientOrganizationEdit))