import '../Form.css';
import { Button, withStyles, Paper, TextField, Typography } from '@material-ui/core';
import { withRouter } from "react-router";
import BaseDiminutive from './BaseDiminutives';
import AuthService from '../services/AuthService';
import "./style.css";

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class DiminutiveForm extends BaseDiminutive {
    constructor(props) {
        super(props);
        this.state = {
            show_address: false,
            errorword: false,
            errordiminutive: false,
        };
    }

    componentDidMount() {
    }

    _handleTextFieldChange = (event) => {
        this.setState({ ["error" + event.target.id]: false });
        if (!event.target.value) {
            this.setState({ ["error" + event.target.id]: true });
        }
        this.props.setState(this.structuring(event.target.id, event.target.value));
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={3} >
                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Diminutivo: {this.props.word}
                    </Typography>
                    <div className="row">
                        <div className="col-sm-6">
                            <TextField id="word" label="Palabra" placeholder="Palabra" error={this.state.errorword}
                                value={this.props.word} onChange={this._handleTextFieldChange} />
                        </div>
                        <div className="col-sm-6">
                            <TextField id="diminutive" label="Diminutivo" placeholder="Diminutivo" error={this.state.errordiminutive}
                                value={this.props.diminutive} onChange={this._handleTextFieldChange} />
                        </div>
                    </div>
                    {
                        (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                        <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3" onClick={this.props.handleSubmit}>
                            Guardar
                        </Button>
                    }
                    <Button variant="contained" color="default" className="mt-4 mb-3" onClick={this._back}>
                        Volver
                    </Button>
                </form >
            </Paper>
        );
    }
}

export default withRouter(withStyles(useStyles)(DiminutiveForm))