import { Grid } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import BaseComponent from './BaseComponent';

export class DateRange extends BaseComponent {
    render = () => {
        return (
            <Grid container>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={this.props.dateName + "_from"}
                            className="mt-3 mr-2"
                            label={this.props.label + " desde"}
                            cancelLabel="Cancelar"
                            clearLabel="Limpiar"
                            clearable={true}
                            value={this.props.date_from}
                            onChange={this.props.handleDateFromChange}
                            invalidDateMessage="Fecha inválida"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={this.props.dateName + "_to"}
                            className="mt-3 mr-2"
                            label={this.props.label + " hasta"}
                            cancelLabel="Cancelar"
                            clearLabel="Limpiar"
                            clearable={true}
                            value={this.props.date_to}
                            onChange={this.props.handleDateToChange}
                            invalidDateMessage="Fecha inválida"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        );
    }
}