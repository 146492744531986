import { LinearProgress } from '@material-ui/core';
import ApplicationService from '../services/ApplicationService';
import ApplicationForm from './ApplicationForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseApplication from './BaseApplication';
import ProductService from '../services/ProductService';
import queryString from 'query-string';
import AuthService from '../services/AuthService';

class ApplicationNew extends BaseApplication {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        this.state = {
            ...this.baseState,
            product_id: params.product_id,
            return_product_view: params.product_id
        };
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getProduct();
        this.getProceedingsList();
        this.getOrganizationsList(true);
        this.getUsersList(true);
        this.getStatusesList();
    }

    _handleSubmit = (event) => {

        event.preventDefault();

        if (this.hasError())
            return;
        this.setState({ loading: true });
        const application = ApplicationService.buildApplication(this.state);

        ApplicationService.getInstance().create(application).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {

                if (this.state.return_product_view) {
                    this.props.history.push('/' + ProductService.modelName + '/view/' + this.state.product_id);
                    return;
                }

                this.props.history.push('/' + ApplicationService.modelName + '/view/' + response.data._id);
                return;
            }

            this.removeLastIteration();
            this.showToast('No se pudo guardar el registro');
        });
    }

    removeLastIteration = () => {
        if (Array.isArray(this.state.iterations))
            this.state.iterations.pop();
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <ApplicationForm
                    client_id={this.state?.client_id}
                    fileError={this.state.fileError}
                    handleSubmit={this._handleSubmit}
                    iterations={this.state?.iterations}
                    last_iteration={this.state?.last_iteration}
                    onDeleteFile={this.onDeleteFile}
                    onFileChange={this.onFileChange}
                    organization_id={this.state?.organization_id}
                    organizations={this.state?.organizations}
                    priority={this.state?.priority || 2}
                    proceeding_id={this.state?.proceeding_id}
                    proceedings={this.state?.proceedings}
                    product_id={this.state?.product_id}
                    product={this.state?.product}
                    setState={state => this.setState(state)}
                    statuses={this.state?.statuses}
                    statusesList={this.state?.statusesList}
                    users={this.state?.users}
                    validateFiles={this.validateFiles}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(ApplicationNew));