import { ResponseError } from "../interfaces/Response";
import ApiService from "./ApiService";

export default class AuthService {

    static setToken = (token?: string) => {
        if (token) {
            localStorage.setItem('TOKEN_AUTH', token);
            return;
        }
        localStorage.removeItem('TOKEN_AUTH');
    };
    static setRefreshToken = (refreshToken?: string) => {
        if (refreshToken) {
            localStorage.setItem('REFRESH_TOKEN_AUTH', refreshToken);
            return;
        }
        localStorage.removeItem('REFRESH_TOKEN_AUTH');
    };

    static setUserLogged = (user?: any) => {
        if (user) {
            localStorage.setItem('USER_LOGGED', JSON.stringify(user));
            return;
        }

        localStorage.removeItem('USER_LOGGED');
    };

    static getToken = () => {
        return localStorage.getItem('TOKEN_AUTH');
    }

    static refreshToken = () => {
        return localStorage.getItem('REFRESH_TOKEN_AUTH');
    }

    static getUserLogged = () => {
        if (localStorage.getItem('USER_LOGGED'))
            return JSON.parse(localStorage.getItem('USER_LOGGED') || '');
    }

    static userIsAdmin = () => {
        var user: any = AuthService.getUserLogged();
        return user && user.admin;
    }

    static userIsEditor = () => {
        var user: any = AuthService.getUserLogged();
        return user && user.editor;
    }

    static userIsReadonly = () => {
        var user: any = AuthService.getUserLogged();
        return user && user.readonly;
    }

    static addHeader = (header: any = {}) => {
        const token = AuthService.getToken();

        if (!token)
            return header;

        return {
            ...header,
            Authorization: `Bearer ${token}`
        }
    }

    static async login(username: string, password: string) {
        try {
            const response = await ApiService.requestPost(
                ApiService.getApiUrl() + '/auth/login/',
                {
                    username,
                    password
                }
            );

            if (response && response.data) {
                AuthService.setToken(response.data.token);
                AuthService.setRefreshToken(response.data.refreshToken);
                AuthService.setUserLogged(response.data.user);
            }
            return response;
        } catch (error) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }

    static async logout() {
        try {
            AuthService.setToken();
            AuthService.setRefreshToken();
            AuthService.setUserLogged();
            document.location.href = "/";
        } catch (error) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }
}