import ClienteForm from "./ClientForm";
import { withRouter } from "react-router";
import ClientService from "../services/ClientService";
import { Button, LinearProgress, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import ProductTable from "../product/ProductTable";
import { withSnackbar } from "notistack";
import BaseClient from "./BaseClient";
import ClientOrganizationTable from "../clientOrganization/ClientOrganizationTable";
import ClientOrganizationService from "../services/ClientOrganizationService";
import AuthService from "../services/AuthService";
import ContainerSearch from "../components/ContainerSearch";
import ProductService from "../services/ProductService";

class ClientEdit extends BaseClient {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.baseState,
      _id: this.props.match.params.id,
    };
  }

  componentDidMount() {
    if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
      this.showToast("No tiene permisos para ingresar");
      this._back();
      this.props.history.push("/");
    }
    this.getClient();
    this.getProducts();
    this.getClientOrganizations();
    this.getUsersList(true);
  }

  _handleSubmit = (event) => {
    console.log("HERE");
    event.preventDefault();
    if (this.state.error) return;
    this.setState({ loading: true });
    const client = ClientService.buildClient(this.state);
    ClientService.getInstance()
      .update(client)
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          this.props.history.push("/client/view/" + client._id);
          return;
        }

        this.showToast("No se pudo guardar el registro");
      });
  };

  render() {
    return (
      <div className="clientEdit">
        {this.state.loading && <LinearProgress />}
        <ClienteForm
          active={this.state?.active}
          comments={this.state?.comments}
          commercial_address={this.state?.commercial_address}
          contact={this.state?.contact}
          contacts={this.state?.contacts}
          coordinator={this.state?.coordinator}
          display_name={this.state?.display_name}
          email={this.state?.email}
          error={this.state.error}
          fantasy_name={this.state?.fantasy_name}
          fiscal_address={this.state?.fiscal_address}
          handleSubmit={this._handleSubmit}
          industry={this.state?.industry}
          name={this.state?.name}
          phone={this.state?.phone}
          position={this.state?.position}
          sector={this.state?.sector}
          showSector={this.state.industry === "ALIMENTOS"}
          setState={(state) => this.setState(state)}
          stateClient={this.state?.stateClient}
          taxes_id={this.state?.taxes_id}
          users={this.state.users}
          assign_to={this.state.assign_to}
        />
        <Paper elevation={3} className="pt-1 mt-2">
          <ContainerSearch
            handleSubmit={this.handleProductSubmit}
            handleKeypress={(e) => this.handleEnterPress(e, this.getProducts)}
            handleTextFieldChange={this.handleProductTextFieldChange}
            title="Productos"
            modelName={ProductService.modelName}
            allowNew={AuthService.userIsAdmin() || AuthService.userIsEditor()}
            q={this.props.q}
            insideTable={true}
          />
          <ProductTable
            loading={this.state.loadingProduct}
            classes={this.state.classes}
            rows={this.state.products}
            totalCount={this.state.totalCount}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            onPageChange={this.onProductChangePage}
            onRowsPerPageChange={this.onProductChangeRowsPerPage}
            hideClient={true}
          />
        </Paper>
        <Paper elevation={3} className="pt-1 mt-2">
          <Typography variant="h5" component="h2" className="mt-2 ml-3 pr-3">
            Organismos
            {AuthService.userIsAdmin() && (
              <Button
                variant="contained"
                color="primary"
                className="mb-3 float-right"
                href={
                  "/" +
                  ClientOrganizationService.modelName +
                  "/new/?client_id=" +
                  this.state._id
                }
              >
                Nuevo
              </Button>
            )}
          </Typography>
          <ClientOrganizationTable
            loading={this.state.loadingOrganization}
            classes={this.state.classes}
            rows={this.state.clientOrganizations}
            totalCount={this.state.totalCountOrganization}
            page={this.state.pageOrganization}
            rowsPerPage={this.state.rowsPerPageOrganization}
            onPageChange={this.onClientOrganizationPageChange}
            search={this.getClientOrganizations}
            onRowsPerPageChange={this.onClientOrganizationRowsPerPageChange}
            hideClient={true}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(withSnackbar(ClientEdit));
