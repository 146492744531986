import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import ProceedingsList from "./ProceedingsList";
import ProceedingEdit from "./ProceedingEdit";
import ProceedingNew from "./ProceedingNew";
import ProceedingView from "./ProceedingView";

function ProceedingRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <ProceedingEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <ProceedingNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <ProceedingView />
                    </Route>
                    <Route path={`${path}/`}>
                        <ProceedingsList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default ProceedingRoutes