import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseOrganization from "./BaseOrganization";
import AuthService from "../services/AuthService";
import FileRow from "../file/FileRow";

class OrganizationView extends BaseOrganization {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id,
        }
    }

    componentDidMount() {
        this.getOrganization();
    }

    render() {
        return (
            <>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}
                    <Typography variant="h5" component="h2">
                        Organismo
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Organismo:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.organization_name || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Dependencia:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.dependence || "-"}</Typography>

                    {/* <Typography variant="body2" color="textSecondary">
                        Licencia Renovable:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.renewable || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Vence:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.expires || "-"}</Typography> */}

                    <Typography variant="body2" color="textSecondary">
                        Teléfono:
                    </Typography>
                    <Typography variant="body1" className="ml-2">
                        {
                            (this.state.phone?.switchboard || "") + " " +
                            (this.state.phone?.fax || "") + " " +
                            (this.state.phone?.other || "") + " "
                        }
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Correo electrónico:
                    </Typography>
                    <Typography variant="body1" className="ml-2">
                        {this.state.email || "-"}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                        Dirección:
                    </Typography>
                    <Typography variant="body1" className="ml-2">
                        {this.state.address?.address || "-"}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                        Documentación requerida:
                    </Typography>
                    <div className="ml-2">

                        {
                            this.state.bucketDocumentations?.map((file, index) =>
                                <FileRow file={file} index={index} key={file?.filePath || index} />
                            )
                        }
                    </div>

                    <div className={"pt-3"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/organization/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </Paper>
            </>
        );
    }
}
export default withSnackbar(withRouter(OrganizationView));