import ProductService from './../services/ProductService';
import ProductForm from './ProductForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withStyles, LinearProgress, Paper } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseProduct from './BaseProduct';
import queryString from 'query-string';
// import ApplicationForm from '../application/ApplicationForm';
import { ApplicationInnerForm } from '../application/ApplicationInnerForm';
import { ApplicationFactory } from '../application/ApplicationFactory';
import ApplicationService from '../services/ApplicationService';
import { ProductButtonSubmit } from './ProductButtonSubmit';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class ProductNew extends BaseProduct {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search)
        this.state = {
            ...this.baseState,
            client_id: params.client_id,
            return_client_view: params.client_id,
            organizations: [],
            last_iteration: {},
            priorities: [],
            expiration_date: null,
        };

    }

    componentDidMount() {
        if (this.state.client_id) {
            this.getClientById(this.state.client_id);
        } else {
            this.getClientsList();
        }
        this.getProceedingsList();
        this.getOrganizationsList();
        this.getUsersList();
        this.getStatusesList();
        this.setState({ priorities: ApplicationService.priorities() });
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        if (!this.validate()) {
            this.setState({ loading: false });
            return false;
        }

        if (this.hasError()) {
            this.setState({ loading: false });
            return;
        }
        const product = {
            name: this.state.name,
            client_id: this.state.client_id,
            fantasy_name: this.state.fantasy_name,
            display_name: this.state.display_name,
            description: this.state.description,
            short_name: this.reduce(this.state.name),
            brand: this.state.brand,
            origin: this.state.origin,
            client: this.state.client
        }

        ProductService.getInstance().create(product).then(async response => {
            this.setState({ loading: false });
            if (response && response.data) {
                this.showToast('Producto creado');
                this.setState({ loading: true });
                const product_id = response.data._id;
                const newApplication = {
                    ...this.state,
                    product_id: product_id,
                    last_iteration: this.state.last_iteration,
                    iterations: [this.state.last_iteration],
                    priority: this.state.priority
                };
                const application = ApplicationService.buildApplication(newApplication);
                const responseApplication = await ApplicationService.getInstance().create(application);
                if (responseApplication && responseApplication.data) {
                    this.showToast('Presentación creada');
                }

                if (this.state.return_client_view) {
                    this.props.history.push('/client/view/' + this.state.client_id);
                    return;
                }

                this.props.history.push('/product/view/' + product_id);
                return;
            }
            this.showToast('Error al crear producto');
        });
    }

    _handleChangeOrg = (event, newInputValue, fieldId) => {
        this._handleChangeAutocomplete(event, newInputValue, fieldId);
        // this.findDocumentation(newInputValue);
        this.getProceedingsList(newInputValue);
    }

    _handleChangeStatus = (_, newInputValue, prevValues) => {
        this.setState({
            last_iteration: {
                ...this.state.last_iteration,
                status: newInputValue,
                substatus: "",
                asign_to: ""
            }
        });
        // Set status in this class because setState method is not same, it's update after
        this.state.last_iteration.status = newInputValue;
        this.validateFiles();
    }

    _handleChangeSubstatus = (_, newInputValue, prevValues) => {
        this.setState({
            last_iteration: {
                ...this.state.last_iteration,
                substatus: newInputValue,
            }
        });
    }
    _handleChangeUser = (_, newInputValue, prevValues) => {
        this.setState({
            last_iteration: {
                ...this.state.last_iteration,
                asign_to: newInputValue,
            }
        });
    }

    _handleChangePriorities = (_, newInputValue, prevValues) => {
        this.setState(this.structuring("priority", newInputValue, prevValues));
        this.forceUpdate();
    }

    handleTextFieldChange = (event) => {
        this.setState(this.structuring(event.target.id, event.target.value, this.state));
        return true;
    }
    handleDateChange = (date) => {
        ApplicationFactory.handleDateChange(this, date);
    }

    hasError = () => {
        return ApplicationFactory.hasError(this);
    }

    validateAsignTo = () => {
        return ApplicationFactory.validateAsignTo(this);
    }

    validateExpirationDate = (proceeding) => {
        return ApplicationFactory.validateExpirationDate(this, proceeding);
    }

    validateExpirationDateEmpty = () => {
        return ApplicationFactory.validateExpirationDateEmpty(this);
    }

    validateComments = () => {
        return ApplicationFactory.validateComments(this);
    }
    validateField0 = () => {
        return ApplicationFactory.validateField0(this);
    }

    validateField1 = () => {
        return ApplicationFactory.validateField1(this);
    }

    validateFiles = () => {
        return ApplicationFactory.validateFiles(this);
    }

    onFileChange = (event) => {
        ApplicationFactory.onFileChange(this, event);
    }

    onDeleteFile = (filePath) => {
        ApplicationFactory.onDeleteFile(this, filePath);
    }

    onDeleteFile = (filePath) => {
        ApplicationFactory.onDeleteFile(this, filePath);
    }

    render() {
        return (
            <Paper elevation={3} >
                {this.state.loading && <LinearProgress />}
                <ProductForm
                    handleSubmit={this._handleSubmit}
                    name={this.state?.name}
                    client_id={this.state?.client_id}
                    client={this.state?.client}
                    fantasy_name={this.state?.fantasy_name}
                    display_name={this.state?.display_name}
                    description={this.state?.description}
                    brand={this.state?.brand}
                    origin={this.state?.origin}
                    clients={this.state?.clients}
                    loading={this.state?.loading}
                    setState={state => this.setState(state)}
                    hideSubmit={true}
                    elevation={0}
                />
                <ApplicationInnerForm
                    {...this.props}
                    priorities={this.state.priorities}
                    handleChangeProceeding={this._handleChangeAutocomplete}
                    _handleChangeAutocomplete={this._handleChangeAutocomplete}
                    _handleChangeOrg={this._handleChangeOrg}
                    _handleChangeStatus={this._handleChangeStatus}
                    _handleChangeSubstatus={this._handleChangeSubstatus}
                    _handleChangeUser={this._handleChangeUser}
                    _handleChangePriorities={this._handleChangePriorities}
                    clickById={this.clickById}
                    handleDateChange={this.handleDateChange}
                    handleTextFieldChange={this.handleTextFieldChange}
                    last_iteration={this.state.last_iteration}
                    newProduct={true}
                    onDeleteFile={this.onDeleteFile}
                    onFileChange={this.onFileChange}
                    organizations={this.state.organizations || []}
                    organization_id={this.state.organization_id}
                    proceedings={this.state.proceedings || []}
                    proceeding_id={this.state.proceeding_id}
                    statuses={this.state?.statuses || []}
                    expiration_date={this.state?.expiration_date}
                    statusesList={this.state?.statusesList || []}
                    users={this.state.users || []}
                    priority={this.state?.priority || 2}
                    fileError={this.state?.fileError}
                />
                <div className="m-3">
                    <ProductButtonSubmit handleSubmit={this._handleSubmit} back={this._back} />
                </div>
            </Paper>
        );
    }
}
export default withSnackbar(withStyles(useStyles)(withRouter(ProductNew)));