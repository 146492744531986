import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import ClientOrganizationList from "./ClientOrganizationList";
import ClientOrganizationEdit from "./ClientOrganizationEdit";
import ClientOrganizationNew from "./ClientOrganizationNew";
// import ClientOrganizationView from "./ClientOrganizationView";

function ClientOrganizationRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <ClientOrganizationEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <ClientOrganizationNew />
                    </Route>
                    {/* <Route path={`${path}/view/:id`}>
                        <ClientOrganizationView />
                    </Route> */}
                    <Route path={`${path}/`}>
                        <ClientOrganizationList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default ClientOrganizationRoutes