import OrganizationService from './../services/OrganizationService';
import OrganizationForm from './OrganizationForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { LinearProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseOrganization from './BaseOrganization';

class OrganizationNew extends BaseOrganization {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = { ...this.baseState };
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    _handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });

        const organization = OrganizationService.buildOrganization(this.state);
        OrganizationService.getInstance().create(organization).then(response => {
            this.setState({ loading: false });

            if (response && response.data) {
                this.props.history.push('/organization/view/' + response.data._id);
                this.showToast('Organismo creado');
                return;
            }
            this.showToast('Error al crear Organismo');
        });
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <OrganizationForm
                    address={this.state?.address}
                    bucketDocumentations={this.state?.bucketDocumentations}
                    dependence={this.state?.dependence}
                    email={this.state?.email}
                    error={this.state.error}
                    fields={this.state?.fields}
                    handleSubmit={this._handleSubmit}
                    loading={this.state?.loading}
                    onDeleteFile={this.onDeleteFile}
                    onFileChange={this.onFileChange}
                    opening={this.state?.opening}
                    organization_name={this.state?.organization_name}
                    phone={this.state?.phone}
                    renewable={this.state?.renewable}
                    setState={state => this.setState(state)}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(OrganizationNew));