import BaseComponent from "../components/BaseComponent";
import FileService from "../services/FileService";
import OrganizationService from '../services/OrganizationService';

class BaseOrganization extends BaseComponent {

    baseState = {
        _id: '',
        address: { address: '' },
        contacts: '',
        dependence: '',
        fields: '',
        organization_name: '',
        commercial_address: '',
        phone: { switchboard: '', fax: '', other: '' },
        email: '',
        opening: '',
        renewable: false,
        documentation: '',
        expires: false,
        loading: true,
        documentations: [],
        bucketDocumentations: []
    }

    getOrganization() {
        // uso params.id porque el set state es async
        OrganizationService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                return;
            }
            this.showToast('Error al cargar Organismo');
        })
    }

    onDeleteFile = (filePath) => {
        this.setState({ loading: true });
        FileService.getInstance().deleteFile(filePath).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                let files = this.state.bucketDocumentations;
                files = files.filter(file => file.filePath !== filePath);

                this.setState({ bucketDocumentations: files });
                this.forceUpdate();
                this.updateData();
                return;
            }
            if (response?.data?.code === "storage/object-not-found") {
                this.showToast('El archivo ya no existe');
                let files = this.state.bucketDocumentations;
                files = files.filter(file => file.filePath !== filePath);

                this.setState({ bucketDocumentations: files });
                this.forceUpdate();
                this.updateData();
                return;
            }
            this.showToast('No se pudo borrar el archivo');
        }).catch((error) => {
            this.setState({ loading: false });
            this.showToast('No se pudo borrar el archivo');
            console.error(error);
        });
    }

    updateData = () => {
        if (!this.state._id) {
            return;
        }
        this.update();
    }

    update = (redirect = false) => {
        this.setState({ loading: true });
        const organization = OrganizationService.buildOrganization(this.state);

        OrganizationService.getInstance().update(organization).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                if (redirect)
                    this.props.history.push('/' + OrganizationService.modelName + '/view/' + this.state._id);
                return;
            }
            this.showToast('No se pudo guardar el registro');
        });
    }

    onFileChange = (event) => {
        this.setState({ loading: true });
        FileService.getInstance().uploadFile(event.target.files[0]).then(response => {
            event.target.value = null;
            this.setState({ loading: false });
            if (response && response.success) {
                let files = this.state.bucketDocumentations.slice();
                files.push(response.data);
                this.setState(this.structuring("bucketDocumentations", files, this.state));
                this.forceUpdate();
                this.updateData();
                return;
            }
            this.showToast('No se pudo cargar el archivo');
        });
    };
}

export default BaseOrganization;