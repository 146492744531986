import '../Form.css';
import { Button, withStyles, Paper, Typography } from '@material-ui/core';
import { withRouter } from "react-router";
import 'date-fns';
import IterationTable from './IterationTable';
import AuthService from '../services/AuthService';
import OrganizationService from '../services/OrganizationService';
import BaseApplication from './BaseApplication';
import { ApplicationInnerForm } from './ApplicationInnerForm';
import { ApplicationFactory } from './ApplicationFactory';
import ApplicationService from '../services/ApplicationService';
import { StatusEnum } from '../StatusEnum';
import ProceedingService from '../services/ProceedingService';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class ApplicationForm extends BaseApplication {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            product: '',
            client: '',
            expiration_date: null,
            requiredDocumentation: '',
            loadingRequiredDocumentation: false,
            priorities: []
        }
    }

    componentDidMount() {
        this.handleDateChange(this.props.last_iteration?.expiration_date || null);
        if (this.props.organization_id) {
            this.findDocumentation(this.props.organization_id);
        }

        this.setState({ priorities: ApplicationService.priorities() });
    }
    componentDidUpdate() {
        this.setState({ expiration_date: this.props.last_iteration?.expiration_date });
    }

    handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value, this.props));
        this.forceUpdate();
        return true;
    }

    handleDateChange = (date) => {
        ApplicationFactory.handleDateChange(this, date);
    }

    _handleChangeStatus = (_, newInputValue, prevValues) => {
        this.props.setState(this.structuring("last_iteration.status", newInputValue, prevValues));
        this.props.setState(this.structuring("last_iteration.substatus", "", prevValues));
        this.forceUpdate();
        this.props.validateFiles();
        this.getFieldByReinscription(this.props?.proceeding_id?.name, newInputValue);
        this.asignWhenStatusChange(newInputValue);
    }
    asignWhenStatusChange = (newInputValue) => {
        if (newInputValue === StatusEnum.IN_PROCESS || newInputValue === StatusEnum.IN_PROCEEDING || newInputValue === StatusEnum.OBSERVED) {
            const username = AuthService.getUserLogged()?.username;
            this.props.setState(this.structuring("last_iteration.asign_to", username, this.props));
        }
    }

    _handleChangeSubstatus = (_, newInputValue, prevValues) => {
        this.props.setState(this.structuring("last_iteration.substatus", newInputValue, prevValues));
        this.forceUpdate();
    }
    _handleChangeUser = (_, newInputValue, prevValues) => {
        this.props.setState(this.structuring("last_iteration.asign_to", newInputValue, prevValues));
        this.forceUpdate();
    }

    _handleChangeOrg = (event, newInputValue, fieldId) => {
        this._handleChangeAutocomplete(event, newInputValue, fieldId);
        this.findDocumentation(newInputValue);
        this.getProceedingsList(newInputValue);
    }

    _handleChangePriorities = (_, newInputValue, prevValues) => {
        this.props.setState(this.structuring("priority", newInputValue, prevValues));
        this.forceUpdate();
    }

    findDocumentation = (organization) => {
        if (!organization || !organization._id) {
            this.setState({ requiredDocumentation: '' });
            return;
        }
        this.setState({ loadingRequiredDocumentation: true });
        OrganizationService.getInstance().getById(organization._id).then((response) => {
            this.setState({ loadingRequiredDocumentation: false });
            if (response && response.data) {
                this.setState({ requiredDocumentation: response.data.documentation });
            }
        });
    }

    handleChangeProceeding = (event, newValue, fieldId, prevValues) => {
        this._handleChangeAutocomplete(event, newValue, fieldId, prevValues);
        this.getFieldByReinscription(newValue?.name, this.props?.last_iteration?.status);
    }

    getFieldByReinscription = (proceeding_id = '', status = '') => {

        const isReinscriptionOrRenovation = ProceedingService.isReinscriptionOrRenovation(proceeding_id);
        const statusesAllowed = [StatusEnum.READY_TO_PROCESS, StatusEnum.PENDING, StatusEnum.TO_BEAT];
        if (statusesAllowed.includes(status) && isReinscriptionOrRenovation) {
            this.setState({ loading: true });
            ApplicationService.getInstance().getAll(0, 1000, { product_id: this.props?.product?._id }).then((response) => {
                this.setState({ loading: false });
                if (response && response.success) {
                    const applications = response.data.filter((application) => application.organization_id?._id === this.props?.organization_id?._id);
                    if (applications.length > 0) {
                        const lastApplication = applications.sort((a, b) => b?.last_iteration?.date - a?.last_iteration?.date)[0];
                        this.handleDateChange(lastApplication?.last_iteration?.expiration_date);
                        this.props.setState(this.structuring("last_iteration.field_1", lastApplication?.last_iteration?.field_1, this.props));
                        this.forceUpdate();
                    }
                }
            });
        }
        if (!statusesAllowed.includes(status) && isReinscriptionOrRenovation) {
            this.props.setState(this.structuring("last_iteration.expiration_date", null, this.props));
            this.forceUpdate();
        }
    }

    render() {
        return (
            <Paper elevation={3} >
                <ApplicationInnerForm
                    {...this.props}
                    handleChangeProceeding={this.handleChangeProceeding}
                    _handleChangeAutocomplete={this._handleChangeAutocomplete}
                    _handleChangeOrg={this._handleChangeOrg}
                    _handleChangePriorities={this._handleChangePriorities}
                    _handleChangeStatus={this._handleChangeStatus}
                    _handleChangeSubstatus={this._handleChangeSubstatus}
                    _handleChangeUser={this._handleChangeUser}
                    clickById={this.clickById}
                    expiration_date={this.state?.expiration_date}
                    handleDateChange={this.handleDateChange}
                    handleTextFieldChange={this.handleTextFieldChange}
                    onDeleteFile={this.props.onDeleteFile}
                    onFileChange={this.props.onFileChange}
                    priorities={this.state.priorities}
                />
                <div className="m-3">
                    {
                        (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                        <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3 save-application" onClick={this.props.handleSubmit}>
                            Guardar
                        </Button>
                    }
                    <Button variant="contained" color="default" className="mt-4 mb-3" onClick={() => {
                        if (this.props.handleCancel) {
                            return this.props.handleCancel();
                        }
                        this._back();
                    }
                    }>
                        Volver
                    </Button>
                </div>
                <div className="m-4">
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Iteraciones:
                    </Typography>
                    <IterationTable iterations={this.props.iterations} />
                </div>
            </Paper >
        );
    }


}

export default withRouter(withStyles(useStyles)(ApplicationForm))