import ClientOrganizationService from './../services/ClientOrganizationService';
import ClientOrganizationForm from './ClientOrganizationForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { LinearProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseClientOrganization from './BaseClientOrganization';
import queryString from 'query-string';
import { withConfirmHooks } from '../confirmHook';
class ClientOrganizationNew extends BaseClientOrganization {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);

        this.state = {
            ...this.baseState,
            client_id: params.client_id,
            return_client_view: params.client_id
        };
    }

    componentDidMount() {
        this.loadClients();
        this.loadOrganizations();
        this.props.confirm.setEnabled(false);
    }

    loadClients() {
        if (this.state.client_id) {
            this.getClientById(this.state.client_id);
            return;
        }
        this.getClientsList();
    }

    loadOrganizations() {
        if (this.state.organization_id) {
            this.getOrganizationById(this.state.organization_id);
            return;
        }
        this.getOrganizationsList();
    }

    _handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        const organization = ClientOrganizationService.buildClientOrganization(this.state);
        ClientOrganizationService.getInstance().create(organization).then(response => {
            this.setState({ loading: false });
            this.props.confirm.setEnabled(false);
            if (response && response.data) {
                //TODO: ojo porque el client_id despues es el object del client
                this.props.history.push('/client/view/' + this.state.client_id._id);
                return;
            }
            this.showToast('Error al crear Organismo');
        });
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <ClientOrganizationForm
                    handleSubmit={this._handleSubmit}
                    organization_id={this.state?.organization_id}
                    organizations={this.state?.organizations}
                    client_id={this.state?.client_id}
                    clients={this.state?.clients}
                    files={this.state?.files}
                    bucketFiles={this.state?.bucketFiles}
                    loading={this.state?.loading}
                    onDeleteFile={this.onDeleteFile}
                    setState={state => this.setState(state)}
                    onFileChange={(e) => {
                        this.props.confirm.setEnabled(true);
                        this.onFileChange(e);
                    }}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(withConfirmHooks(ClientOrganizationNew)));