import CRUDService from './CRUDService';

class ClientOrganizationService extends CRUDService {
    private static instance: ClientOrganizationService;
    public static modelName: String = 'clientOrganization';
    private constructor() {
        super();
        this.modelName = ClientOrganizationService.modelName;
    }

    public static getInstance(): ClientOrganizationService {
        if (!this.instance)
            this.instance = new ClientOrganizationService();
        return this.instance;
    }

    public static buildClientOrganization(data: any): any {
        if (data.files && data.files.length) {
            data.files.forEach(function (elemento: any, i: any) {
                data.files[i]['data'] = undefined;
                data.files[i]['createdAt'] = undefined;
                data.files[i]['type'] = undefined;
                data.files[i]['updatedAt'] = undefined;
                data.files[i]['__v'] = undefined;
            });
        }
        return {
            _id: data._id,
            organization_id: data.organization_id,
            client_id: data.client_id,
            files: data.files,
            bucketFiles: data.bucketFiles,
        }
    }
}

export default ClientOrganizationService