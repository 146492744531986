import BaseComponent from "../components/BaseComponent";
import ClientService from "../services/ClientService";
import ProductService from "../services/ProductService";
import UserService from "../services/UserService";
import ClientOrganizationService from "../services/ClientOrganizationService";

class BaseClient extends BaseComponent {
  baseState = {
    active: true,
    lastActiveChange: null,
    loading: true,
    loadingProduct: true,
    loadingOrganization: true,
    _id: "",
    error: false,
    products: [],
    clientOrganizations: [
      {
        files: [],
      },
    ],
    editor: null,
    comments: "",
    industry: "",
    sector: "",
    stateClient: "",
    assign_to: null,
    commercial_address: {
      address: "",
      city: "",
      country: "",
      postal_code: "",
      province: "",
    },
    contact: "",
    contacts: [],
    fantasy_name: "",
    fantasy_names: "",
    fiscal_address: {
      address: "",
      city: "",
      country: "",
      postal_code: "",
      province: "",
    },
    coordinator: "",
    name: "",
    taxes_id: "",
    phone: {
      switchboard: "",
    },
    email: "",
    // de product
    totalCount: 100,
    rowsPerPage: 10,
    page: 0,
    totalCountOrganization: 100,
    rowsPerPageOrganization: 10,
    pageOrganization: 0,
    users: [],
  };

  getClient() {
    this.setState({ loading: true });
    ClientService.getInstance()
      .getById(this.state._id)
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          this.setState(response.data);
          return;
        }
        this.showToast("Error al carga cliente");
      });
  }

  getProducts = (page, rowsPerPage, sort = null) => {
    this.setState({ loadingProduct: true });
    // TODO:esto tambien está en productList
    var query = {
      ...this.state.query,
      client_id: this.state._id,
    };
    if (sort) {
      // como los atributos de query se agregan y no se borran, puedo mantener el sort
      // si necesidad de setearlo en cada consulta
      query.sort = sort;
    }
    ProductService.getInstance()
      .getAll(page, rowsPerPage, query)
      .then((response) => {
        this.setState({ loadingProduct: false });
        if (response && response.success) {
          this.setState({
            products: response.data,
          });
          return;
        }
        this.showToast("Error al listar productos");
      });

    ProductService.getInstance()
      .count(query)
      .then((response) => {
        if (response && response.success) {
          this.setState({ totalCount: response.data });
        }
      });
  };

  getUsersList(notEmpty = false) {
    UserService.getInstance()
      .list()
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          const users = response.data.map((user) => user.name)?.sort();

          if (notEmpty && users?.length) {
            this.setState({ users: users.filter((user) => !!user) });
            return;
          }
          this.setState({ users: users });
          return;
        }
        this.showToast("Error al listar usuarios");
      });
  }

  onProductChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getProducts(newPage);
  };

  onProductChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.getProducts(0, event.target.value);
  };

  handleProductKeypress = (event) => {
    if (event.charCode === 13 || event.which === 13) {
      this.getProducts();
    }
  };

  handleProductTextFieldChange = (event) => {
    const query = {
      ...this.state.query,
      [event.target.id]: event.target.value,
    };
    this.setState({ query });
  };

  handleProductSubmit = (event) => {
    event.preventDefault();
    this.getProducts(0);
  };

  getClientOrganizations = (page, rowsPerPage, sort = null) => {
    this.setState({ loadingOrganization: true });
    // TODO:esto tambien está en productList
    var query = { client_id: this.state._id };

    if (sort) {
      // como los atributos de query se agregan y no se borran, puedo mantener el sort
      // si necesidad de setearlo en cada consulta
      query.sort = sort;
    }
    ClientOrganizationService.getInstance()
      .getAll(page, rowsPerPage, query)
      .then((response) => {
        this.setState({ loadingOrganization: false });
        if (response && response.success) {
          this.setState({
            clientOrganizations: response.data,
          });
          return;
        }
        this.showToast("Error al listar organismos");
      });

    ClientOrganizationService.getInstance()
      .count(query)
      .then((response) => {
        if (response && response.success) {
          this.setState({ totalCountOrganization: response.data });
        }
      });
  };

  onClientOrganizationPageChange = (event, newPage) => {
    this.setState({ pageOrganization: newPage });
    this.getClientOrganizations(newPage);
  };

  onClientOrganizationRowsPerPageChange = (event) => {
    this.setState({
      rowsPerPageOrganization: parseInt(event.target.value, 10),
    });
    this.setState({ pageOrganization: 0 });
    this.getClientOrganizations(0, event.target.value);
  };
}

export default BaseClient;
