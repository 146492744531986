import ProceedingService from '../services/ProceedingService';
import ProceedingForm from './ProceedingForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { LinearProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseProceeding from './BaseProceeding';

class ProceedingNew extends BaseProceeding {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = { ...this.baseState };
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    _handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });

        const proceeding = ProceedingService.buildProceeding(this.state);
        ProceedingService.getInstance().create(proceeding).then(response => {
            this.setState({ loading: false });

            if (response && response.data) {
                this.props.history.push('/' + ProceedingService.modelName + '/view/' + response.data._id);
                return;
            }
            this.showToast('Error al crear Organismo');
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
                <ProceedingForm handleSubmit={this._handleSubmit}
                    organizations={this.state?.organizations}
                    name={this.state?.name}
                    loading={this.state?.loading}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(ProceedingNew));