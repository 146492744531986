import "./ClientDetail.css";
import { Chip, Grid, Typography } from "@material-ui/core";
import BaseComponent from "../components/BaseComponent";

export default class ClientDetail extends BaseComponent {
  render() {
    return (
      <>
        <Grid className="client-file" container>
          <Grid className="client-file-row" item>
            <Grid container className="client-file-head" xs={12} sm={6} md={3}>
              <Typography variant="h5" component="h2">
                Cliente
              </Typography>
              <Typography variant="body1" className="ml-2">
                <Chip
                  variant="default"
                  color={this.props.client.active ? "primary" : "secondary"}
                  label={this.props.client.active ? "Activo" : "Inactivo"}
                />
              </Typography>
            </Grid>
            <Grid container className="client-file-data">
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="body2" color="textSecondary">
                  Razón social:
                </Typography>
                <Typography
                  variant="body1"
                  className="ml-2"
                  component="a"
                  href={"/client/view/" + this.props.client._id}
                >
                  {" "}
                  {this.props.client.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="body2" color="textSecondary">
                  Coordinador:
                </Typography>
                <Typography variant="body1" className="ml-2">
                  {" "}
                  {this.props.client.coordinator || "-"}
                </Typography>
              </Grid>              
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="body2" color="textSecondary">
                  Dirección comercial:
                </Typography>
                <Typography variant="body1" className="ml-2">
                  {" "}
                  {this.props.client.commercial_address?.address || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="body2" color="textSecondary">
                  Dirección fiscal:
                </Typography>
                <Typography variant="body1" className="ml-2">
                  {" "}
                  {this.props.client.fiscal_address?.address || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="body2" color="textSecondary">
                  CUIT:
                </Typography>
                <Typography variant="body1" className="ml-2">
                  {" "}
                  {this.props.client.taxes_id || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="client-file-row" item sm={6}>
            <div className={"pt-1 mb-1"}>Contacto</div>

            <Grid container className="client-file-data">
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" color="textSecondary">
                  Nombre:
                </Typography>
                {this.props.client.contacts?.map((item) => {
                  return (
                    <Typography variant="body1" className="ml-2">
                      {item.name || "-"}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" color="textSecondary">
                  Apellido:
                </Typography>
                {this.props.client.contacts?.map((item) => {
                  return (
                    <Typography variant="body1" className="ml-2">
                      {item.last_name || "-"}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" color="textSecondary">
                  Cargo:
                </Typography>
                {this.props.client.contacts?.map((item) => {
                  return (
                    <Typography variant="body1" className="ml-2">
                      {item.position || "-"}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" color="textSecondary">
                  Teléfono:
                </Typography>
                {this.props.client.contacts?.map((item) => {
                  return (
                    <Typography variant="body1" className="ml-2">
                      {item.phone || "-"}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" color="textSecondary">
                  Correo electrónico:
                </Typography>
                {this.props.client.contacts?.map((item) => {
                  return (
                    <Typography variant="body1" className="ml-2">
                      {item.email || "-"}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
            {this.props.client.comments && (
              <Grid container className="client-file-row">
                <Grid container className="client-file-data">
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body2" color="textSecondary">
                      Comentarios:
                    </Typography>

                    <Typography variant="body1" className="ml-2">
                      {this.props.client.comments || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
