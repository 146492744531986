import BaseComponent from "../components/BaseComponent";
// import FileService from "../services/FileService";
import DeleteIcon from '@material-ui/icons/Delete';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import BaseFirebase from "./base.ts";
import { withSnackbar } from "notistack";

class FileRow extends BaseComponent {

    componentDidMount() {
        var storage = getStorage(BaseFirebase.app, BaseFirebase.bucket);

        this.setState({ httpsReference: "" });

        if (this.props.file?.filePath) {
            getDownloadURL(ref(storage, this.props.file?.filePath)).then((url) => {
                this.setState({ httpsReference: url });
            }).catch(() => {
                console.error("No se encontró el archivo \"" + this.props.file?.name + "\"");
                // this.showToastError("No se encontró el archivo \"" + this.props.file?.name + "\"");
            });
        }
    }

    onclickDelete = (event) => {
        event.preventDefault();
        this.props.onDelete(this.props.file.filePath);
    }

    render() {
        return (
            <div key={this.props?.index}>
                {
                    !this.props.hide && this.props.file &&
                    <div className="row bucketFileRow">
                        <div className="col-sm-10 mt-1">
                            <a href={this.state?.httpsReference} target="_blank" rel="noreferrer" className="bucketFileName">
                                {this.props.file.name}
                            </a>
                        </div>
                        {this.props.delete &&
                            <div className="col-sm-2 mt-1">
                                <a onClick={this.onclickDelete} href="#a" id={this.props.file._id} className="bucketFileDelete">
                                    <DeleteIcon />
                                </a>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default withSnackbar(FileRow);
