import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import UserService from '../services/UserService';
import { Alert } from '@material-ui/lab';
import BaseComponent from '../components/BaseComponent';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import TablePaginationEs from '../components/TablePaginationEs';
import UserTable from './UserTable';
import ContainerSearch from '../components/ContainerSearch';
import AuthService from '../services/AuthService';

class UsersList extends BaseComponent {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            totalCount: 100,
            rowsPerPage: 10,
            page: 0,
            query: {},
            rows: [],
            deleteUserId: null
        };
    }
    componentDidMount() {
        if (!AuthService.userIsAdmin()) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.search();
    }

    search = (page = 0, rowsPerPage = null) => {
        this.setState({ page: page });
        this.setState({ loading: true });
        if (!rowsPerPage)
            rowsPerPage = this.state.rowsPerPage;

        UserService.getInstance().getAll(page, rowsPerPage, this.state.query).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ rows: response.data });
                return;
            }
            this.showToast('Error al listar usuarios');
        });
        UserService.getInstance().count(this.state.query).then((response) => {
            if (response && response.success) {
                this.setState({ totalCount: response.data });
            }
        })
    };

    handleChangePage = (event, newPage) => {
        this.setState({ loading: true });
        this.setState({ page: newPage });
        this.search(newPage);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ loading: true });
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
        this.search(0, event.target.value);
    };

    handleTextFieldChange = (event) => {
        const query = {
            ...this.state.query,
            [event.target.id]: event.target.value
        };
        this.setState({ query });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.search();
    }

    render() {
        return (
            <div>
                <ContainerSearch
                    handleSubmit={this.handleSubmit}
                    handleKeypress={(e) => this.handleEnterPress(e, this.search)}
                    handleTextFieldChange={this.handleTextFieldChange}
                    title="Usuarios"
                    modelName={UserService.modelName}
                    allowNew={AuthService.userIsAdmin() || AuthService.userIsEditor()}
                    q={this.props.q}
                />
                <Paper elevation={3}>
                    {
                        this.state.rows.length !== 0 &&
                        <TablePaginationEs
                            count={this.state.totalCount}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    }

                    <UserTable loading={this.state.loading}
                        classes={this.state.classes}
                        rows={this.state.rows}
                        search={this.search}
                    />
                    {
                        !this.state.loading && this.state.rows.length === 0 &&
                        <Alert severity="error">No se encontraron resultados</Alert>
                    }
                    {
                        this.state.rows.length !== 0 &&
                        <TablePaginationEs
                            count={this.state.totalCount}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    }
                </Paper>
            </div >
        );
    }
}

export default withRouter(withSnackbar(UsersList));