import "./ClientTable.css";
import BaseComponent from "../components/BaseComponent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { NavLink } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CircularLoading from "../components/CircularLoading";
import { DeleteForever } from "@material-ui/icons";
import ModalDelete from "../components/ModalDelete";
import AuthService from "../services/AuthService";
import { Checkbox, Grid, TextField } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Autocomplete } from "@material-ui/lab";

export default class ClientTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      classes: makeStyles({
        table: {
          minWidth: 650,
        },
      }),
      showModalDelete: false,
      deleteClientId: null,
      users: [],
      showSector: false,
    };
  }

  componentDidMount() {
    this.getUsersList();
    console.log(this.props);
  }

  showModalDelete = (id) => (event) => {
    event.preventDefault();
    this.setState({ showModalDelete: true });
    this.setState({ deleteClientId: id });
  };

  render() {
    const arrowIcon = (property) => {
      if (this.props.orderBy === property) {
        return this.props.order === "asc" ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        );
      }
      return null;
    };
    const listIndustry = [
      "ALIMENTOS",
      "DOMISANITARIO",
      "ENVASES",
      "INDUSTRIALES",
      "COMEX",
    ];
    const listSector = [
      "Cárnicos",
      "Lácteos",
      "Farináceos",
      "Azucarados",
      "Vegetales",
      "Bebidas y Helados",
      "Bebidas Fermentadas y Alcohólicas",
      "Estimulantes y Fruitivos",
      "Correctivos y Coadyuvantes, Grasos, Aceites",
      "Dietéticos",
      "Comidas Preparadas",
      "Aditivos, Concentrados, Aislados",
      "Otros",
    ];
    return (
      <div>
        {this.props.loading && <CircularLoading />}
        {!this.props.loading && (
          <TableContainer className="table-client mb-3">
            <Grid
              container
              className="filters-client"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                marginBottom: "50px",
              }}
            >
              <Grid item className="filter-item">
                <Autocomplete
                  id="state_active"
                  options={["Todos", "Activos", "Inactivos"]}
                  getOptionLabel={(option) => option || ""}
                  onChange={this.props.onChangeStateActive}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado Activo"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item className="filter-item">
                <Autocomplete
                  id="state"
                  options={["Todos", "Alfa", "Beta", "Gamma", "Delta"]}
                  getOptionLabel={(option) => option || ""}
                  onChange={this.props.onChangeState}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de Cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item className="filter-item">
                <Autocomplete
                  id="coordinator"
                  options={this.state.users}
                  getOptionLabel={(option) => option || ""}
                  onChange={this.props.onChangeCoordinator}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Coordinador"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item className="filter-item">
                <Autocomplete
                  id="asign_to"
                  options={this.state.users}
                  getOptionLabel={(option) => option || ""}
                  onChange={this.props.onChangeAssignTo}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asignado a"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item className="filter-item">
                <Autocomplete
                  id="industry"
                  options={[...listIndustry]}
                  value={this.props.industry}
                  getOptionLabel={(option) => option || ""}
                  onChange={(event, newValue) => {
                    this.props.onChangeIndustry(event, newValue);
                    this.setState({ showSector: newValue === "ALIMENTOS" });
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industria"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {this.state.showSector && (
                <Grid item className="filter-item">
                  <Autocomplete
                    id="sector"
                    options={[...listSector]}
                    value={this.props.sector}
                    getOptionLabel={(option) => option || ""}
                    onChange={this.props.onChangeSector}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Rubro" variant="outlined" />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Table
              className={this.state.classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell
                    className="column-title"
                    onClick={() => this.props.handleRequestSort("name")}
                  >
                    Razón social
                    <span className="arrow-order">{arrowIcon("name")}</span>
                  </TableCell> */}
                  <TableCell
                    className="column-title"
                    onClick={() => this.props.handleRequestSort("fantasy_name")}
                  >
                    Fantasía
                    <span className="arrow-order">
                      {arrowIcon("fantasy_name")}
                    </span>
                  </TableCell>
                  <TableCell> Asignado a </TableCell>
                  <TableCell> Coordinador/a </TableCell>
                  <TableCell> Tipo de Cliente </TableCell>
                  <TableCell
                    className="column-title"
                    onClick={() => this.props.handleRequestSort("industry")}
                  >
                    Industria
                    <span className="arrow-order">{arrowIcon("industry")}</span>
                  </TableCell>
                  <TableCell
                    className="column-title"
                    onClick={() => this.props.handleRequestSort("sector")}
                  >
                    Rubro
                    <span className="arrow-order">{arrowIcon("sector")}</span>
                  </TableCell>
                  {/* <TableCell> Dirección comercial </TableCell> */}
                  <TableCell> Contacto </TableCell>
                  {/* <TableCell> CUIT </TableCell> */}
                  <TableCell> Activo </TableCell>
                  <TableCell className="max-width-1 text-right pr-4">
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.rows?.map((row) => (
                  <TableRow key={row._id}>
                    {/* <TableCell>
                      <NavLink to={"/client/view/" + row._id}>
                        {row.name}
                      </NavLink>
                    </TableCell> */}
                    <TableCell>
                      <NavLink to={"/client/view/" + row._id}>
                        {row.fantasy_name}
                      </NavLink>
                    </TableCell>
                    <TableCell> {row.assign_to} </TableCell>
                    <TableCell> {row.coordinator} </TableCell>
                    <TableCell> {row.stateClient} </TableCell>
                    <TableCell> {row.industry} </TableCell>
                    <TableCell> {row.sector} </TableCell>
                    {/* <TableCell> {row.commercial_address?.address} </TableCell> */}
                    <TableCell>
                      {row.contacts?.map((e, index) => (
                        <div key={index}>
                          <a href={"mailto:" + e.email}>{e.email}</a>
                        </div>
                      ))}

                      {row.phone?.switchboard}
                      {row.phone?.fax}
                    </TableCell>
                    {/* <TableCell> {row.taxes_id} </TableCell> */}
                    <TableCell>
                      <Checkbox
                        checked={row.active}
                        color="primary"
                        onClick={this.props.handleActiveChange(row)}
                      />
                    </TableCell>
                    <TableCell className="max-width-1 text-right">
                      <div className="min-width-1">
                        <NavLink to={"/client/view/" + row._id}>
                          <ListAltOutlinedIcon />
                        </NavLink>
                        {(AuthService.userIsAdmin() ||
                          AuthService.userIsEditor()) && (
                          <NavLink to={"/client/edit/" + row._id}>
                            <EditOutlinedIcon />
                          </NavLink>
                        )}
                        {AuthService.userIsAdmin() && (
                          <NavLink
                            onClick={this.showModalDelete(row._id)}
                            to="#"
                          >
                            <DeleteForever />
                          </NavLink>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <ModalDelete
          show={this.state.showModalDelete}
          link="/client/delete"
          id={this.state.deleteClientId}
          message="¿Estas seguro que desea eliminar este cliente?"
          setState={(state) => this.setState(state)}
          onSubmit={this.props.search}
        />
      </div>
    );
  }
}
