import '../Form.css';
import { Button, withStyles, Paper, TextField, Typography, LinearProgress } from '@material-ui/core';
import { withRouter } from "react-router";
import BaseClientOrganization from './BaseClientOrganization';
import { Autocomplete } from '@material-ui/lab';
import { withSnackbar } from 'notistack';
import FileRow from '../file/FileRow';
import { BackupOutlined } from '@material-ui/icons';
import OrganizationService from '../services/OrganizationService';
import AuthService from '../services/AuthService';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class ClientOrganizationForm extends BaseClientOrganization {
    constructor(props) {
        super(props);
        this.state = {
            show_address: false,
            bucketDocumentations: [],
            loadingRequiredDocumentation: false
        };
        if (this.props.organization_id) {
            this.findDocumentation(this.props.organization_id);
        }
    }

    _handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
        // this.setState(this.structuring(event.target.id, event.target.value));
    }

    _handleChangeOrg = (event, newInputValue, fieldId) => {
        this._handleChangeAutocomplete(event, newInputValue, fieldId);
        this.findDocumentation(newInputValue);
    }

    findDocumentation = (organization) => {
        if (!organization || !organization._id) {
            this.setState({ bucketDocumentations: [] });
            return;
        }
        this.setState({ loadingRequiredDocumentation: true });
        OrganizationService.getInstance().getById(organization._id).then((response) => {
            this.setState({ loadingRequiredDocumentation: false });
            if (response && response.data) {
                this.setState({ bucketDocumentations: response.data.bucketDocumentations });
            }
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={3} >
                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Organismo:
                    </Typography>
                    <div className="row">

                        <div className="col-sm-6">
                            <Autocomplete
                                id="organization_id"
                                options={this.props.organizations}
                                getOptionSelected={(option, value) => { return option._id === value._id || option.organization_name === value.organization_name; }}
                                getOptionLabel={(option) => { return option.organization_name || '' }}
                                // style={{ width: 300 }}
                                value={this.props.organization_id}
                                renderInput={(params) => <TextField {...params} label="Organismo" />}
                                onChange={(event, newInputValue) => this._handleChangeOrg(event, newInputValue, "organization_id")}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Autocomplete
                                id="client_id"
                                options={this.props.clients}
                                getOptionSelected={(option, value) => { return option._id === value._id || option.name === value.name; }}
                                getOptionLabel={(option) => { return option.name || '' }}
                                // style={{ width: 300 }}
                                value={this.props.client_id}
                                renderInput={(params) => <TextField {...params} label="Cliente" />}
                                onChange={(event, newInputValue) => this._handleChangeAutocomplete(event, newInputValue, "client_id")}
                            />
                        </div>
                    </div>
                    <div className={"pt-3"}>
                        <div className="row">
                            <div className="col-sm-6">
                                <span className="font-weight-bold">Documentación</span>
                            </div>
                            {
                                this.state.bucketDocumentations &&
                                <div className="col-sm-6">
                                    <span className="font-weight-bold">Documentación requerida</span>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                {(AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                    <>
                                        <a href="#a" onClick={() => this.clickById("inputUpload")}>
                                            <BackupOutlined />
                                        </a>
                                        <input type="file" id="inputUpload" onChange={this.props.onFileChange} hidden={true} />
                                    </>
                                }
                            </div>
                            <div className="col-sm-6">
                                {
                                    this.state.loadingRequiredDocumentation &&
                                    <LinearProgress />
                                }
                                {
                                    this.state.bucketDocumentations?.map((file, numIteration) =>
                                        <FileRow file={file} index={numIteration} key={file?.filePath || numIteration} />
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.props?.bucketFiles.map((file, index) => (
                                    <FileRow file={file} onDelete={this.props.onDeleteFile} key={file?.filePath || index} />
                                ))}
                            </div>
                        </div>
                    </div>
                    {
                        (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                        <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3" onClick={this.props.handleSubmit}>
                            Guardar
                        </Button>
                    }
                    <Button variant="contained" color="default" className="mt-4 mb-3" onClick={this._back}>
                        Volver
                    </Button>
                </form >
            </Paper>
        );
    }
}

export default withSnackbar(withRouter(withStyles(useStyles)(ClientOrganizationForm)));