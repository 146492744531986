const moment = require('moment');
export const getByIdInArray = (arrayTarget, targetId) => {
    if (!arrayTarget || !arrayTarget.length) {
        return null;
    }
    const found = arrayTarget.find((element) => element.id === targetId);
    if (found) {
        return found;
    }
    return null;
}

export const getByNameInArray = (arrayTarget, targetId) => {
    if (!arrayTarget || !arrayTarget.length) {
        return null;
    }
    const found = arrayTarget.find((element) => {
        return element?.name?.toLowerCase() === targetId.toLowerCase()
    });
    if (found) {
        return found;
    }
    return null;
}


export const sort = (
    currentSortOrder, column, valueA, valueB
) => {
    let currentA = valueA || '';
    let currentB = valueB || '';
    if (column?.type === 'date') {
        currentA = moment(valueA, 'DD/MM/YYYY');
        currentB = moment(valueB, 'DD/MM/YYYY');

        if (currentA.isAfter(currentB)) {
            return 1 * currentSortOrder;
        }
        if (currentA.isBefore(currentB)) {
            return -1 * currentSortOrder;
        }
        currentA = valueA || '';
        currentB = valueB || '';
        if (currentA?.length > currentB?.length) {
            return 1 * currentSortOrder;
        }
        if (currentA?.length < currentB?.length) {
            return -1 * currentSortOrder;
        }
        return 0;
    }
    if (currentA?.toLowerCase() > currentB?.toLowerCase()) {
        return 1 * currentSortOrder;
    }
    if (currentA?.toLowerCase() < currentB?.toLowerCase()) {
        return -1 * currentSortOrder;
    }

    return 0;
}