import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import DiminutivesList from "./DiminutivesList";
import DiminutiveEdit from "./DiminutiveEdit";
import DiminutiveNew from "./DiminutiveNew";
import DiminutiveView from "./DiminutiveView";

function DiminutiveRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <DiminutiveEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <DiminutiveNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <DiminutiveView />
                    </Route>
                    <Route path={`${path}/`}>
                        <DiminutivesList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default DiminutiveRoutes