import { DeleteForever } from "@material-ui/icons";
import { Link } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import AuthService from "../services/AuthService";
import CircularLoading from "../components/CircularLoading";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ModalDelete from "../components/ModalDelete";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePaginationEs from "../components/TablePaginationEs";
import TableRow from '@material-ui/core/TableRow';
import BaseProduct from "./BaseProduct";
import { Alert } from "@material-ui/lab";

export default class ProductTable extends BaseProduct {

    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteProductId: null,
            rows: this.props.rows
        };
        this.getDiminutives();
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
            this.setState({ rows: this.props.rows });
            this.getDiminutives();
        }
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteProductId: id });
    }

    setSort = (sortName) => (e) => {
        e.preventDefault();
        var sort = sortName + '_asc';
        if (this.state.sort === sortName + '_asc') {
            sort = sortName + '_desc';
        }
        this.setState({ sort });
        this.props.search(0, null, sort);
    };

    reduceAll = () => {
        if (
            this.state.diminutives && this.state.diminutives.length &&
            this.state.rows && this.state.rows.length
        ) {
            let rows = this.state.rows.slice();
            rows.forEach((row, index) => {
                rows[index].short_name = this.reduce(row.name);
            });
            this.setState({ rows });
        }
    }

    render() {
        return (
            <div className="product-table">
                {this.props.loading &&
                    <CircularLoading />
                }
                {
                    !this.props.loading && this.props.rows.length !== 0 &&
                    <TablePaginationEs
                        count={this.props.totalCount}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        onPageChange={this.props.onPageChange}
                        onRowsPerPageChange={this.props.onRowsPerPageChange}
                    />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Link href="#" onClick={this.setSort('short_name')} > Nombre Corto</Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={this.setSort('fantasy_name')} > Nombre de fantasía</Link>
                                    </TableCell>
                                    {
                                        !this.props.hideClient &&
                                        <TableCell>
                                            <Link href="#" onClick={this.setSort('client')} > Cliente </Link>
                                        </TableCell>
                                    }
                                    {/* <TableCell>
                                        <Link href="#" onClick={this.setSort('fantasy_name')} > Nombre de corto </Link>
                                    </TableCell> */}
                                    <TableCell>
                                        <Link href="#" onClick={this.setSort('description')} > Descripción </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={this.setSort('brand')} > Marca </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={this.setSort('origin')} > Origen </Link>
                                    </TableCell>
                                    <TableCell className="text-right pr-4"> Acciones </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    !!this.state.rows?.length ? this.state.rows.map((row, index) => (
                                        <TableRow key={index} >
                                            <TableCell>
                                                <NavLink to={"/product/view/" + row._id}>
                                                    {row.short_name ? row.short_name : row.name}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell> {row.fantasy_name} </TableCell>
                                            {
                                                !this.props.hideClient &&
                                                <TableCell>
                                                    <NavLink to={"/client/view/" + row.client?._id}>
                                                        {row.client?.name}
                                                    </NavLink>
                                                </TableCell>
                                            }
                                            <TableCell> {row.description} </TableCell>
                                            <TableCell> {row.brand} </TableCell>
                                            <TableCell> {row.origin} </TableCell>
                                            <TableCell className=" text-right">
                                                <div className="min-width-1">
                                                    <NavLink to={"/product/view/" + row._id}>
                                                        <ListAltOutlinedIcon />
                                                    </NavLink>
                                                    {
                                                        (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                                        <NavLink to={"/product/edit/" + row._id}>
                                                            <EditOutlinedIcon />
                                                        </NavLink>
                                                    }
                                                    {
                                                        AuthService.userIsAdmin() &&
                                                        <NavLink onClick={this.showModalDelete(row._id)} to="#">
                                                            <DeleteForever />
                                                        </NavLink>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )) : <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Alert severity="error">No se encontraron resultados</Alert>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {
                    !this.props.loading && this.props.rows.length !== 0 &&
                    <TablePaginationEs
                        count={this.props.totalCount}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        onPageChange={this.props.onPageChange}
                        onRowsPerPageChange={this.props.onRowsPerPageChange}
                    />
                }
                <ModalDelete
                    show={this.state.showModalDelete}
                    link="/product/delete"
                    id={this.state.deleteProductId}
                    reason={['Duplicado', 'No corresponde al cliente', 'Otro']}
                    message="¿Estas seguro que desea eliminar este producto?"
                    setState={state => this.setState(state)}
                    onSubmit={this.props.search}
                />
            </div >
        );
    }
}