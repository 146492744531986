export const StatusEnum = {
    IN_PROCESS: "En proceso",
    PENDING: "Pendiente",
    READY_TO_PROCESS: "Listo para procesar",
    READY_TO_PRESENT: "Listo para presentar",
    IN_TRANSACT: "En Trámite",
    DISCHARGED: "Baja",
    TO_BEAT: "Por vencer",
    BEATEN: "Vencido",
    OBSERVED: "Observado"
};