import DiminutiveService from '../services/DiminutiveService';
import DiminutiveForm from './DiminutiveForm';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { LinearProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseDiminutive from './BaseDiminutives';

class DiminutiveNew extends BaseDiminutive {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = { ...this.baseState };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        this.setState({ loading: true });

        const proceeding = DiminutiveService.buildDiminutive(this.state);
        DiminutiveService.getInstance().create(proceeding).then(response => {
            this.setState({ loading: false });
            if (response) {
                if (response.success) {
                    this.props.history.push('/' + DiminutiveService.modelName + '/view/' + response.data._id);
                    return;
                }
                if (response.message) {
                    return this.showToast(response.message);
                }
            }
            this.showToast('Error al crear Diminutivo');
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
                <DiminutiveForm handleSubmit={this._handleSubmit}
                    word={this.state?.word}
                    diminutive={this.state?.diminutive}
                    loading={this.state?.loading}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                />
            </div>
        );
    }
}
export default withSnackbar(withRouter(DiminutiveNew));