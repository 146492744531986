import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseDiminutive from "./BaseDiminutives";
import AuthService from "../services/AuthService";
import DiminutiveService from "../services/DiminutiveService";

class DiminutiveView extends BaseDiminutive {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id,
        }
    }

    componentDidMount() {
        this.getDiminutive();
    }

    render() {
        return (
            <div>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}
                    <Typography variant="h5" component="h2">
                        Diminutivo
                    </Typography>
                    <Typography variant="body2" color="textSecondary">Palabra:</Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.word || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">Diminutivo:</Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.diminutive || "-"}</Typography>

                    <div className={"pt-3"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/" + DiminutiveService.modelName + "/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                    </Button>
                    </div>
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(DiminutiveView));