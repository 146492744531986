import CRUDService from './CRUDService';

class DiminutiveService extends CRUDService {

    private static instance: DiminutiveService;
    public static modelName: String = 'diminutive';

    private constructor() {
        super();
        this.modelName = DiminutiveService.modelName;
    }

    public static getInstance(): DiminutiveService {
        if (!this.instance)
            this.instance = new DiminutiveService();
        return this.instance;
    }

    public static buildDiminutive(data: any): any {
        return {
            _id: data._id,
            word: data.word,
            diminutive: data.diminutive
        }
    }
}

export default DiminutiveService