import { Response, ResponseError } from '../interfaces/Response';
import ApiService from './ApiService';
import CRUDService from './CRUDService';

class StatusService extends CRUDService {

    private static instance: StatusService;
    public static modelName: String = 'status';

    private constructor() {
        super();
        this.modelName = StatusService.modelName;
    }

    public static getInstance(): StatusService {
        if (!this.instance)
            this.instance = new StatusService();
        return this.instance;
    }

    public static buildStatus(data: any): any {
        return {
            _id: data._id,
            name: data.name,
        }
    }


    public async getSubstatusesList(): Promise<Response> {
        try {
            return await ApiService.requestGet(
                ApiService.getApiUrl() + '/' + this.modelName + '/getStatusesList');
        } catch (error) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }
}

export default StatusService