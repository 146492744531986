import { Button, LinearProgress, MenuItem, TextField, Typography } from '@material-ui/core';
import BaseComponent from '../components/BaseComponent';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../services/ApiService';
import { withSnackbar } from 'notistack';


class ModalDelete extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reason: ''
        }
    }

    onHide = () => {
        this.props.setState({ showModalDelete: false });
    }

    changeReason = (event) => {
        this.setState({ reason: event.target.value });
    }

    handleConfirm = () => {
        this.setState({ loading: true });

        ApiService.requestPost(ApiService.getApiUrl() + this.props.link + '/' + this.props.id, {
            reason: this.state.reason
        }).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.props.setState({ showModalDelete: false });
                if (!this.props.onSubmit) {
                    window.location.reload();
                    return;
                }
                this.props.onSubmit();
                this.showToast('Borrado correctamente');
                return;
            }

            this.showToast('Error al borrar');
        });

    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.onHide}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Eliminar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.loading &&
                        <LinearProgress />
                    }
                    <div>
                        <Typography>
                            {this.props.message}
                        </Typography>
                    </div>
                    <div>
                        {
                            this.props.reason &&
                            <TextField id="reason" select label="Motivo" placeholder="Motivo" onChange={this.changeReason} style={{ minWidth: 260 + 'px' }} >
                                {this.props.reason.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    </div>
                    <div className="mt-3">
                        <Button variant="contained" color="primary" className="mb-3 mr-3" onClick={this.handleConfirm}>
                            Si
                        </Button>
                        <Button variant="contained" color="default" className="mb-3" onClick={this.onHide}>
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default withSnackbar(ModalDelete);