export interface Response {
    success: Boolean;
    message?: String;
    data?: any;
    count?: any;
}

export class ResponseError implements Response {
    success: Boolean;
    message?: String;
    data?: any;

    constructor(message: String, data?: any) {
        this.success = false;
        this.message = message;
        this.data = data;
    }
}

export class ResponseSuccess implements Response {
    success: Boolean;
    message?: String | undefined;
    data?: any;

    constructor(data: any) {
        this.success = true;
        this.data = data;
    }
}