import BaseComponent from "../components/BaseComponent";
import OrganizationService from "../services/OrganizationService";
import ProceedingService from '../services/ProceedingService';

class BaseProceeding extends BaseComponent {

    baseState = {
        _id: '',
        name: '',
        organizations: [],
        loading: true,
        loadingOrganizations: true
    }

    getProceeding() {
        // uso params.id porque el set state es async
        ProceedingService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                return;
            }
            this.showToast('Error al cargar Organismo');
        })
    }

    getOrganizations() {
        this.setState({ loadingOrganizations: true });
        // uso params.id porque el set state es async
        OrganizationService.getInstance().list().then((response) => {
            this.setState({ loadingOrganizations: false });
            if (response && response.success) {
                this.sort(response.data, 'organization_name');
                this.setState({ organizations: response.data });
                return;
            }
            this.showToast('Error al cargar Organismos');
        })
    }
}

export default BaseProceeding;