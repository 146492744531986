import CRUDService from './CRUDService';

class ProceedingService extends CRUDService {

    private static instance: ProceedingService;
    public static modelName: String = 'proceeding';

    private constructor() {
        super();
        this.modelName = ProceedingService.modelName;
    }

    public static getInstance(): ProceedingService {
        if (!this.instance)
            this.instance = new ProceedingService();
        return this.instance;
    }

    public static buildProceeding(data: any): any {
        return {
            _id: data._id,
            name: data.name,
            organizations: data.organizations
        }
    }

    public static isReinscriptionOrRenovation(proceedingName: string) {

        if (!proceedingName) {
            return false;
        }

        let regex = ProceedingService.getRegex('Reinscripcion');
        if (regex.exec(proceedingName)) {
            return true;
        }
        regex = ProceedingService.getRegex('Renovacion');
        if (regex.exec(proceedingName)) {
            return true;
        }
        return false;
    }

    public static getRegex(target: string) {
        const a: string[] = ['à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ'],
            e: string[] = ['È', 'É', 'Ê', 'Ë', 'è', 'é', 'ê', 'ë'],
            i: string[] = ['Ì', 'Í', 'Î', 'Ï', 'ì', 'í', 'î', 'ï'],
            o: string[] = ['Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø'],
            u: string[] = ['Ù', 'Ú', 'Û', 'Ü', 'ù', 'ú', 'û', 'ü'];

        const substitutions: any = {
            e,
            E: e,
            a,
            A: a,
            i,
            I: i,
            o,
            O: o,
            u,
            U: u
        }

        const pattern = Array.from(target).map(c => substitutions[c] ? `[${c}${substitutions[c].join("")}]` : c).join("");
        return new RegExp(pattern, "gi");
    }
}

export default ProceedingService