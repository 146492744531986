import NavBar from './components/NavBar';

function Home(props) {


    return (
        <>
            <NavBar logout={props.logout} />
            <div id="home-page">
                <div style={{
                    "display": "flex",
                    "textAlign": "center",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "height": "70vh"
                }}>
                    <img src="/logo-completo-sin-sombra.png" className="mb-4 mt-4" alt="Logo de la empresa" />
                </div>
            </div>
        </>
    )
}

export default Home