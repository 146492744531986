import { Button, FormControl, LinearProgress, TextField, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import BaseComponent from '../components/BaseComponent';
import AuthService from '../services/AuthService';
import './Login.css';

class Login extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            username: '',
            password: ''
        }
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    }

    submit = () => {
        if (this.state.error)
            return;
        this.setState({ loading: true });
        AuthService.login(this.state.username, this.state.password).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                AuthService.setToken(response.data.token);
                this.props.setToken(response.data.token);
                return;
            }
            this.showToast('No se pudo iniciar sesión');
        });
    }

    handleTextFieldChange = (event) => {
        this.setState(this.structuring(event.target.id, event.target.value));
    }

    render() {
        return (
            <div className="login-wrapper" >
                {this.state.loading && <LinearProgress className="w-100" />}
                <img src="/logo-completo-sin-sombra.png" className="mb-4 mt-4" alt="Logo de la empresa" />
                <div className="mt-2 p-4">
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3 text-center"}>
                        Iniciar sesión
                    </Typography>
                    <FormControl onSubmit={this.props.handleSubmit}>
                        <TextField id="username" label="Nombre de usuario" placeholder="Nombre de usuario"
                            variant="outlined"
                            fullWidth
                            value={this.state.username} onChange={this.handleTextFieldChange} onKeyPress={(e) => this.handleEnterPress(e, this.submit)} />
                        <TextField id="password" label="Contraseña" placeholder="Contraseña" type="password"
                            variant="outlined"
                            fullWidth
                            value={this.state.password} onChange={this.handleTextFieldChange} onKeyPress={(e) => this.handleEnterPress(e, this.submit)} />
                        <div>
                            <Button variant="contained" color="primary" className="mt-3 mb-3 float-right" onClick={this.handleSubmit}>
                                Iniciar
                            </Button>
                        </div>
                    </FormControl>
                </div>
            </div>
        );

    }
}

export default withSnackbar(Login);