import BaseComponent from "../components/BaseComponent"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { DeleteForever } from "@material-ui/icons";
import ModalDelete from "../components/ModalDelete";
import AuthService from "../services/AuthService";
import DiminutiveService from "../services/DiminutiveService";

export default class DiminutiveTable extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteDiminutiveId: null
        };
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteDiminutiveId: id });
    }

    render() {
        return (
            <div>
                {this.props.loading &&
                    <CircularLoading />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell> Palabra </TableCell>
                                    <TableCell> Diminutivo </TableCell>
                                    <TableCell className="text-right pr-4"> Acciones </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    this.props.rows.map((row) => (
                                        <TableRow key={row._id} >
                                            <TableCell>
                                                <NavLink to={"/" + DiminutiveService.modelName + "/view/" + row._id}>
                                                    {row.word}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell>
                                                {row.diminutive}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="min-width-1">
                                                    <NavLink to={"/" + DiminutiveService.modelName + "/view/" + row._id}>
                                                        <ListAltOutlinedIcon />
                                                    </NavLink>
                                                    {
                                                        (AuthService.userIsAdmin()) &&
                                                        <NavLink to={"/" + DiminutiveService.modelName + "/edit/" + row._id}>
                                                            <EditOutlinedIcon />
                                                        </NavLink>
                                                    }
                                                    {
                                                        AuthService.userIsAdmin() &&
                                                        <NavLink onClick={this.showModalDelete(row._id)} to="#">
                                                            <DeleteForever />
                                                        </NavLink>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <ModalDelete
                    show={this.state.showModalDelete}
                    link={"/" + DiminutiveService.modelName + "/delete"}
                    id={this.state.deleteDiminutiveId}
                    message="¿Estas seguro que desea eliminar este diminutivo?"
                    setState={state => this.setState(state)}
                    onSubmit={this.props.search}
                />
            </div>
        );
    }
}