import { Button, withStyles, Paper, TextField, Typography, FormControlLabel, Checkbox, FormLabel } from '@material-ui/core';
import { withRouter } from "react-router";
import UserService from '../services/UserService';
import BaseUser from './BaseUser';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 500,
            "max-width": "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class UserForm extends BaseUser {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            passwordChanged: false,
            buttonPassword: props.buttonPassword,
            showPassword: props.showPassword
        };
    }

    componentDidMount() {
    }

    handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));

        this.validateForm({
            ...this.props.state,
            [event.target.id]: event.target.value
        });
    }

    handleCheckboxChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.checked));
    }

    handleChangePassword = (event) => {
        this.setState({ 'passwordChanged': true });
        this.props.setState(this.structuring(event.target.id, event.target.value));
    }

    validateForm(newProps) {
        // no lo pongo en un if para que valide todos y despues evalue
        let validate = this.validateConfirmPassword(newProps.password, newProps.confirmPassword);
        validate &= this.validateUsername(newProps.username);

        this.props.setState({ error: !validate });
        return validate;
    }

    handleSubmit = (event) => {
        if (!this.validateForm(this.props.state)) {
            event.preventDefault();
            return;
        }

        this.props.handleSubmit(event);
    }

    clickShowPassword = (event) => {
        event.preventDefault();
        this.setState({ buttonPassword: false });
        this.setState({ showPassword: true });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={3} >
                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Usuario: {this.props.name}
                    </Typography>

                    <div>
                        <TextField id="name" label="Nombre" placeholder="Nombre" value={this.props.name} onChange={this.handleTextFieldChange} />
                        <TextField id="last_name" label="Apellido" placeholder="Apellido" value={this.props.last_name} onChange={this.handleTextFieldChange} />
                    </div>
                    <div>
                        <TextField id="username" label="Nombre de usuario" placeholder="Nombre de usuario" value={this.props.username} onChange={this.handleTextFieldChange}
                            {...(this.state.error_username && { error: true, helperText: this.state.error_username })}
                        />
                        <TextField id="email" label="Correo electrónico" placeholder="Correo electrónico" value={this.props.email} onChange={this.handleTextFieldChange} />
                    </div>
                    {
                        this.state.buttonPassword && <Button onClick={this.clickShowPassword}>Cambiar contraseña</Button>
                    }
                    {
                        this.state.showPassword &&
                        <div>
                            <TextField id="password" type="password" label="Contraseña" placeholder="Contraseña" value={this.props.password} onChange={this.handleChangePassword} />
                            <TextField id="confirmPassword" type="password" label="Confirmar Contraseña" placeholder="Confirmar Contraseña" value={this.props.confirmPassword} onChange={this.handleTextFieldChange}
                                {...(this.state.error_confirmPassword && { error: true, helperText: this.state.error_confirmPassword })}
                            />
                        </div>
                    }
                    <div style={{ marginLeft: "8px" }}>
                        <FormLabel>Perfil</FormLabel>
                        {
                            this.props.profile ?
                                (<div> {UserService.permission(this.props)} </div>) : (
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                id="admin"
                                                checked={this.props.admin}
                                                disabled={this.props.profile}
                                                onChange={this.handleCheckboxChange} />}
                                            label="Administrador"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                id="editor"
                                                checked={this.props.editor}
                                                disabled={this.props.profile}
                                                onChange={this.handleCheckboxChange} />}
                                            label="Editor"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                id="readonly"
                                                checked={this.props.readonly}
                                                disabled={this.props.profile}
                                                onChange={this.handleCheckboxChange} />}
                                            label="Solo lectura"
                                        />
                                    </div>
                                )}
                    </div>
                    <div>
                        <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this.handleSubmit}>
                            Guardar
                        </Button>
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </form >
            </Paper>
        );
    }
}

export default withRouter(withStyles(useStyles)(UserForm))