import CRUDService from "./CRUDService";

class ClientService extends CRUDService {
  private static instance: ClientService;
  public static modelName: String = "client";
  private constructor() {
    super();
    this.modelName = ClientService.modelName;
  }

  public static getInstance(): ClientService {
    if (!this.instance) this.instance = new ClientService();
    return this.instance;
  }

  public static buildClient(data: any): any {
    return {
      _id: data._id,
      active: data.active,
      taxes_id: data.taxes_id,
      comments: data.comments,
      assign_to: data.assign_to,
      commercial_address: data.commercial_address,
      contact: data.contact,
      contacts: data.contacts,
      coordinator: data.coordinator,
      email: data.contacts[0]?.email,
      fantasy_name: data.fantasy_name,
      fiscal_address: data.fiscal_address,
      industry: data.industry,
      name: data.name,
      phone: data.contacts[0]?.phone,
      position: data.contacts[0]?.position,
      sector: data.sector,
      stateClient: data.stateClient,
    };
  }
}

export default ClientService;
