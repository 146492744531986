import BaseComponent from "../components/BaseComponent"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { DeleteForever } from "@material-ui/icons";
import ModalDelete from "../components/ModalDelete";
import AuthService from "../services/AuthService";

export default class OrganizationTable extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteOrganizationId: null
        };
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteOrganizationId: id });
    }

    render() {
        return (
            <div>
                {this.props.loading &&
                    <CircularLoading />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell> Organismo </TableCell>
                                    <TableCell> Dependencia </TableCell>
                                    <TableCell> Correo electrónico </TableCell>
                                    <TableCell> Dirección </TableCell>
                                    <TableCell> Teléfono </TableCell>
                                    <TableCell className="text-right pr-4"> Acciones </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    this.props.rows.map((row) => (
                                        <TableRow key={row._id} >
                                            <TableCell>
                                                <NavLink to={"/organization/view/" + row._id}>
                                                    {row.organization_name}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell> {row.dependence} </TableCell>
                                            <TableCell> {row.email} </TableCell>
                                            <TableCell> {row.address?.address} </TableCell>
                                            <TableCell> {row.phone?.switchboard} </TableCell>
                                            <TableCell className="text-right">
                                                <div className="min-width-1">
                                                    <NavLink to={"/organization/view/" + row._id}>
                                                        <ListAltOutlinedIcon />
                                                    </NavLink>
                                                    {
                                                        (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                                        <NavLink to={"/organization/edit/" + row._id}>
                                                            <EditOutlinedIcon />
                                                        </NavLink>
                                                    }
                                                    {
                                                        AuthService.userIsAdmin() &&
                                                        <NavLink onClick={this.showModalDelete(row._id)} to="#">
                                                            <DeleteForever />
                                                        </NavLink>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <ModalDelete
                    show={this.state.showModalDelete}
                    link="/organization/delete"
                    id={this.state.deleteOrganizationId}
                    message="¿Estas seguro que desea eliminar este organizationo?"
                    setState={state => this.setState(state)}
                    onSubmit={this.props.search}
                />
            </div>
        );
    }
}