import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { DeleteForever } from "@material-ui/icons";
import ModalDelete from "../components/ModalDelete";
import ClientOrganizationService from "../services/ClientOrganizationService";
import OrganizationService from "../services/OrganizationService";
import ClientService from "../services/ClientService";
import FileRow from "../file/FileRow";
import BaseClientOrganization from "./BaseClientOrganization";
import AuthService from '../services/AuthService';

export default class ClientOrganizationTable extends BaseClientOrganization {
    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteClientOrganizationId: null
        };
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteClientOrganizationId: id });
    }

    render() {
        return (
            <div className="clientorganization-table">
                {this.props.loading &&
                    <CircularLoading />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell> Organismo </TableCell>
                                    <TableCell> Cliente </TableCell>
                                    <TableCell> Documentación </TableCell>
                                    <TableCell className="text-right pr-4"> Acciones </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.props.rows.map((row, index) => (
                                        <TableRow key={index} >
                                            <TableCell>
                                                <NavLink to={"/" + OrganizationService.modelName + "/view/" + row.organization_id?._id}>
                                                    {row.organization_id?.organization_name}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell>
                                                <NavLink to={"/" + ClientService.modelName + "/view/" + row.client_id?._id}>
                                                    {row.client_id?.name}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell>
                                                {row?.bucketFiles?.map((bucketFile, index) =>
                                                    <FileRow file={bucketFile} index={index} key={bucketFile?.filePath || index} />
                                                )}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="min-width-1">
                                                    <NavLink to={"/" + ClientOrganizationService.modelName + "/view/" + row._id}>
                                                        <ListAltOutlinedIcon />
                                                    </NavLink>
                                                    {
                                                        (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                                        <NavLink to={"/" + ClientOrganizationService.modelName + "/edit/" + row._id}>
                                                            <EditOutlinedIcon />
                                                        </NavLink>
                                                    }
                                                    {
                                                        AuthService.userIsAdmin() &&
                                                        <NavLink onClick={this.showModalDelete(row._id)} to="#">
                                                            <DeleteForever />
                                                        </NavLink>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <ModalDelete
                    show={this.state.showModalDelete}
                    link={"/" + ClientOrganizationService.modelName + "/delete"}
                    id={this.state.deleteClientOrganizationId}
                    message="¿Estas seguro que desea eliminar este organizationo?"
                    setState={state => this.setState(state)}
                    onSubmit={this.props.search}
                />
            </div>
        );
    }
}