import ApplicationForm from './ApplicationForm';
import { withRouter } from "react-router";
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseApplication from './BaseApplication';
import AuthService from '../services/AuthService';

class ApplicationEdit extends BaseApplication {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id || this.props.id
        };

    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getApplication(true);
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        if (this.hasError())
            return;
        if (this.props.handleSubmit) {
            this.update(false, this.props.handleSubmit);
            return;
        }
        this.update(true);
    }

    removeLastIteration = () => {
        if (Array.isArray(this.state.iterations))
            this.state.iterations.pop();
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <ApplicationForm
                    client_id={this.state?.client_id}
                    fileError={this.state.fileError}
                    handleSubmit={this._handleSubmit}
                    id={this.state?._id}
                    iterations={this.state?.iterations}
                    last_iteration={this.state?.last_iteration}
                    onDeleteFile={(filePath) => this.onDeleteFile(filePath)}
                    onFileChange={this.onFileChange}
                    organization_id={this.state?.organization_id}
                    organizations={this.state?.organizations}
                    priority={this.state?.priority || 2}
                    proceeding_id={this.state?.proceeding_id}
                    proceedings={this.state?.proceedings}
                    product_id={this.state?.product_id}
                    product={this.state?.product}
                    setState={state => this.setState(state)}
                    statuses={this.state?.statuses}
                    statusesList={this.state?.statusesList}
                    users={this.state?.users}
                    validateFiles={this.validateFiles}
                    handleCancel={this.props.handleCancel}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(ApplicationEdit))