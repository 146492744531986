import * as firebase from "firebase/app";
import * as firebaseAuth from "firebase/auth";
let config = {
    apiKey: "AIzaSyCDQtaAvUHKAFrz_52Ay7pqGizJ2kxeebo",
    authDomain: "maria-cabrera-staging.firebaseapp.com",
    projectId: "maria-cabrera-staging",
    storageBucket: "maria-cabrera-staging.appspot.com",
    messagingSenderId: "280938122498",
    appId: "1:280938122498:web:ff6f6c5640dd3cc733861b",
    measurementId: "G-CSZLX1D0V8",
    user: "jonathanmatiasborda@gmail.com",
    password: "y6t5r4e3"
};
let bucket = "https://firebasestorage.googleapis.com/v0/b/maria-cabrera-staging.appspot.com/o/";

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    config = {
        apiKey: "AIzaSyDlFpBBSyeBBrQ4W_6iGiMGK5MlL1RLDEQ",
        authDomain: "maria-cabrera-prod.firebaseapp.com",
        projectId: "maria-cabrera-prod",
        storageBucket: "maria-cabrera-prod.appspot.com",
        messagingSenderId: "814182176435",
        appId: "1:814182176435:web:820102842722c2fcd682c6",
        measurementId: "G-2F6WNH46NK",
        user: "jonathanmatiasborda@gmail.com",
        password: "y6t5r4e3"
    };
    bucket = "https://firebasestorage.googleapis.com/v0/b/maria-cabrera-prod.appspot.com/o/";
}
const app = firebase.initializeApp(config);
const auth = firebaseAuth.getAuth(app);
firebaseAuth.signInWithEmailAndPassword(auth, config.user, config.password)
    .then(() => { })
    .catch(error => console.error(error));


const BaseFirebase = {
    bucket: bucket,
    app
};
export default BaseFirebase;
