import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import ReportsList from "./ReportList";

function ReportRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/`}>
                        <ReportsList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default ReportRoutes