import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePaginationEs from "../components/TablePaginationEs";
import TableRow from '@material-ui/core/TableRow';
import BaseReport from './BaseReport';
import { Box, TableSortLabel } from '@material-ui/core';
import { visuallyHidden } from '@mui/utils';

export default class ReportTable extends BaseReport {

    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
            }),
            showModalDelete: false,
            deleteProductId: null,
        };
    }

    showModalDelete = id => event => {
        event.preventDefault();
        this.setState({ showModalDelete: true });
        this.setState({ deleteProductId: id });
    }

    setSort = (sortName) => (e) => {
        e.preventDefault();
        var sort = sortName + '_asc';
        if (this.state.sort === sortName + '_asc') {
            sort = sortName + '_desc';
        }
        this.setState({ sort });
        this.props.search(0, null, sort);
    };

    render() {
        return (
            <div>
                {this.props.loading &&
                    <CircularLoading />
                }
                {
                    !this.props.loading && this.props.rows.length !== 0 &&
                    <TablePaginationEs
                        count={this.props.totalCount}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        onPageChange={this.props.onPageChange}
                        onRowsPerPageChange={this.props.onRowsPerPageChange}
                    />
                }
                {!this.props.loading &&
                    <TableContainer className="mb-3">
                        <Table
                            className={this.state.classes.table}
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    {this.props.columns && this.props.columns.map(column => (
                                        <TableCell key={column.label}
                                            // onClick={() => this.props.shortByColumn(column)} 
                                            sortDirection={this.props.currentSortOrder === 1 ? 'desc' : 'asc'}>
                                            <TableSortLabel
                                                active={this.props.currentSortField === column.className}
                                                direction={this.props.currentSortOrder === 1 ? 'desc' : 'asc'}
                                                onClick={() => this.props.shortByColumn(column)}
                                            >
                                                {column.label}
                                                {this.props.currentSortField === column.className ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {/* {this.props.currentSortOrder === 1 ? 'desc' : 'asc'} */}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {this.props.rows?.length === 0 &&
                                    <TableRow >
                                        {this.props.columns && this.props.columns.map(column => (
                                            <TableCell key={column.label}>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                }
                                {
                                    this.props.rows.map((row, index) => (
                                        <TableRow key={row?.application?._id || index} >
                                            {this.props.columns && this.props.columns.map(column => (
                                                <TableCell key={column.label} className={"cell_" + column.className}>
                                                    {this.showCell(row, column)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {
                    !this.props.loading && this.props.rows.length !== 0 &&
                    <TablePaginationEs
                        count={this.props.totalCount}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        onPageChange={this.props.onPageChange}
                        onRowsPerPageChange={this.props.onRowsPerPageChange}
                    />
                }
            </div >
        );
    }

}