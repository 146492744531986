import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ClientService from "../services/ClientService";
import { Alert } from "@material-ui/lab";
import BaseComponent from "../components/BaseComponent";
import { withSnackbar } from "notistack";
import TablePaginationEs from "../components/TablePaginationEs";
import ClientTable from "./ClientTable";
import ContainerSearch from "../components/ContainerSearch";
import AuthService from "../services/AuthService";

class ClientsList extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      classes: makeStyles({
        table: {
          minWidth: 650,
        },
      }),
      totalCount: 100,
      rowsPerPage: 10,
      page: 0,
      query: {},
      rows: [],
      deleteClientId: null,
      allClients: [],
      currentPage: [],
      currentList: [],
      currentListWithoutPagination: [],
      assignToFilter: null,
      industryFilter: null,
      sectorFilter: null,
      coordinatorFilter: null,
      activeFilter: null,
      stateFilter: null,
      orderBy: "name",
      order: "asc",
    };
  }
  componentDidMount() {
    this.search();
  }

  search = (page = 0, rowsPerPage = null) => {
    this.setState({ page: page });
    this.setState({ loading: true });
    if (!rowsPerPage) rowsPerPage = this.state.rowsPerPage;

    ClientService.getInstance()
      .getAll(page, rowsPerPage, this.state.query)
      .then((response) => {
        this.setState({
          loading: false,
        });
        if (response && response.success) {
          this.setState({ allClients: response.data });
          this.setState({ totalCount: response.count });
          this.setList(this.state.allClients, this.state.rowsPerPage);
          this.setState({
            currentListWithoutPagination: this.state.allClients,
          });
          this.setCurrentPage(this.state.page);
          return;
        }
        this.showToast("Error al listar clientes");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          assignToFilter: null,
          industryFilter: null,
          sectorFilter: null,
          coordinatorFilter: null,
          activeFilter: null,
          stateFilter: null,
        });
      });
  };

  setList = (list, rowsPerPage) => {
    this.setState({ totalCount: list?.length });
    const currentList = [];
    for (let i = 0; i < list?.length; i += rowsPerPage) {
      const subArray = list?.slice(i, i + rowsPerPage);
      currentList.push(subArray);
    }
    this.setState({ currentList: currentList }, () => {
      this.setState({ currentPage: currentList[0] });
    });
  };

  setCurrentPage = (page) => {
    this.setState({ currentPage: this.state.currentList[page] });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ loading: true });
    this.setState({ page: newPage });
    this.setCurrentPage(newPage);
    this.setState({ loading: false });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ loading: true });
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setList(
      this.state.currentListWithoutPagination,
      parseInt(event.target.value, 10)
    );
    this.setState({ page: 0 });
    this.setState({ loading: false });
  };

  handleTextFieldChange = (event) => {
    const query = {
      ...this.state.query,
      [event.target.id]: event.target.value,
    };
    this.setState({ query });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.search();
  };

  handleActiveChange = (row) => (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    row.active = !row.active;
    const client = ClientService.buildClient(row);
    ClientService.getInstance()
      .update(client)
      .then((response) => {
        this.setState({ loading: false });
        if (response && response.success) {
          // this.search();
          this.showToast("Registro actualizado");
          return;
        }
        this.showToast("No se pudo guardar el registro");
      });
  };

  onChangeCoordinator = (event) => {
    this.setState({ coordinatorFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
  };

  onChangeAssignTo = (event) => {
    this.setState({ assignToFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
  };
  onChangeIndustry = (event) => {
    this.setState({ industryFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
    if (event.target.innerText !== "ALIMENTOS") {
      this.setState({ sectorFilter: null }, () => {
        this.applyFilter();
      });
    }
  };
  onChangeSector = (event) => {
    this.setState({ sectorFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
  };
  onChangeStateActive = (event) => {
    this.setState({ activeFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
  };

  onChangeState = (event) => {
    this.setState({ stateFilter: event.target.innerText }, () => {
      this.applyFilter();
    });
  };

  clearFilters = () => {
    this.setState({
      assignToFilter: null,
      coordinatorFilter: null,
      industryFilter: null,
      sectorFilter: null,
      activeFilter: null,
      stateFilter: null,
      orderBy: "name",
      order: "asc",
    });
  };

  handleRequestSort = (property) => {
    const orderBy = property;
    const order =
      this.state.orderBy === property && this.state.order === "asc"
        ? "desc"
        : "asc";
    const rowsToSort = this.state.currentListWithoutPagination;
    const sortedRows = rowsToSort.slice().sort((a, b) => {
      if (order === "asc") {
        if (
          orderBy === "industry" ||
          orderBy === "sector" ||
          orderBy === "name" ||
          orderBy === "fantasy_name"
        ) {
          return (a[orderBy] || "").localeCompare(b[orderBy] || "", undefined, {
            sensitivity: "base",
          });
        } else {
          return a[orderBy] > b[orderBy] ? 1 : -1;
        }
      } else {
        if (
          orderBy === "industry" ||
          orderBy === "sector" ||
          orderBy === "name" ||
          orderBy === "fantasy_name"
        ) {
          return (b[orderBy] || "").localeCompare(a[orderBy] || "", undefined, {
            sensitivity: "base",
          });
        } else {
          return a[orderBy] < b[orderBy] ? 1 : -1;
        }
      }
    });

    this.setState({ orderBy, order });
    this.setState({ currentListWithoutPagination: sortedRows });
    this.setList(sortedRows, this.state.rowsPerPage);
  };

  applyFilter = () => {
    let filteredRows = this.state.allClients;

    if (this.state.activeFilter === "Activos") {
      filteredRows = filteredRows.filter((row) => row.active === true);
    }

    if (this.state.activeFilter === "Inactivos") {
      filteredRows = filteredRows.filter((row) => row.active === false);
    }

    if (this.state.assignToFilter) {
      filteredRows = filteredRows.filter(
        (row) => row.assign_to === this.state.assignToFilter
      );
    }
    if (this.state.industryFilter) {
      filteredRows = filteredRows.filter(
        (row) => row.industry === this.state.industryFilter
      );
    }
    if (this.state.sectorFilter) {
      filteredRows = filteredRows.filter(
        (row) => row.sector === this.state.sectorFilter
      );
    }
    if (this.state.coordinatorFilter) {
      filteredRows = filteredRows.filter(
        (row) => row.coordinator === this.state.coordinatorFilter
      );
    }

    if (this.state.stateFilter && this.state.stateFilter !== "Todos") {
      filteredRows = filteredRows.filter(
        (row) => row.stateClient === this.state.stateFilter
      );
    }

    this.setState({ page: 0 });
    this.setState({ currentListWithoutPagination: filteredRows }, () => {
      this.handleRequestSort(this.state.orderBy);
    });
  };

  render() {
    return (
      <div>
        <ContainerSearch
          handleSubmit={this.handleSubmit}
          handleKeypress={(e) => {
            this.clearFilters();
            this.handleEnterPress(e, this.search);
          }}
          handleTextFieldChange={this.handleTextFieldChange}
          title="Clientes"
          modelName={ClientService.modelName}
          allowNew={AuthService.userIsAdmin() || AuthService.userIsEditor()}
          q={this.props.q}
        />
        <Paper elevation={3}>
          {this.state.currentPage?.length !== 0 && (
            <TablePaginationEs
              count={this.state.totalCount}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          )}

          <ClientTable
            loading={this.state.loading}
            classes={this.state.classes}
            rows={this.state.currentPage}
            rowsPerPage={this.state.rowsPerPage}
            allClients={this.state.allClients}
            search={this.search}
            handleActiveChange={this.handleActiveChange}
            onPageChange={this.handleChangePage}
            currentListWithoutPagination={
              this.state.currentListWithoutPagination
            }
            onChangeCoordinator={this.onChangeCoordinator}
            onChangeAssignTo={this.onChangeAssignTo}
            onChangeIndustry={this.onChangeIndustry}
            onChangeSector={this.onChangeSector}
            onChangeStateActive={this.onChangeStateActive}
            onChangeState={this.onChangeState}
            handleRequestSort={this.handleRequestSort}
            order={this.state.order}
            orderBy={this.state.orderBy}
          />
          {!this.state.loading && this.state.currentPage?.length === 0 && (
            <Alert severity="error">No se encontraron resultados</Alert>
          )}
          {this.state.currentPage?.length !== 0 && (
            <TablePaginationEs
              count={this.state.totalCount}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    );
  }
}

export default withSnackbar(ClientsList);
