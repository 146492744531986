import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import BaseReport from "./BaseReport";
import Paper from "@material-ui/core/Paper";
import ReportExport from "./ReportExport";
import ProductService from "../services/ProductService";
import ReportTable from "./ReportTable";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import "date-fns";
// import DateFnsUtils from '@date-io/date-fns';
// import esLocale from "date-fns/locale/es";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactToPrint from "react-to-print";
import ReportPdf from "./ReportPdf";
import camelCase from "camelcase";
import { DateRange } from "../components/DateRange";
import AuthService from "../services/AuthService";
import { sort } from "../utilHook";
import { ModalStatus } from "../components/ModalStatus";

class ReportsList extends BaseReport {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  columns = [
    {
      label: "Fecha de inicio",
      className: "start_date",
      value: this.showStartDate,
      type: "date",
      show: true,
    },
    {
      label: "Fecha último movimiento",
      className: "last_date",
      value: this.showLastDate,
      type: "date",
      show: true,
    },
    {
      label: "Fecha vencimiento",
      className: "expiration_date",
      value: this.showExpirationDate,
      type: "date",
      show: true,
    },
    {
      label: "Cliente",
      className: "client",
      value: this.showClient,
      link: (row) => "/client/view/" + row.client?._id,
      type: "text",
      show: true,
    },
    {
      label: "Organismo",
      className: "organization",
      value: this.showProceeding,
      type: "text",
      show: true,
    },
    {
      label: "Trámite",
      className: "proceeding",
      value: (element) => {
        return element?.application?.proceeding?.name;
      },
      type: "text",
      show: true,
    },
    {
      label: "Nombre Corto",
      className: "short_name",
      value: this.showShortName,
      link: (row) => "/product/view/" + row._id,
      type: "text",
      show: true,
    },
    {
      label: "Marca",
      className: "brand",
      value: "brand",
      type: "text",
      show: true,
    },
    {
      label: "Fantasia",
      className: "fantasy_name",
      value: "fantasy_name",
      type: "text",
      show: true,
    },
    {
      label: "Origen",
      className: "origin",
      value: "origin",
      type: "text",
      show: true,
    },
    {
      label: "Descripción",
      className: "description",
      value: "description",
      type: "text",
      show: true,
    },
    {
      label: "Estado",
      className: "status",
      value: (col, isPdf) => this.showStatus(col, isPdf),
      type: "text",
      show: true,
    },
    {
      label: "Subestado",
      className: "substatus",
      value: (element) => {
        return element?.application?.last_iteration?.substatus;
      },
      type: "text",
      show: true,
    },
    {
      label: "Expediente",
      className: "expedient",
      value: this.showExpedient,
      type: "text",
      show: true,
    },
    {
      label: "Certificado",
      className: "field_1",
      value: (element) => {
        return element?.application?.last_iteration?.field_1;
      },
      type: "text",
      show: true,
    },
    {
      label: "Comentarios",
      className: "comments",
      value: (element) => {
        return element?.application?.last_iteration?.comments;
      },
      type: "text",
      show: true,
    },
    {
      label: "Usuario",
      className: "asign_to",
      value: (col, isPdf) => this.showAsignedUser(col, isPdf),
      type: "text",
      show: true,
    },
    {
      label: "Prioridad",
      className: "priority",
      value: (col, isPdf) => this.showPriority(col, isPdf),
      type: "text",
      show: true,
    },
    // { label: "Denominación Legal", value: "name", show: true },
  ];

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      notFound: true,
      totalCount: 100,
      rowsPerPage: 10,
      page: 0,
      proceeding: "",
      expedient: "",
      certificate: "",
      organization: "",
      rows: [],
      rowsShow: [],
      rowsPdf: [],
      diminutives: [],
      clients: [],
      clientsSelected: [],
      organizations: [],
      organizationsSelected: null,
      statuses: [],
      statusesSelected: null,
      substatuses: [],
      substatusesSelected: null,
      proceedings: [],
      // asign_to: "",
      users: [],
      asignToSelected: null,
      name: "",

      created_from: null,
      createdFromSelected: "",
      created_to: null,
      createdToSelected: "",
      // date: null,
      // dateSelected: "",
      date_from: null,
      dateFromSelected: "",
      date_to: null,
      dateToSelected: "",

      expiration_date_from: null,
      expirationDateFromSelected: "",
      expiration_date_to: null,
      expirationDateToSelected: "",

      columnsSelected: this.columns.slice(),
      currentSortField: "",
      currentSortOrder: 1,
    };
  }

  openModalStatus(applicationId) {
    this.setState({ currentApplicationId: applicationId });
    this.setState({ showModalStatus: true });
  }

  showStatus(col, isPdf = false) {
    if (isPdf || !AuthService.userIsAdmin()) {
      return col?.application?.last_iteration?.status;
    }
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          this.openModalStatus(col?.application?._id);
        }}
        href="#top"
      >
        {col?.application?.last_iteration?.status}
      </a>
    );
  }

  componentDidMount() {
    if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
      this.showToast("No tiene permisos para ingresar");
      this._back();
      this.props.history.push("/");
    }
    this.getDiminutives();
    this.getClientsList();
    this.getOrganizationsList();
    this.getStatusesList();
    this.getSubstatusesList();
    this.getProceedingList();
    this.getUsersList();
  }

  getIds = (arrayObject) => {
    if (!arrayObject || arrayObject?.length === 0) return null;

    const allClientsSelected = arrayObject?.find(
      (client) => client.name === "Todos los clientes"
    );
    if (allClientsSelected) {
      arrayObject = this.state.clients;
    }
    return arrayObject.map((element) => element._id);
  };
  getNames = (arrayObject) => {
    if (!arrayObject) return null;
    return arrayObject.map((element) => element.name);
  };

  getStatusName = (arrayObject) => {
    if (!arrayObject) return null;
    return arrayObject.map((element) => element.status);
  };

  search = () => {
    const query = {
      // organization: this.state.organization,
      organization: this.getIds(this.state.organizationsSelected),
      // short_name: this.state.short_name,
      display_name: this.state.short_name,
      // name: this.state.name,
      expedient: this.state.expedient,
      certificate: this.state.certificate,
      proceeding: this.getIds(this.state.proceedingsSelected),
      client: this.getIds(this.state.clientsSelected),
      status: this.getStatusName(this.state.statusesSelected),
      substatus: this.state.substatusesSelected,
      // asign_to: this.state.asign_to,
      asign_to: this.getNames(this.state.asignTosSelected),

      created_from: this.state.createdFromSelected,
      created_to: this.state.createdToSelected,

      // date: this.state.dateSelected,
      date_from: this.state.dateFromSelected,
      date_to: this.state.dateToSelected,

      expiration_date_from: this.state.expirationDateFromSelected,
      expiration_date_to: this.state.expirationDateToSelected,
    };

    if (this.isObjectEmpty(query)) {
      this.showToastError("Por favor, seleccione un filtro de búsqueda");
      return;
    }

    this.setState({ loading: true });
    this.setState({ page: 0 });
    this.setState({ notFound: true });
    ProductService.getInstance()
      .getReport({ ...query })
      .then((response) => {
        this.setState({ loading: false });

        if (response && response.success) {
          this.setState({ notFound: !response.data.length });
          this.setState({ rows: response.data });
          this.setState({ totalCount: response.data.length });
          this.reduceAll();
          this.setState({
            rowsShow: response.data.slice(0, this.state.rowsPerPage),
          });
          setTimeout(() => {
            this.setState({ rowsPdf: response.data });
          });
          return;
        }
        this.showToast("Error al listar productos");
      })
      .catch(() => {
        this.setState({ notFound: true });
        this.setState({ loading: false });
      });
  };

  handleChangePage = (_, newPage) => {
    this.setState({ page: newPage });
    const inicio = this.state.rowsPerPage * newPage;
    this.setState({
      rowsShow: this.state.rows.slice(inicio, this.state.rowsPerPage + inicio),
    });
  };

  handleChangeRowsPerPage = (event) => {
    let newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage });
    this.setState({ page: 0 });
    this.setState({ rowsShow: this.state.rows.slice(0, newRowsPerPage) });
  };

  handleTextFieldChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.search(0);
  };

  reduceAll = () => {
    if (
      this.state.diminutives &&
      this.state.diminutives.length &&
      this.state.rows &&
      this.state.rows.length
    ) {
      let rows = this.state.rows;
      rows.forEach((row, index) => {
        rows[index].short_name = this.reduce(row.name);
      });
      this.setState({ rows });
    }
  };

  onChangeClient = (_, value) => {
    const lastValue = value[value.length - 1];

    if (lastValue?.name === "Todos los clientes") {
      this.setState({ clientsSelected: [lastValue] });
    } else {
      const newValue = value.filter(
        (client) => client.name !== "Todos los clientes"
      );
      this.setState({ clientsSelected: newValue });
    }

    // Forzar la actualización del componente
    // this.forceUpdate();
  };
  onChangeOrganization = (_, value) => {
    this.setState({ organizationsSelected: value });
  };
  onChangeStatus = (_, value) => {
    this.setState({ statusesSelected: value });
  };
  onChangeSubstatus = (_, value) => {
    this.setState({ substatusesSelected: value });
  };

  onChangeProceeding = (_, value) => {
    this.setState({ proceedingsSelected: value });
  };
  onChangeAsignTo = (_, value) => {
    this.setState({ asignTosSelected: value });
  };

  // handleDateChange = (date) => {
  //     this.setState({ dateSelected: '' });
  //     this.setState({ date });
  //     if (date)
  //         this.setState({ dateSelected: date });
  // }

  handleCreatedFromChange = (created_from) => {
    this.handleDateChange(created_from, "created_from");
  };
  handleCreatedToChange = (created_to) => {
    this.handleDateChange(created_to, "created_to");
  };
  handleDateFromChange = (date_from) => {
    this.handleDateChange(date_from, "date_from");
  };
  handleDateToChange = (date_to) => {
    this.handleDateChange(date_to, "date_to");
  };
  handleExpirationDateFromChange = (expiration_date_from) => {
    this.handleDateChange(expiration_date_from, "expiration_date_from");
  };
  handleExpirationDateToChange = (expiration_date_to) => {
    this.handleDateChange(expiration_date_to, "expiration_date_to");
  };

  handleDateChange = (date, dateName) => {
    this.setState({ [camelCase(dateName) + "Selected"]: "" });
    this.setState({ [dateName]: date });
    if (date) this.setState({ [camelCase(dateName) + "Selected"]: date });
  };

  shortByColumn = async (column) => {
    this.setState({ loading: true });
    this.forceUpdate();

    const currentSortOrder =
      this.state.currentSortField === column.className &&
      this.state.currentSortOrder === 1
        ? -1
        : 1;
    this.setState({
      currentSortField: column.className,
      currentSortOrder,
    });
    this.state.rows.sort((rowA, rowB) => {
      const valueA = this.showValueCell(rowA, column);
      const valueB = this.showValueCell(rowB, column);
      return sort(currentSortOrder, column, valueA, valueB);
    });
    this.handleChangePage(null, 0);
    this.setState({ loading: false });
  };

  render() {
    return (
      <div>
        <Paper
          elevation={3}
          className="mb-4 mt-3 pt-3 pr-4 pl-4 pb-3 container-search"
        >
          <Typography variant="h5" className="col-sm-1 title">
            Reporte
          </Typography>
          <div className="clear"></div>
          <FormControl
            onSubmit={this.handleSubmit}
            style={{ display: "block" }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="client"
                  className="mr-2"
                  options={this.state.clients}
                  getOptionLabel={(option) => option.name || ""}
                  value={this.state.clientsSelected}
                  defaultValue={[]}
                  onChange={this.onChangeClient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Cliente"
                      placeholder="Cliente"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="organization"
                  className="mr-2"
                  options={this.state.organizations}
                  getOptionLabel={(option) => option.organization_name || ""}
                  defaultValue={[]}
                  onChange={this.onChangeOrganization}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Organismo"
                      placeholder="Organismo"
                    />
                  )}
                />
                {/* <TextField id="organization" className="mr-2" label="Organismo"
                                    value={this.state.organization} onChange={this.handleTextFieldChange}
                                    onKeyPress={(e) => this.handleEnterPress(e, this.search)} /> */}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="short_name"
                  className="mr-2"
                  label="Nombre Corto"
                  value={this.state.short_name}
                  onChange={this.handleTextFieldChange}
                  onKeyPress={(e) => this.handleEnterPress(e, this.search)}
                />
              </Grid>
              {/* <Grid item xs={6}>
                                <TextField id="name" className="mr-2" label="Denominación Legal"
                                    value={this.state.name} onChange={this.handleTextFieldChange}
                                    onKeyPress={(e) => this.handleEnterPress(e, this.search)} />
                            </Grid> */}
              <Grid item xs={6}>
                <TextField
                  id="expedient"
                  className="mr-2"
                  label="Expediente"
                  value={this.state.expedient}
                  onChange={this.handleTextFieldChange}
                  onKeyPress={(e) => this.handleEnterPress(e, this.search)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="certificate"
                  className="mr-2"
                  label="Certificado"
                  value={this.state.certificate}
                  onChange={this.handleTextFieldChange}
                  onKeyPress={(e) => this.handleEnterPress(e, this.search)}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="proceeding"
                  className="mr-2"
                  options={this.state.proceedings}
                  getOptionLabel={(option) => option.name || ""}
                  defaultValue={[]}
                  onChange={this.onChangeProceeding}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Trámite"
                      placeholder="Trámite"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="status"
                  className="mr-2"
                  options={this.state.statuses}
                  getOptionLabel={(option) => option.status || ""}
                  defaultValue={[]}
                  onChange={this.onChangeStatus}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Estado"
                      placeholder="Estado"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="substatus"
                  className="mr-2"
                  options={this.state.substatuses}
                  getOptionLabel={(option) => option || ""}
                  defaultValue={[]}
                  onChange={this.onChangeSubstatus}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Subestado"
                      placeholder="Subestado"
                    />
                  )}
                  disabled={
                    !(
                      this.state.statusesSelected?.length &&
                      this.state.statusesSelected?.some(
                        (status) => status?.substatuses?.length
                      )
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="asign_to"
                  className="mr-2"
                  options={this.state.users}
                  getOptionLabel={(option) => option.name || ""}
                  defaultValue={[]}
                  onChange={this.onChangeAsignTo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Asignado a"
                      placeholder="Asignado a"
                    />
                  )}
                />

                {/* <TextField id="asign_to" className="mr-2 mt-3" label="Usuario (asignado a)"
                                    value={this.state.asign_to} onChange={this.handleTextFieldChange}
                                    onKeyPress={(e) => this.handleEnterPress(e, this.search)} /> */}
              </Grid>
              <Grid item xs={6}>
                <DateRange
                  dateName="created"
                  label="Fecha de inicio"
                  date_from={this.state.created_from}
                  date_to={this.state.created_to}
                  handleDateFromChange={this.handleCreatedFromChange}
                  handleDateToChange={this.handleCreatedToChange}
                />
              </Grid>
              <Grid item xs={6}>
                <DateRange
                  dateName="date"
                  label="Fecha de último movimiento"
                  date_from={this.state.date_from}
                  date_to={this.state.date_to}
                  handleDateFromChange={this.handleDateFromChange}
                  handleDateToChange={this.handleDateToChange}
                />
              </Grid>
              <Grid item xs={6}>
                <DateRange
                  dateName="expiration_date"
                  label="Fecha vencimiento"
                  date_from={this.state.expiration_date_from}
                  date_to={this.state.expiration_date_to}
                  handleDateFromChange={this.handleExpirationDateFromChange}
                  handleDateToChange={this.handleExpirationDateToChange}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-3" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={this.search}
                  disabled={this.state.loading}
                  {...(this.state.loading ? {} : { color: "primary" })}
                >
                  Buscar
                  {/* {this.state.loading && <CircularProgress size={24} style={{ position: "absolute" }} />} */}
                </Button>
              </Grid>
              <Grid item>
                <ReportExport
                  data={this.state.rowsPdf}
                  columns={this.state.columnsSelected}
                  name="Productos"
                  disabled={this.state.notFound}
                />
              </Grid>
              <Grid item>
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <Button
                        variant="contained"
                        disabled={this.state.notFound}
                        {...(this.state.notFound ? {} : { color: "primary" })}
                      >
                        PDF
                      </Button>
                    );
                  }}
                  content={() => this.componentRef}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
        <div style={{ display: "none" }}>
          <ReportPdf
            ref={(el) => (this.componentRef = el)}
            rows={this.state.rowsPdf}
            columns={this.columns}
          />
        </div>
        <Paper elevation={3} className="mb-3 p-3">
          <Typography variant="h5" className="col-sm-1 title">
            Columnas
          </Typography>
          {this.columns.map((column) => (
            <FormControlLabel
              key={column.label}
              control={
                <Checkbox
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.columns = this.columns.map((currentColumn) => {
                        if (currentColumn.label === event.target.name) {
                          currentColumn.show = true;
                        }
                        return currentColumn;
                      });
                    } else {
                      this.columns = this.columns.map((currentColumn) => {
                        if (currentColumn.label === event.target.name) {
                          currentColumn.show = false;
                        }
                        return currentColumn;
                      });
                    }
                    this.setState({
                      columnsSelected: this.columns.filter(
                        (currentColumn) => currentColumn.show
                      ),
                    });
                  }}
                  name={column.label}
                />
              }
              label={column.label}
              checked={column.show}
            />
          ))}
        </Paper>
        <Paper elevation={3}>
          <Paper elevation={1} className="reportTableResult">
            {this.state.loading && <LinearProgress />}
            <ReportTable
              loading={this.state.loading}
              rows={this.state.rowsShow}
              search={this.search}
              totalCount={this.state.totalCount}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              columns={this.state.columnsSelected}
              currentSortField={this.state.currentSortField}
              currentSortOrder={this.state.currentSortOrder}
              shortByColumn={this.shortByColumn}
            />
          </Paper>
        </Paper>
        {this.state.showModalStatus && (
          <ModalStatus
            show={this.state.showModalStatus}
            onHide={() => {
              this.setState({ showModalStatus: false });
            }}
            applicationId={this.state.currentApplicationId}
            onSave={() => {
              this.search();
              this.setState({ showModalStatus: false });
            }}
          />
        )}
      </div>
    );
  }
}
export default withRouter(withSnackbar(ReportsList));
