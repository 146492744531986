import AuthService from './AuthService';

export default class ApiService {
    static getApiUrl() {
        if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION')
            return process.env.REACT_APP_API_URL;

        if (process.env.REACT_APP_ENVIRONMENT === 'STAGING')
            return process.env.REACT_APP_API_URL;

        return "http://localhost:8080/v1";
    }

    static async requestPost(url: string, data?: any, isRefreshToken = false): Promise<any> {
        let headerInit: HeadersInit = {
            'Content-Type': 'application/json; charset=UTF-8'
            //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }

        let requestInit: RequestInit = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: AuthService.addHeader(headerInit)
        };

        const request = new Request(url, requestInit);
        return await fetch(request).then(async response => {
            if (response.status === 401 && !isRefreshToken) {
                AuthService.setToken();
                return await this.requestPost(
                    this.getApiUrl() + '/auth/refreshToken',
                    {
                        'refreshToken': AuthService.refreshToken()
                    },
                    true
                ).then(response => {
                    if (response.success) {
                        AuthService.setToken(response.data.token);
                        AuthService.setRefreshToken(response.data.refreshToken);
                        return this.requestPost(url, data);
                    } else {
                        AuthService.logout();
                    }
                });
            } else {
                return Promise.resolve(response);
            }
        }).then(response => {
            try {
                return response.json();
            } catch {
                return response;
            }
        });
    }

    static async requestGet(url: string, isRefreshToken = false): Promise<any> {

        const request = new Request(
            url,
            {
                method: 'GET',
                headers: AuthService.addHeader()
            });

        return await fetch(request).then(async response => {
            if (response.status === 401 && !isRefreshToken) {
                AuthService.setToken();
                return await this.requestPost(
                    this.getApiUrl() + '/auth/refreshToken',
                    {
                        'refreshToken': AuthService.refreshToken()
                    },
                    true
                ).then(response => {
                    if (response.success) {
                        AuthService.setToken(response.data.token);
                        AuthService.setRefreshToken(response.data.refreshToken);
                        return this.requestGet(url);
                    } else {
                        AuthService.logout();
                    }
                });
            } else {
                return Promise.resolve(response);
            }
        }).then(response => {
            try {
                return response.json();
            } catch {
                return response;
            }
        });
    }
}