import ApiService from './ApiService';
import CRUDService from './CRUDService';
import { Response, ResponseError } from '../interfaces/Response';

class ProductService extends CRUDService {

    private static instance: ProductService;
    public static modelName: String = 'product';
    private constructor() {
        super();
        this.modelName = ProductService.modelName;
    }

    public static getInstance(): ProductService {
        if (!this.instance)
            this.instance = new ProductService();
        return this.instance;
    }

    public static buildProduct(data: any): any {
        return {
            _id: data._id,
            name: data.name,
            client_id: data.client_id,
            fantasy_name: data.fantasy_name,
            fantasy_names: data.fantasy_names,
            short_name: data.short_name,
            display_name: data.display_name,
            description: data.description,
            brand: data.brand,
            origin: data.origin,
            client: data.client
        }
    }

    public async getReport(data: any): Promise<Response> {
        try {
            return await ApiService.requestPost(
                ApiService.getApiUrl() + '/' + this.modelName + '/getReport/', data);
        } catch (error) {
            console.error(error);
            return new ResponseError(error as string);
        }
    }
}

export default ProductService