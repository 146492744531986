import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import OrganizationsList from "./OrganizationList";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationNew from "./OrganizationNew";
import OrganizationView from "./OrganizationView";

function OrganizationRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <OrganizationEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <OrganizationNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <OrganizationView />
                    </Route>
                    <Route path={`${path}/`}>
                        <OrganizationsList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default OrganizationRoutes