import BaseComponent from "../components/BaseComponent";
import { withRouter } from "react-router";
import { withSnackbar } from "notistack";
import FileService from "../services/FileService";
import { LinearProgress } from "@material-ui/core";

class FileDownload extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    componentDidMount() {
        FileService.getInstance().getById(this.state._id).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                var data = response.data.data.split(';base64,')[1];
                const url = window.URL.createObjectURL(this.b64toBlob(data, response.data.type));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `${response.data.name}`
                );
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    window.close();
                }, 100);
                return;
            }

            this.showToast('No se pudo guardar el registro');
        });;
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
            </div>
        );
    }
}
export default withSnackbar(withRouter(FileDownload));