import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
// import React from "react";
import BaseReport from "./BaseReport";
import "./stylePdf.css";

const MAX_ROWS_PDF = 5000;
class ReportPdf extends BaseReport {

    constructor(props) {
        super(props);
        this.state = {
            stylePath: '../landscape.css',
            small: false
        }
    }

    componentDidUpdate() {
        const columnsToShow = this.getColumnsToShow();
        this.setState({ small: false });
        if (columnsToShow?.length > 5) {
            this.setState({ stylePath: '../landscape.css' });
            if (columnsToShow?.length > 10) {
                this.setState({ small: true });
            }
        } else {
            this.setState({ stylePath: '' });
        }
    }

    getColumnsToShow = () => {
        return this.props.columns.filter((column) => column.show === true);
    }
    render() {
        const columnsToShow = this.getColumnsToShow();
        return (
            <div className="mt-5 ml-3 mr-3 mb-2 pdf">
                <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
                <img src="/logo-completo.png" className="img-logo-navbar" alt="logo" />
                <div className="linearParent">
                    <div className="linearSon"></div>
                </div>
                <TableContainer className={"mb-3 " + (this.state.small ? "small" : "")} style={{ maxWidth: "100%" }}>
                    <Table size="small" aria-label="a dense table" >
                        <TableHead>
                            <TableRow>
                                {
                                    columnsToShow && columnsToShow.map((column) => {
                                        return <TableCell key={column.label}>{column.label}</TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {
                                this.props.rows?.length ?
                                    this.props.rows.slice(0, MAX_ROWS_PDF).map((row, index) => {
                                        return <TableRow key={row?.application?._id || index} >
                                            {columnsToShow && columnsToShow.map(column => {
                                                return (<TableCell key={column.label}>
                                                    {this.showCell(row, column, true)}
                                                </TableCell>)
                                            })
                                            }
                                        </TableRow>
                                    }) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };
}

export default ReportPdf;