import OrganizationForm from './OrganizationForm';
import { withRouter } from "react-router";
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseOrganization from './BaseOrganization';
import AuthService from '../services/AuthService';

class OrganizationEdit extends BaseOrganization {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin() || AuthService.userIsEditor())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getOrganization();
    }

    _handleSubmit(event) {

        event.preventDefault();
        if (this.state.error)
            return;
        this.update(true);
    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <OrganizationForm
                    _id={this.state?._id}
                    address={this.state?.address}
                    bucketDocumentations={this.state?.bucketDocumentations}
                    dependence={this.state?.dependence}
                    documentations={this.state?.documentations}
                    email={this.state?.email}
                    error={this.state.error}
                    expires={this.state?.expires}
                    fields={this.state?.fields}
                    handleSubmit={this._handleSubmit}
                    loading={this.state?.loading}
                    onDeleteFile={this.onDeleteFile}
                    onFileChange={this.onFileChange}
                    opening={this.state?.opening}
                    organization_name={this.state?.organization_name}
                    phone={this.state?.phone}
                    renewable={this.state?.renewable}
                    setState={state => this.setState(state)}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(OrganizationEdit))