import { Route, Switch, useRouteMatch } from "react-router";
import FileDownload from "./FileDownload";

function FileRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/download/:id`}>
                    <FileDownload />
                </Route>
            </Switch>
        </div>
    )
}

export default FileRoutes