import ProceedingForm from './ProceedingForm';
import { withRouter } from "react-router";
import ProceedingService from '../services/ProceedingService';
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseProceeding from './BaseProceeding';
import AuthService from '../services/AuthService';

class ProceedingEdit extends BaseProceeding {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getProceeding();
    }

    _handleSubmit = (event) => {

        event.preventDefault();
        if (this.state.error)
            return;
        this.setState({ loading: true });
        const proceeding = ProceedingService.buildProceeding(this.state);

        ProceedingService.getInstance().update(proceeding).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.props.history.push('/' + ProceedingService.modelName + '/view/' + proceeding._id);
                return;
            }

            this.showToast('No se pudo guardar el registro');
        });

    }

    render() {
        return (
            <div>
                {this.state.loading && <LinearProgress />}
                <ProceedingForm handleSubmit={this._handleSubmit}
                    _id={this.state?._id}
                    organizations={this.state?.organizations}
                    name={this.state?.name}
                    loading={this.state?.loading}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(ProceedingEdit))