import { withRouter } from "react-router";
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from "notistack";
import BaseProceeding from "./BaseProceeding";
import AuthService from "../services/AuthService";
import ProceedingService from "../services/ProceedingService";

class ProceedingView extends BaseProceeding {

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id,
        }
    }

    componentDidMount() {
        this.getProceeding();
    }

    render() {
        return (
            <div>
                <Paper elevation={3} className="p-3">
                    {this.state.loading && <LinearProgress />}
                    <Typography variant="h5" component="h2">
                        Trámite
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Nombre:
                    </Typography>
                    <Typography variant="body1" className="ml-2"> {this.state.name || "-"}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        Organismos:
                    </Typography>
                    <Typography variant="body1" className="ml-2" component="div">
                        <div className="row">
                            {
                                this.state.organizations && this.state.organizations.map(organization => (
                                    <div className="col-sm-6" key={organization.organization_name}>{organization.organization_name}</div>
                                ))
                            }
                        </div>
                    </Typography>

                    <div className={"pt-3"}>
                        Acciones
                    </div>
                    <div>
                        {
                            (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                            <Button variant="contained" color="primary" className="mt-3 mr-3 mb-3" onClick={this._linkTo("/" + ProceedingService.modelName + "/edit/" + this.state._id)}>
                                Editar
                            </Button>
                        }
                        <Button variant="contained" color="default" className="mt-3 mb-3" onClick={this._back}>
                            Volver
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }
}
export default withSnackbar(withRouter(ProceedingView));