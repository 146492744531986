import '../Form.css';
import { Button, withStyles, Paper, TextField, IconButton, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { withRouter } from "react-router";
import ModalAddress from '../components/ModalAddress';
import BaseOrganization from './BaseOrganization';
import AuthService from '../services/AuthService';
import { BackupOutlined } from '@material-ui/icons';
import FileRow from '../file/FileRow';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class OrganizationForm extends BaseOrganization {
    constructor(props) {
        super(props);
        this.state = {
            show_address: false
        };
    }

    _handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
        // this.setState(this.structuring(event.target.id, event.target.value));
    }

    handleCheckboxChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.checked));
        // this.setState(this.structuring(event.target.id, event.target.checked));
    }

    handleChangePhone = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
        // this.validateForm(this.props.taxes_id, event.target.value);
    }

    showModalAddress = () => {
        this.setState({ show_address: true });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={3} >
                <ModalAddress
                    show={this.state.show_address}
                    address={this.props.address}
                    setStateParent={state => this.props.setState(state)}
                    setState={state => this.setState(state)}
                    name="address"
                    title="Dirección"
                />

                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Organismo: {this.props.organization_name}
                    </Typography>
                    <div className="row">
                        <div className="col-sm-6">
                            <TextField id="organization_name" label="Organismo" placeholder="Organismo" value={this.props.organization_name} onChange={this._handleTextFieldChange} />
                        </div>
                        <div className="col-sm-6">
                            <TextField id="dependence" label="Dependencia" placeholder="Dependencia" value={this.props.dependence} onChange={this._handleTextFieldChange} />
                        </div>
                    </div>
                    {/* <h2 className="h5 font-weight-bold mt-1">
                        Licencia
                    </h2>
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                id="renewable"
                                checked={this.props.renewable}
                                onChange={this.handleCheckboxChange} />}
                            label="Renovable"
                        />

                        <FormControlLabel
                            control={<Checkbox
                                id="expires"
                                checked={this.props.expires}
                                onChange={this.handleCheckboxChange} />}
                            label="Vence"
                        />
                    </div> */}
                    <div>
                        <div className={"pt-3"}>
                            Contactos
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <TextField id="phone.switchboard" label="Teléfono" placeholder="Teléfono"
                                    value={this.props.phone?.switchboard} onChange={this.handleChangePhone}
                                    multiline={true}
                                    {...(this.props.error_phone && { error: true, helperText: this.props.error_phone })} />
                            </div>
                            <div className="col-sm-12">
                                <TextField id="email" label="Correo electrónico" placeholder="Correo electrónico"
                                    value={this.props.email} onChange={this._handleTextFieldChange}
                                    multiline={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <TextField id="address" label="Dirección" placeholder="Dirección"
                                    disabled={true} value={this.props.address.address} className="input-address" />
                                <IconButton onClick={this.showModalAddress} className="pencil-address">
                                    <EditOutlinedIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className={"pt-3"}>
                        Documentación requerida
                    </div>
                    <div className="row">
                        <div className="col-sm-6 ml-1">
                            {(AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                                <>
                                    <a href="#a" onClick={() => this.clickById("inputUpload")}>
                                        <BackupOutlined />
                                    </a>
                                    <input type="file" id="inputUpload" onChange={this.props.onFileChange} hidden={true} />
                                </>
                            }

                            {this.props?.bucketDocumentations?.map((file, index) => (
                                <FileRow file={file} onDelete={this.props.onDeleteFile} index={index} key={file?.filePath || index} delete={true} />
                            ))}
                        </div>
                    </div>
                    {/* <h2 className="h5 font-weight-bold mt-1">
                        Campos
                    </h2>
                    <div>
                        <TextField id="week" label="Días de Semana" placeholder="Días de Semana" disabled={true} value={this.props.opening?.week} />
                        <TextField id="weekend" label="Fines de Semana" placeholder="Fines de Semana" disabled={true} value={this.props.opening?.weekend} />
                    </div> */}
                    {
                        (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                        <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3" onClick={this.props.handleSubmit}>
                            Guardar
                        </Button>
                    }
                    <Button variant="contained" color="default" className="mt-4 mb-3" onClick={this._back}>
                        Volver
                    </Button>
                </form >
            </Paper>
        );
    }
}

export default withRouter(withStyles(useStyles)(OrganizationForm))