import BaseComponent from "../components/BaseComponent";
import ClientOrganizationService from '../services/ClientOrganizationService';
import ClientService from '../services/ClientService';
import OrganizationService from "../services/OrganizationService";
import FileService from '../services/FileService';

class BaseClientOrganization extends BaseComponent {

    baseState = {
        _id: '',
        organization_id: '',
        organizations: [],
        client_id: '',
        clients: [],
        files: [],
        bucketFiles: [],
        loading: true
    }

    getClientOrganization() {
        // uso params.id porque el set state es async
        ClientOrganizationService.getInstance().getById(this.state._id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState(response.data);
                return;
            }
            this.showToast('Error al cargar Organismo');
        })
    }

    getClientById(id) {
        ClientService.getInstance().getById(id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ clients: [response.data] });
                this.setState({ client_id: response.data });
                return;
            }
            this.showToast('Error al listar clientes');
        });
    }

    getOrganizationsList() {
        OrganizationService.getInstance().list().then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ organizations: response.data });
                return;
            }
            this.showToast('Error al listar organismos');
        });
    }

    getOrganizationById(id) {
        OrganizationService.getInstance().getById(id).then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.setState({ organizations: [response.data] });
                this.setState({ organization: response.data });
                return;
            }
            this.showToast('Error al listar organismos');
        });
    }

    onFileChange = (event) => {
        this.setState({ loading: true });
        FileService.getInstance().uploadFile(event.target.files[0]).then(response => {
            event.target.value = null;
            this.setState({ loading: false });
            if (response && response.success) {
                let files = this.state.bucketFiles;
                files.push(response.data);
                this.setState({ bucketFiles: files });
                this.forceUpdate();
                this.updateData();
                return;
            }
            this.showToast('No se pudo cargar el archivo');
        });
    };

    onDeleteFile = (filePath) => {
        this.setState({ loading: true });
        FileService.getInstance().deleteFile(filePath).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                let files = this.state.bucketFiles;
                files = files.filter(file => file.filePath !== filePath);

                this.setState({ bucketFiles: files });
                this.forceUpdate();
                this.updateData();
                return;
            }
            this.showToast('No se pudo borrar el archivo');
        }).catch(() => {
            this.setState({ loading: false });
            this.showToast('No se pudo borrar el archivo');
        });
    }

    updateData = () => {
        if (!this.state._id) {
            return;
        }
        this.update();
    }

    update = (redirect) => {
        this.setState({ loading: true });
        const organization = ClientOrganizationService.buildClientOrganization(this.state);

        ClientOrganizationService.getInstance().update(organization).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                if (redirect)
                    this.props.history.push('/' + ClientOrganizationService.modelName + '/view/' + organization._id);
                return;
            }

            this.showToast('No se pudo guardar el registro');
        });
    }
}

export default BaseClientOrganization;