import CRUDService from './CRUDService';

class UserService extends CRUDService {

    private static instance: UserService;
    public static modelName: String = 'user';
    private constructor() {
        super();
        this.modelName = UserService.modelName;
    }

    public static getInstance(): UserService {
        if (!this.instance)
            this.instance = new UserService();
        return this.instance;
    }

    public static buildUser(data: any): any {
        var builder: any = {
            _id: data._id,
            name: data.name,
            last_name: data.last_name,
            username: data.username.trim(),
            last_login: data.last_login,
            //TODO: https://www.npmjs.com/package/public-ip
            last_ip: data.last_ip,
            email: data.email,
            admin: data.admin,
            editor: data.editor,
            readonly: data.readonly
        }

        if (data.password) {
            builder.password = data.password;
        }
        return builder;

    }

    public static permission(data: any) {
        if (data.admin)
            return 'Administrador';
        if (data.editor)
            return 'Editor';
        if (data.readonly)
            return 'Lectura';
    }
}

export default UserService