import DiminutiveForm from './DiminutiveForm';
import { withRouter } from "react-router";
import DiminutiveService from '../services/DiminutiveService';
import { LinearProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import { withSnackbar } from 'notistack';
import BaseDiminutive from './BaseDiminutives';
import AuthService from '../services/AuthService';

class DiminutiveEdit extends BaseDiminutive {

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            _id: this.props.match.params.id
        };
    }

    componentDidMount() {
        if (!(AuthService.userIsAdmin())) {
            this.showToast('No tiene permisos para ingresar');
            this._back();
            this.props.history.push('/');
        }
        this.getDiminutive();
    }

    _handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.error)
            return;

        if (!this.validate()) {
            return;
        }
        this.setState({ loading: true });
        const proceeding = DiminutiveService.buildDiminutive(this.state);

        DiminutiveService.getInstance().update(proceeding).then(response => {
            this.setState({ loading: false });
            if (response && response.success) {
                this.props.history.push('/' + DiminutiveService.modelName + '/view/' + proceeding._id);
                return;
            }

            this.showToast('No se pudo guardar el registro');
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <LinearProgress />}
                <DiminutiveForm handleSubmit={this._handleSubmit}
                    _id={this.state?._id}
                    word={this.state?.word}
                    diminutive={this.state?.diminutive}
                    loading={this.state?.loading}
                    setState={state => this.setState(state)}
                    error={this.state.error}
                />
            </div>
        );
    }
}
export default withRouter(withSnackbar(DiminutiveEdit))