import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { AppBar, Drawer, Grid, IconButton, Link, Toolbar } from '@material-ui/core';
import AuthService from '../services/AuthService';
import { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { matchPath } from 'react-router';
import { useLocation } from "react-router-dom";
export default function NavBar(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        drawerHeader2: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1)
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
    }));
    let location = useLocation();
    const classes = useStyles();
    const [open, toggleDrawer] = useState(false);

    const [openSistem, setOpenSistem] = useState(false);
    const [openAdministration, setOpenAdministration] = useState(false);
    const handleClickSistem = () => { setOpenSistem(!openSistem); };
    const handleClickAdministration = () => { setOpenAdministration(!openAdministration); };

    const [administrationSelected, setAdministrationSelected] = useState(false);
    const [sistemSelected, setSistemSelected] = useState(false);

    const isAdministration = !!matchPath(
        location.pathname,
        ['/product', '/client']
    );
    const isSistem = !!matchPath(
        location.pathname,
        ['/user', '/organization', '/proceeding']
    );
    useEffect(() => {
        setAdministrationSelected(isAdministration);
        setSistemSelected(isSistem);
    }, [isAdministration, isSistem]);

    return (
        <div style={{ marginBottom: "30px", minHeight: "64px" }}>
            <Drawer variant="persistent" anchor="top" open={true} className="appBar">
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => toggleDrawer(true)}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <img src="/logo-blanco.png" className="img-logo-navbar" alt="logo" />
                            </Grid>
                            <Grid item>
                                <NavLink to="/" className="text-white">
                                    <Typography variant="h6" className={classes.title + " ml-3"}>
                                        Estudio María L. Cabrera y Asoc. S.R.L.
                                    </Typography>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Drawer>
            <Drawer variant="temporary" anchor="left" open={open} onClose={() => toggleDrawer(false)} className="appBar">
                <Grid container className="gridBar">
                    <Grid item xs={8} style={{ paddingLeft: "28px", paddingTop: "10px" }}>
                        <img src="/logo-blanco.png" className="img-logo-navbar" alt="logo" />
                    </Grid>
                    <Grid item xs={3}>
                        <IconButton onClick={() => toggleDrawer(false)}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
                <List>
                    {
                        (AuthService.userIsAdmin() || AuthService.userIsEditor()) &&
                        <NavLink exact to="/report" className="text-white" activeClassName="active">
                            <ListItem button key={1}>
                                Reporte
                            </ListItem>
                        </NavLink>
                    }
                    <ListItem button onClick={handleClickAdministration} className="text-white" selected={administrationSelected}>
                        <ListItemText primary="Administración" />
                        {openAdministration ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAdministration} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <NavLink to="/client" className="text-white" activeClassName="active">
                                <ListItem button key={1}>
                                    Clientes
                                </ListItem>
                            </NavLink>
                            <NavLink to="/product" className="text-white t-3" activeClassName="active">
                                <ListItem button key={1}>
                                    Productos
                                </ListItem>
                            </NavLink>
                        </List>
                    </Collapse>
                    <ListItem button onClick={handleClickSistem} className="text-white" selected={sistemSelected}>
                        <ListItemText primary="Sistema" />
                        {openSistem ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSistem} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <NavLink to="/diminutive" className="text-white" activeClassName="active">
                                <ListItem button key={1}>
                                    Diminutivos
                                </ListItem>
                            </NavLink>
                            <NavLink to="/organization" className="text-white" activeClassName="active">
                                <ListItem button key={1}>
                                    Organismos
                                </ListItem>
                            </NavLink>
                            <NavLink to="/proceeding" className="text-white" activeClassName="active">
                                <ListItem button key={1}>
                                    Trámites
                                </ListItem>
                            </NavLink>
                            {
                                AuthService.userIsAdmin() &&
                                <NavLink exact to="/user" className="text-white" activeClassName="active">
                                    <ListItem button key={1}>
                                        Usuarios
                                    </ListItem>
                                </NavLink>
                            }
                        </List>
                    </Collapse>
                    {
                        AuthService.getToken() &&
                        <NavLink to="/user/profile" className="text-white" activeClassName="active">
                            <ListItem button key={1}>
                                Mi perfil
                            </ListItem>
                        </NavLink>
                    }
                    <ListItem key={1} className="text-white mt-3" >
                        {AuthService.getUserLogged()?.username}
                    </ListItem>
                    <ListItem button key={2}>
                        <Link to="/" className="text-white" onClick={props.logout}>
                            Cerrar sesión
                        </Link>
                    </ListItem>
                </List>
            </Drawer >
        </div >
    )
}