import { Container } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router";
import NavBar from "../components/NavBar";
import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserNew from "./UserNew";
import UserView from "./UserView";
import UserLogout from "./UserLogout";
import Profile from "./Profile";

function UserRoutes(props) {

    let { path } = useRouteMatch();

    return (
        <div>
            <NavBar logout={props.logout} />
            <Container maxWidth={false}>
                <Switch>
                    <Route path={`${path}/edit/:id`}>
                        <UserEdit />
                    </Route>
                    <Route path={`${path}/new`}>
                        <UserNew />
                    </Route>
                    <Route path={`${path}/view/:id`}>
                        <UserView />
                    </Route>
                    <Route path={`${path}/logout/`}>
                        <UserLogout />
                    </Route>
                    <Route path={`${path}/profile/`}>
                        <Profile />
                    </Route>
                    <Route path={`${path}/`}>
                        <UserList />
                    </Route>
                </Switch>
            </Container>
        </div>
    )
}

export default UserRoutes