import { withRouter } from "react-router";
import { withSnackbar } from "notistack";
import BaseUser from "./BaseUser";
import AuthService from "../services/AuthService";

class UserEdit extends BaseUser {

    componentDidMount() {
        AuthService.setToken();
        this._back();
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}
export default withSnackbar(withRouter(UserEdit));