import '../Form.css';
import { Button, withStyles, Paper, TextField, Typography, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core';
import { withRouter } from "react-router";
import BaseProceeding from './BaseProceeding';
import AuthService from '../services/AuthService';
import "./style.css";

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: "100%",
        },
        '& .MuiAutocomplete-root': {
            width: 500,
            "max-width": "100%",
            order: 0,
            margin: 0,
            display: "inline-flex",
            padding: 0,
            position: "relative",
            "min-width": 0,
            "flex-direction": "column",
            "vertical-align": "top",
        }
    }
});

class ProceedingForm extends BaseProceeding {
    constructor(props) {
        super(props);
        this.state = {
            show_address: false
        };
    }

    componentDidMount() {
        this.getOrganizations();
    }

    _handleTextFieldChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
    }

    handleCheckboxChange = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.checked));
    }

    handleChangePhone = (event) => {
        this.props.setState(this.structuring(event.target.id, event.target.value));
    }

    organizationChange = (event) => {
        var organizations = this.props.organizations.filter(organizationFind => organizationFind._id !== event.target.id);
        if (event.target.checked) {
            // debería agregar solo el que checkeo, porque ya filré por este, para no agregarlo dos veces
            var organization = this.state.organizations.filter(organizationFind => organizationFind._id === event.target.id);
            organizations.push(organization[0]);
        }
        // si no está chequeado solo se filtra
        this.props.setState({ organizations });
        this.forceUpdate();
    }

    showModalAddress = () => {
        this.setState({ show_address: true });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={3} >
                <form className={classes.root + " m-3"} onSubmit={this.props.handleSubmit}>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Trámite: {this.props.name}
                    </Typography>
                    <div className="row">
                        <div className="col-sm-6">
                            <TextField id="name" label="Nombre" placeholder="Nombre" value={this.props.name} onChange={this._handleTextFieldChange} />
                        </div>
                    </div>
                    <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
                        Organismos:
                    </Typography>
                    {this.state.loadingOrganizations && <LinearProgress />}
                    <div className="row">
                        {
                            this.state.organizations && this.state.organizations.map(organization => (
                                <div className="col-sm-6" key={organization._id}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            id={organization._id}
                                            checked={this.props.organizations.some(organizationFind => organizationFind._id === organization._id)}
                                            onChange={this.organizationChange} />}
                                        label={organization.organization_name}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    {
                        (AuthService.userIsEditor() || AuthService.userIsAdmin()) &&
                        <Button variant="contained" color="primary" className="mt-4 mr-3 mb-3" onClick={this.props.handleSubmit}>
                            Guardar
                        </Button>
                    }
                    <Button variant="contained" color="default" className="mt-4 mb-3" onClick={this._back}>
                        Volver
                    </Button>
                </form >
            </Paper>
        );
    }
}

export default withRouter(withStyles(useStyles)(ProceedingForm))